import React, { useState } from "react";
import { useAlert } from "./useAlert";
import axiosClient from "utils/axios";
import { ROUTES_API } from "utils/constants";
import { TBsPLineToMask, TMaskToBsPLine } from "types/ai";

const usePsLine = () => {
	const { error, warn } = useAlert();
	const [loadingPsLine, setLoadingPsLine] = useState(false);

	const onMaskToBsPLine = async (
		input: TMaskToBsPLine,
		callback?: (a: any) => void,
	) => {
		setLoadingPsLine(true);
		try {
			const rs: any = await axiosClient.post(ROUTES_API.AI.MASK_TO_LINE, input);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				return callback?.(data);
			}
			callback?.(null);
			warn(message);
		} catch (err) {
			callback?.(false);
		} finally {
			setLoadingPsLine(false);
		}
	};

	const onBsPLineToMask = async (
		input: TBsPLineToMask,
		callback?: (a: any) => void,
	) => {
		setLoadingPsLine(true);
		try {
			const rs: any = await axiosClient.post(ROUTES_API.AI.LINE_TO_MASK, input);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				return callback?.(data);
			}
			callback?.(null);
			error(message);
		} catch (err) {
			callback?.(false);
		} finally {
			setLoadingPsLine(false);
		}
	};
	return { onMaskToBsPLine, onBsPLineToMask, loadingPsLine };
};

export default usePsLine;
