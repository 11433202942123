import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import SvgIcon from "assets/SvgIcon";
import { IModalLayout } from "interface";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};

const modalStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	zIndex: 10000,
};

const stylesBackDrop = {
	style: { backgroundColor: "#D9D9D9", opacity: "30%" },
};

export default function ModalLayout({
	children,
	className,
	headerTitle,
	footerModal,
	open,
	toggleModal,
}: IModalLayout) {
	return (
		<div>
			<Modal
				open={open}
				onClose={toggleModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="border-transparent focus:border-transparent focus:ring-0"
				BackdropProps={stylesBackDrop}
				sx={modalStyle}>
				<Box
					sx={style}
					className={`rounded-[8px] flex flex-col border-0 border-transparent focus:border-transparent focus:ring-0 border-sm ${className}`}>
					{headerTitle ? (
						<div className="w-full border-b py-3 flex flex-row justify-between items-center px-4">
							<span className="font-bold text-[16px] text-white">
								{headerTitle}
							</span>
							<button onClick={() => toggleModal?.()}>
								{SvgIcon.CloseIcon()}
							</button>
						</div>
					) : null}
					{children}
					{footerModal && footerModal}
				</Box>
			</Modal>
		</div>
	);
}
