import { useAuth } from 'hooks/useAuth';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { forgotPasswordSchema } from 'utils/validField';
import SvgIcon from 'assets/SvgIcon';
import { InputFX } from '../Inputs';
import InputSendEmail from './InputSendEmail';
import { TForgotPassword } from 'types/auth';
import InputSendOTP from './InputSendOTP';

const TIMER = 10;
export default function FormForgotPassword() {
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const { onSendOTP, onVerifyOTP, onChangePassword } = useAuth();
    const [formData, setFormData] = useState<TForgotPassword>({
        email: '',
    });
    const [value, setValue] = useState<string>("");
    const [errors, setErrors] = useState<TForgotPassword>({});
    const buttonRef = useRef<any>();
    const [name, setName] = useState<string>('email');
    const [seconds, setSeconds] = useState(TIMER);

    useEffect(() => {
        if (seconds !== TIMER) return;
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => {
                const sec = prevSeconds > 0 ? prevSeconds - 1 : 0;
                if (sec === 0) {
                    clearInterval(intervalId);
                }
                return sec
            });

            // Clear the interval when the countdown reaches zero

        }, 1000);

        // Cleanup function to clear the interval when the component unmounts
    }, [seconds]);

    useEffect(() => {
        setValue("");
        if (name === '') {
            router("/auth/signIn");
        }
    }, [name]);

    const togglePassword1 = () => {
        console.log("🚀 ~ togglePassword1 ~ showPassword1:", showPassword1)
        setShowPassword1(!showPassword1);
    };

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleSubmit = async () => {
        try {
            await forgotPasswordSchema.validate(formData, { abortEarly: false });
            if (name === 'email') {
                const result = await onSendOTP(formData);
                if (result === 200) {
                    setName('otp')
                    setSeconds(TIMER);
                }
            } else if (name === "otp") {
                const result = await onVerifyOTP(formData);
                if (result === 200) {
                    setName('changPWD')
                    setSeconds(TIMER);
                }
            } else if (name === 'changPWD') {
                onChangePassword(formData);
            }
        } catch (error: any) {
            const validationErrors: any = {};
            if (error?.inner) {
                error?.inner.forEach((err: any) => {
                    validationErrors[err.path] = err.message;
                });
            }
            setErrors(validationErrors);
            console.log("errors", errors);
        }
    };

    const onReSendOTP = () => {
        if (seconds === 0) {
            onSendOTP(formData);
            setSeconds(TIMER)
            setErrors({})
        }
    };

    const router = useNavigate();
    const handelSinUp = () => {
        router("/auth/signup");
    };

    const renderInputFX = useCallback(() => {
        switch (name) {
            case 'email':
                return (<InputSendEmail
                    type={`email`}
                    name={name}
                    placeholder={`Email Address`}
                    value={value}
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                />)
            case 'otp': {
                return (<InputSendOTP
                    type={`text`}
                    required={false}
                    name={name}
                    placeholder={`OTP`}
                    value={value}
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                />)
            }
            case 'changPWD': {
                return (
                    <div className="flex flex-col gap-3">
                        <div className="relative">

                            <InputFX
                                type={showPassword1 ? "text" : "password"}
                                name='newPassword'
                                placeholder='New Password'
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors?.newPassword}
                                setErrors={setErrors}
                            />

                            <span className="absolute right-4 top-4" onClick={togglePassword1}>
                                {!showPassword1 ? (
                                    <span>{SvgIcon.ShowIcon()}</span>
                                ) : (
                                    <span>
                                        {SvgIcon.NotShowIcon()}
                                    </span>

                                )}
                            </span>
                        </div>
                        <div className="relative">
                            <InputFX
                                type={showPassword2 ? "text" : "password"}
                                name='repassword'
                                placeholder='Re-enter Password'
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors?.repassword}
                                setErrors={setErrors}
                            />
                            <span className="absolute right-4 top-4" onClick={togglePassword2}>
                                {!showPassword2 ? (
                                    <span>{SvgIcon.ShowIcon()}</span>

                                ) : (
                                    <span>{SvgIcon.NotShowIcon()}</span>

                                )}
                            </span>
                        </div>
                    </div>
                )
            }
        }
    }, [name, showPassword1, showPassword2, formData])

    return (
        <div>
            <p className='font-medium text-[#FFFFFF] text-[16px] pt-8 pb-2'>

                {name && name === 'email' && (
                    <span>Enter your email and we'll send you an OTP to reset your password</span>
                )}

                {name && name === 'otp' && (
                    <span>We have sent the OTP verification code to your email. Please check and enter the code below</span>
                )}

                {name && name === 'changPWD' && (
                    <span>Enter your new password. If you forget it, you can choose to forget password later.</span>
                )}

            </p>
            <form
                className="pt-2"
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
            >

                {renderInputFX()}

                <div className=" my-4 text-[#ffffff] font-medium text-[20px]">
                    <input
                        ref={buttonRef}
                        type="button"
                        value={`${name === 'changePWD' ? `Confirm` : `Continue`}`}
                        onClick={() => handleSubmit()}
                        className="font-bold text-[18px] w-full p-4 transition bg-[#5271FF] text-white rounded-lg cursor-pointer border-gradient hover:text-white hover:bg-gradient-to-r hover:from-[#7340d88c] hover:rgba(82, 114, 255, 0.443) hover:to-[#48d7e49a] border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient"
                    />
                </div>


                {name && name === 'email' && (
                    <p className="pb-[25px] text-[#ffffff]  ">
                        Don't have an account?{" "}
                        <span
                            className="text-[#3257bd] font-bold hover:cursor-pointer "
                            onClick={handelSinUp}
                        >
                            Sign up
                        </span>
                    </p>
                )}

                {name && name === 'otp' && (
                    <div className="w-full flex flex-col items-center content-center pb-[25px] ">
                        <p className="text-[#ffffff] hover:cursor-pointer " >
                            Not receiving emails?{" "}
                        </p>
                        <button
                            className="text-[#3257bd] "
                            onClick={() => onReSendOTP()}
                        >
                            Resend
                            <span className='text-[#3257bd]'> {seconds}</span> seconds
                        </button>
                    </div>
                )}

            </form>
        </div>
    )
}







