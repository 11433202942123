import { InputFX } from 'components/Inputs'
import React from 'react'
import { IInputFX } from 'types/auth'

export default function InputSendEmail({
    type,
    name,
    value,
    placeholder,
    formData,
    setFormData,
    errors,
    setErrors,
}: IInputFX) {
    return (<InputFX
        type={`email`}
        name={`email`}
        placeholder={`Email Address`}
        value={value}
        formData={formData}
        setFormData={setFormData}
        errors={errors?.email}
        setErrors={setErrors}
    />)
}
