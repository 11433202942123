import { FC } from "react";
import SignIn from "./pages/auth/SignIn/SignIn";
import ProjectPage from "pages/admin/project/page";
import DetailPage from "pages/admin/project/[plug]/page";
import DetailLayout from "components/Layouts/DetailLayout";
import DefaultLayout from "components/Layouts/DefaultLayout";
import { BlankLayout } from "components/Layouts";
import { PricingPlan } from "pages/payment/PricingPlan";

export interface RouterType {
	path: string;
	component: FC;
	layout:
		| FC<{ children: string | JSX.Element | JSX.Element[] | JSX.Element }>
		| any;
}

const publicRoutes: RouterType[] = [
	{
		path: "/auth/login",
		component: SignIn,
		layout: BlankLayout,
	},
	{
		path: "/pricing-plan",
		component: PricingPlan,
		layout: BlankLayout,
	},
];

const privatedRoutes: RouterType[] = [
	{
		path: "/admin/project",
		component: ProjectPage,
		layout: DefaultLayout,
	},
	{
		path: "/admin/project-detail/:plug",
		component: DetailPage,
		layout: DetailLayout,
	},
];

export { publicRoutes, privatedRoutes };
