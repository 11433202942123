import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Background from 'assets/images/backgrounds/background1.jpg'

export function BlankLayout({
  children,
}: {
  children: React.ReactNode;
}) {

  const backgroundImageStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'fixed',
    margin: 0,
    padding: 0,
  };

  return (
    <>
      <div className="flex flex-col w-screen h-screen " style={backgroundImageStyle}>
        <main>
          <div className="flex flex-row justify-evenly w-screen h-dvh">
            {children}
            <div className="absolute">
              <ToastContainer />
            </div>
          </div>

        </main>
      </div>
    </>
  );
}
