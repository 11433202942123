import { Typography } from "@mui/material";
import React from "react";
import {
	EPricingPlan,
	EnumPlanType,
	PackagePlan,
	ServiceItem,
} from "types/payment";
import LineRotate from "assets/images/icon/line-rotate.png";
import CheckIcon from "assets/images/icon/check-icon.png";
import MoreIcon from "assets/images/icon/more-icon.png";
import { BuyMore } from "./BuyMore";

interface PlanItemProps extends PackagePlan {
	onClick?: (item?: PackagePlan) => void;
	type?: EPricingPlan;
}

export const PlanItem: React.FC<PlanItemProps> = (item) => {
	return (
		<div className="bg-[#191A1E] rounded-lg border-[#3C3C3C] border-[1px] max-w-[300px] max-h-[765px] p-6 w-fit">
			<div className="">
				<Typography fontSize={"22px"} fontWeight={"700"} color={"#FFFFFF"}>
					{item?.packageName}
				</Typography>
				<p className="font-semibold text-5xl bg-gradient-to-r from-purple-600 via-blue-500 to-cyan-400 bg-clip-text text-transparent">{`${item?.priceSale}`}</p>
				{item?.price ? (
					<div className="flex flex-col items-start mt-1.5">
						<div className="relative flex flex-row items-end mr-2">
							<Typography
								fontSize={"20px"}
								fontWeight={"700"}
								color={"#B3B6B6"}
								style={{
									position: "relative",
								}}>
								{`${item?.price}`}
								<img
									src={LineRotate}
									className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
									style={{
										transform: "rotate(10deg)",
									}}
								/>
							</Typography>
							<Typography
								fontSize={"10px"}
								fontWeight={"700"}
								color={"#B3B6B6"}>
								/mo
							</Typography>
						</div>

						{item?.annualPrice ? (
							<div className="text-[10px] ">
								{`billed annually as ${item?.annualPrice} `}
							</div>
						) : (
							""
						)}

						{/* <button
							className="text-white font-normal rounded border-transparent  w-fit px-2"
							style={{
								backgroundImage:
									"linear-gradient(to right, #7340D8B2 , #5271FFB2,#48D8E4B2)",
							}}>
							<p className="font-semibold text-[10px] text-white">{`SAVE ${item?.discountPercent} %`}</p>
						</button> */}
					</div>
				) : (
					""
				)}
			</div>
			<div className="my-6">
				<Typography fontSize={"14px"} fontWeight={"600"} color={"#FFFFFF"}>
					{item?.description}
				</Typography>
			</div>
			<button
				onClick={() => item?.onClick?.(item)}
				className="w-full flex shadow-lg border-gradient-radius-5xl shrink-0 grow-0 box-border flex justify-center items-center px-11 py-5 text-white text-base font-bold">
				{item?.btnText}
			</button>
			<div className="w-full h-[1px] my-6 bg-[#3C3C3C]" />
			{item?.serviceTile && (
				<p className="font-semibold text-xs mb-1.5 bg-gradient-to-r from-purple-600 via-blue-500 to-cyan-400 bg-clip-text text-transparent">{`$${item?.serviceTile}`}</p>
			)}
			{item?.description?.map((service: string, index: number) => {
				return (
					<div
						key={index}
						className="flex items-center justify-between mb-3 gap-2">
						<img src={CheckIcon} alt="" />
						<p className="text-xs text-white flex-1 font-semibold">{service}</p>
						{item?.packageName !== EnumPlanType.ENTERPRISE && (
							<button>
								<img src={MoreIcon} alt="" />
							</button>
						)}
					</div>
				);
			})}
			{item?.packageName === EnumPlanType.PREMIUM && (
				<BuyMore />
			)}
			{item?.packageName === EnumPlanType.PRO && (
				<BuyMore />
			)}
		</div>
	);
};