import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationType } from "../../interface";

const initialState = {
  darkMode: JSON.parse(localStorage.getItem("darkMode") as string) || false,
  language: localStorage.getItem("language") || "en",
  application:
    JSON.parse(localStorage.getItem("application") as string) || null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
      localStorage.setItem("darkMode", JSON.stringify(action.payload));
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
      localStorage.setItem("language", action.payload);
    },
    setApplication: (state, action: PayloadAction<ApplicationType | null>) => {
      state.application = action.payload;
      localStorage.setItem("application", JSON.stringify(action.payload));
    },
  },
});

export const { setDarkMode, setLanguage, setApplication } = appSlice.actions;

export default appSlice.reducer;
