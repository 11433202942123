import React, { useEffect, useRef, useState } from "react";

const useFullScreen = () => {
	const elementRef = useRef<any>(null);
	const [isFullScreen, setIsFullScreen] = useState(false);

	const handleFullScreenChange = () => {
		setIsFullScreen(!!document.fullscreenElement);
	};

	useEffect(() => {
		document.addEventListener("fullscreenchange", handleFullScreenChange);
		document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
		document.addEventListener("mozfullscreenchange", handleFullScreenChange);
		document.addEventListener("msfullscreenchange", handleFullScreenChange);

		return () => {
			document.removeEventListener("fullscreenchange", handleFullScreenChange);
			document.removeEventListener(
				"webkitfullscreenchange",
				handleFullScreenChange,
			);
			document.removeEventListener(
				"mozfullscreenchange",
				handleFullScreenChange,
			);
			document.removeEventListener(
				"msfullscreenchange",
				handleFullScreenChange,
			);
		};
	}, []);

	const enterFullScreen = () => {
		if (elementRef.current) {
			if (elementRef.current.requestFullscreen) {
				elementRef.current.requestFullscreen();
			} else if (elementRef.current.mozRequestFullScreen) {
				elementRef.current.mozRequestFullScreen();
			} else if (elementRef.current.webkitRequestFullscreen) {
				elementRef.current.webkitRequestFullscreen();
			} else if (elementRef.current.msRequestFullscreen) {
				elementRef.current.msRequestFullscreen();
			}
		}
	};

	const exitFullScreen = () => {
		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		}
	};

	const toggleFullScreen = () => {
		if (isFullScreen) {
			exitFullScreen();
		} else {
			enterFullScreen();
		}
	};

	return { elementRef, isFullScreen, toggleFullScreen };
};

export default useFullScreen;
