import useAITools from "hooks/useAITools";
import { getBouncePoint } from "utils/libs";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { EModeFrame } from "types/ai";
import { forEachChild } from "typescript";
interface bezierCurveProps {
	modeFrame: any;
	keyMode: string;
	blobId: string;
	objectIdx: string;
	width: number;
	height: number;
	image?: any;
	onUpdateBCurve: (a: any) => void;
	onCancel?: () => void;
}
export default function BezierCurve({
	modeFrame,
	keyMode,
	blobId,
	objectIdx,
	onUpdateBCurve,
	onCancel,
	width,
	height,
}: bezierCurveProps) {
	const { plug } = useParams<any>() || "";
	const { onExportBezierCurve } = useAITools();
	const canvasRef = useRef(null);
	const prevRef = useRef<any>({
		controlPoints: [],
		control1Points: [],
		control2Points: [],
	}).current;
	const [canvas, setCanvas] = useState<any>();
	const [color, setColor] = useState<any>(null);
	const [draggingIndex, setDraggingIndex] = useState<number | any>({});
	const [controlPoints, setControlPoints] = useState<any[]>([]);

	useEffect(() => {
		if (modeFrame === EModeFrame.SAVE) {
			drawCurves(controlPoints, false);
		}
	}, [modeFrame]);

	useEffect(() => {
		if (!width && !canvasRef.current) return;
		console.log(
			"🚀 ~ useEffect ~ imagesMask[keyMode]:",
			objectIdx,
			blobId,
			plug,
		);

		const canva: any = canvasRef.current;
		canva.width = width;
		canva.height = height;
		setCanvas(canva);
		const getControlPoints = async () => {
			const { data = null, color = [] }: any = await onExportBezierCurve({
				code: plug ?? "",
				blob_id: blobId,
				object_idx: objectIdx,
				width,
				height,
			});
			console.log("🚀 ~ getControlPoints ~ rs:", data, blobId);
			if (data) {
				console.log("🚀 ~ getControlPoints ~ rs:", data, blobId);
				prevRef.controlPoints = data.map((e: any) => ({ x: +e[0], y: +e[1] }));
				prevRef.control1Points = data.map((e: any) => ({ x: +e[2], y: +e[3] }));
				prevRef.control2Points = data.map((e: any) => ({ x: +e[4], y: +e[5] }));
				setControlPoints(data);
				color && setColor(`rgb(${color})`);
				drawCurves(data);
			} else {
				onCancel?.();
			}
		};
		getControlPoints();
	}, [width, height, keyMode, blobId, canvasRef, prevRef, plug]);

	const drawArc = (
		ctx: any,
		point: any,
		shape = "square",
		colour = "white",
	) => {
		if (ctx) {
			const square = 5;
			ctx.beginPath();
			shape === "square" &&
				ctx.fillRect(
					point.x - square + 2,
					point.y - square + 2,
					square,
					square,
				);

			shape !== "square" && ctx.arc(point.x, point.y, 5, 0, Math.PI * 2);
			ctx.fillStyle = colour;
			ctx.fill();
		}
	};

	const drawLine = (ctx, point1, point2, color = "green") => {
		ctx.strokeStyle = color;
		ctx.lineWidth = 2;
		ctx.beginPath();
		ctx.moveTo(point1.x, point1.y);
		ctx.lineTo(point2.x, point2.y);
		ctx.stroke();
	};

	const drawOncCurves = (ctx: any, ctlPoints: any[], i: number) => {
		const cp1x = +ctlPoints[i]?.[0];
		const cp1y = +ctlPoints[i]?.[1];
		const cp2x = +ctlPoints[i]?.[2];
		const cp2y = +ctlPoints[i]?.[3];
		const x1 = +ctlPoints[i]?.[4];
		const y1 = +ctlPoints[i]?.[5];

		ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x1, y1);
		// ctx.lineTo(x1, y1);
		// ctx.lineTo(cp1x, cp1y);
		// ctx.lineTo(cp2x, cp2y);
	};

	const drawCurves = (ctlPoints: any, showNode = true) => {
		if (!canvas) return;
		const ctx = canvas.getContext("2d");
		console.log("🚀 ~ drawCurves ~ controlPoints:", ctlPoints, color);
		// Clear the canvas
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.strokeStyle = showNode ? "black" : "transparent";
		ctx.lineWidth = 2;
		ctx.beginPath();

		ctx.moveTo(+ctlPoints[0]?.[0], +ctlPoints[0]?.[1]);

		// Loop through all click points and draw Bezier curves
		for (var i = 0; i < ctlPoints.length; i++) {
			drawOncCurves(ctx, ctlPoints, i);
		}

		drawOncCurves(ctx, ctlPoints, 0);

		ctx.fillStyle = color; // Set fill color
		ctx.fill();
		ctx.stroke();

		showNode &&
			ctlPoints.forEach((point: any) => {
				drawArc(ctx, { x: +point[0], y: +point[1] });
				drawArc(ctx, { x: +point[2], y: +point[3] });
				drawArc(ctx, { x: +point[4], y: +point[5] });
			});

		ctx.closePath();

		if (!showNode) {
			// const img = canvas.toDataURL('image/png');
			// document.write('<img src="' + img + '"/>');

			// const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

			// // Log the image data to the console (for demonstration purposes)
			// console.log('imageData', imageData.data);
			// imageData.data.forEach(e => {
			//   e !== 0 && console.log("🚀 ~ drawCurves ~ e:", e)

			// })
			canvas.toBlob((blob) => {
				const file = new File([blob], blobId);
				onUpdateBCurve?.(file);
			});
		}
	};

	useEffect(() => {
		if (!canvas) return;
		// canvas.addEventListener('click', function (event: any) {
		//     // Get the click coordinates relative to the canvas
		//     const pointClick = getPoint(event);
		//     console.log("🚀 ~ { x: mouseX, y: mouseY }:", pointClick, draggingIndex)
		//     const isPoint = getBouncePoint(prevRef.controlPoints, pointClick);
		//     if (!pointClick || draggingIndex || ![-1, 0].includes(isPoint)) return;
		//     console.log("🚀 ~ !pointClick:", !pointClick, draggingIndex !== -1, event)
		//     // Add the click coordinates to the array
		//     prevRef.controlPoints.push(!!isPoint ? pointClick : prevRef.controlPoints[isPoint]);
		//     // Redraw all Bezier curve paths
		//     drawCurves(prevRef.controlPoints);
		//     //
		// });

		// if (draggingIndex) return;
		// Redraw all Bezier curve paths
		drawCurves(controlPoints);

		// setCanvas(canvas)
	}, [canvas, draggingIndex, controlPoints]);
	/** move node */
	// handleMouseDown
	const handleMouseDown = (event: any) => {
		const rect = event.target.getBoundingClientRect();
		const mouseX = event.clientX - rect.left;
		const mouseY = event.clientY - rect.top;
		let key = -1;
		const dragIndex = getBouncePoint(prevRef.controlPoints, {
			x: mouseX,
			y: mouseY,
		});
		if (dragIndex !== -1) key = 1;

		const dragIndex1 = getBouncePoint(prevRef.control1Points, {
			x: mouseX,
			y: mouseY,
		});

		if (dragIndex1 !== -1) key = 2;

		const dragIndex2 = getBouncePoint(prevRef.control2Points, {
			x: mouseX,
			y: mouseY,
		});

		const val = {
			1: dragIndex,
			2: dragIndex1,
			3: dragIndex2,
		};

		if (dragIndex2 !== -1) key = 3;

		console.log(
			"🚀 ~ handleMouseDown ~ dragIndex:",
			dragIndex,
			dragIndex1,
			dragIndex2,
		);

		key !== -1 && setDraggingIndex({ key, val: val[key] });
	};

	const handleMouseMove = (event: any) => {
		if (draggingIndex?.key) {
			console.log("🚀 ~ handleMouseMove ~ draggingIndex:", draggingIndex);
			const rect = event.target.getBoundingClientRect();
			const mouseX = event.clientX - rect.left;
			const mouseY = event.clientY - rect.top;

			if (draggingIndex?.key === 1) {
				prevRef.controlPoints[draggingIndex?.val] = { x: mouseX, y: mouseY };
				controlPoints[draggingIndex?.val][0] = `${mouseX}`;
				controlPoints[draggingIndex?.val][1] = `${mouseY}`;
			} else if (draggingIndex?.key === 2) {
				prevRef.control2Points[draggingIndex?.val] = { x: mouseX, y: mouseY };
				controlPoints[draggingIndex?.val][2] = `${mouseX}`;
				controlPoints[draggingIndex?.val][3] = `${mouseY}`;
			} else {
				prevRef.control2Points[draggingIndex?.val] = { x: mouseX, y: mouseY };
				controlPoints[draggingIndex?.val][4] = `${mouseX}`;
				controlPoints[draggingIndex?.val][5] = `${mouseY}`;
			}

			console.log("🚀 ~ handleMouseMove ~ controlPoints:", controlPoints);

			drawCurves(controlPoints);
		}
	};
	const handleMouseUp = () => {
		console.log("🚀 ~ handleMouseUp ~ controlPoints:", controlPoints);
		// draggingIndex &&
		//     onUpdateBezierCurve({
		//         blob_id: blobId,
		//         code: plug,
		//         object_idx: objectIdx,
		//         control_point: controlPoints
		//     }, a => a && onUpdateBCurve?.(a))
		setDraggingIndex(null);
		// setTimeout(() => {
		//   const img = canvas.toDataURL('image/png');
		//   document.write('<img src="' + img + '"/>');
		// }, 2500)
	};

	return (
		<canvas
			ref={canvasRef}
			style={{ position: "absolute", zIndex: 99999 }}
			// onMouseUp={handleMouseUp}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
		/>
	);
}
