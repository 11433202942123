import React, { useEffect, useRef, useState } from "react";
interface ITotalFramePros {
	total: number;
	curFrame: number;
	onChange?: (a: any) => void;
}

export default function TotalFrame({
	total,
	curFrame,
	onChange,
}: ITotalFramePros) {
	const [value, setValue] = useState<number | string>(1);

	useEffect(() => {
		if (curFrame) {
			setValue?.(curFrame);
		}
	}, [curFrame]);

	const handelChangeValue = (e) => {
		const value = e.target.value;
		if (value === "") {
			setValue?.("");
		} else {
			const numberValue = Number(value);
			if (numberValue < 1) {
				setValue?.(1);
				onChange?.(1);
			} else if (numberValue > total) {
				setValue?.(total);
				onChange?.(total);
			} else {
				setValue?.(numberValue);
				onChange?.(numberValue);
			}
		}
	};

	return (
		<>
			<div className="pr-1"> Frame: </div>
			<div className="border-gradient flex flex-row justify-evenly">
				<input
					min={1}
					max={total}
					type="number"
					value={value}
					onChange={handelChangeValue}
					className="w-[50px] relative z-99999 text-white text-[10px] text-center font-semibold bg-transparent focus py-0 px-2 border-0 border-transparent focus:border-transparent focus:ring-0"
				/>
				<span className="">/</span>
				<div className="w-fit text-white text-[10px] text-center font-semibold bg-transparent focus py-0 px-2">
					{total}
				</div>
			</div>
		</>
	);
}
