import { useState, useEffect } from 'react';

export default function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined';

  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const getWindowDimensions = () => {
      const width = hasWindow ? window.innerWidth : 0;
      console.log("🚀 ~ getWindowDimensions ~ width:", width)
      const height = hasWindow ? window.innerHeight : 0;
      setWindowDimensions({
        width,
        height,
      });
    }

    if (hasWindow) {
      getWindowDimensions()

      window.addEventListener('resize', getWindowDimensions);
      return () => window.removeEventListener('resize', getWindowDimensions);
    }
  }, [hasWindow]);

  return windowDimensions;
}