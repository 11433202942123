import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelectionContainer } from "@air/react-drag-to-select";

export const useBehaviorHandlers = ({
	undo,
	redo,
	zoomIn,
	zoomOut,
	selectAll,
	deselectAll,
}: {
	undo: () => void;
	redo: () => void;
	zoomIn: () => void;
	zoomOut: () => void;
	selectAll: () => void;
	deselectAll: () => void;
}) => {
	useHotkeys("ctrl+z", undo);
	useHotkeys("ctrl+shift+z", redo);
	useHotkeys("ctrl+y", redo);
	useHotkeys("ctrl++", zoomIn);
	useHotkeys("ctrl+-", zoomOut);
	useHotkeys("delete", () => alert("delete"));
	useHotkeys("backspace", () => alert("backspace"));
};
