import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { ModalLayout, LeftModal, RightModal } from "components/modals";

interface IModalExport {
	open: boolean;
	toggleModalExport: () => void;
	onExportShape: (info: any) => void;
	infoExport?: {
		frameRate: number;
		numberObjects: number;
		scalePoint: number;
		choosingFrame: number;
		startKeyframe: number;
		startFrame: number;
		endFrame: number;
		width: number;
		height: number;
	};
	setInfoExport?: Dispatch<
		SetStateAction<{
			frameRate: number;
			numberObjects: number;
			scalePoint: number;
			choosingFrame: number;
			startKeyframe: number;
			startFrame: number;
			endFrame: number;
			width: number;
			height: number;
		}>
	>;
}

export default function ModalExport({
	open,
	toggleModalExport,
	onExportShape,
}: IModalExport) {
	const [infoExport, setInfoExport] = useState({
		frameRate: 0,
		numberObjects: 10,
		scalePoint: 0,
		choosingFrame: 0,
		startKeyframe: 0,
		startFrame: 0,
		endFrame: 0,
	});

	const renderFooterModalCancel = useCallback(
		() => (
			<div className="h-full flex flex-col justify-end">
				<div className="flex flex-row justify-center items-center place-content-end gap-2 py-2">
					<button
						onClick={() => toggleModalExport()}
						className="rounded-[8px] px-8 py-1 border text-white text-[10px] font-semibold">
						Cancel
					</button>
					<button
						onClick={() => onExportShape(infoExport)}
						className="rounded-lg px-8 py-[6px] border-gradient text-white text-[10px] font-semibold bg-[#1F1F1F]">
						Export
					</button>
				</div>
			</div>
		),
		[infoExport],
	);

	const ModalExportOptions = useMemo(
		() => [
			{
				title: "Start Frame",
				key: "startFrame",
			},
			{
				title: "Choosing Frame",
				key: "choosingFrame",
			},
			{
				title: "End Frame",
				key: "endFrame",
			},
			{
				title: "Start Keyframe",
				key: "startKeyframe",
			},
			{
				title: "Scale num of Curve",
				key: "scalePoint",
			},
		],
		[],
	);

	return (
		<>
			<ModalLayout
				open={open}
				toggleModal={toggleModalExport}
				headerTitle="Export Roto Shape"
				footerModal={renderFooterModalCancel()}
				className="w-[400px] h-fit bg-[#1F1F1F]">
				<div className="flex flex-row h-full">
					<LeftModal className="w-full border-b">
						<div className="">
							{ModalExportOptions?.map((v, k) => (
								<div key={k} className={` flex flex-col gap-2 pb-4 last:pb-0 `}>
									<div className="flex flex-row justify-between items-center">
										<span className="text-[14px] text-white">{v?.title}</span>
										<div className="border-gradient w-[60%] py-1 flex items-center">
											<input
												type="number"
												value={infoExport?.[v.key]}
												onChange={(e) => {
													if (+e?.target.value < 0) return;
													setInfoExport?.((pre) => {
														return {
															...pre,
															[v.key]: e.target?.value,
														};
													});
												}}
												className="w-full h-full text-white text-[12px] font-medium bg-transparent focus  border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient"
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					</LeftModal>
				</div>
			</ModalLayout>
		</>
	);
}
