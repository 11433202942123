import "./styles.css";
import { useEffect, useMemo, useState } from "react";
import SplitPane from "react-split-pane";
import Ruler from "./Ruler";
import { useTour } from "@reactour/tour";

export default function Timeline({
	curFrame = 1,
	totalFrame,
	setCurFrame,
	autoPlay,
	stopAutoPlay,
	images,
	isTour,
	duration,
	isDisable,
	setIsChanged,
	isChanged,
}: any) {
	const [listImages, setListImages] = useState<any>();
	const [w, setW] = useState<number>(0);
	const timer = useMemo(
		() => (Number(curFrame) === 1 ? 0 : (curFrame / totalFrame) * w),
		[curFrame, totalFrame, w],
	);

	const { currentStep, isOpen } = useTour();

	useEffect(() => {
		if (images) {
			setListImages(images);
		}
	}, [images?.length]);

	useEffect(() => {
		if (duration) {
			setW(duration);
		}
	}, [duration]);

	return (
		<>
			<div
				style={{
					overflowY: "hidden",
					overflowX: "auto",
					minHeight: 140,
					position: "relative",
				}}
				className={`mx-2 box-border min-h-420 p-2 border-2 rounded-lg border-[#3C3C3C] overflow-scroll ${
					currentStep === 4 && isOpen && "border-gradient px-3"
				}`}
				data-tour="step-5">
				{isDisable && (
					<div className="absolute top-0 left-0 w-full h-full bg-[#0000005e] z-99999"></div>
				)}
				<div
					style={{
						left: 20,
						position: "absolute",
						zIndex: 99,
						width: "100%",
						minHeight: 95,
					}}>
					{listImages && (
						<Ruler
							width={
								Math.round(duration) > 9000 ? Math.round(duration) + 130 : 9000
							}
							height={38}
							images={listImages}
							duration={duration}
						/>
					)}
				</div>
				<SplitPane
					style={{
						maxHeight: 97,
						position: "absolute",
						zIndex: 100,
						marginLeft: "20px !important",
						overflow: "auto !important",
					}}
					resizerStyle={{
						backgroundColor: "#000277",
						opacity: "unset",
						height: "100px",
					}}
					split="vertical"
					step={0.01}
					minSize={0}
					size={isChanged ?? timer}
					onDragStarted={() => stopAutoPlay()}
					onDragFinished={(newSize: any) => {
						setIsChanged(newSize);
					}}
					onChange={(s: any) => {
						s = s >= w ? w : s;
						setCurFrame(Math.max(Math.round((s * totalFrame) / w) - 1, 1));
						setIsChanged(s);
					}}
					maxSize={duration}></SplitPane>
			</div>
		</>
	);
}
