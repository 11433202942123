import Box from "@mui/material/Box";
import { Button } from "@material-tailwind/react";
import Modal from "@mui/material/Modal";
import VideoIcon from "assets/images/icon/icon-video.png";
import CloseIcon from "assets/images/icon/icon-close.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 10000,
};

const stylesBackDrop = {
  style: { backgroundColor: "#00000099" },
};

export default function ModalGettingStarted({ open = false, toggleModal, startTour }) {

  return (
    <div>
      <Modal
        open={open}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="text-white border-0 border-transparent focus:border-transparent focus:ring-0"
        BackdropProps={stylesBackDrop}
        sx={modalStyle}
      >
        <Box
          sx={style}
          className="lg:w-1/3 lg:mx-6 w-full bg-gettingStarted rounded-[8px] border-0 border-transparent focus:border-transparent focus:ring-0"
        >
          <Box className="flex justify-end  w-full">
            <Button onClick={toggleModal}>
              <img
                src={CloseIcon}
                alt="x"
                className="lg:w-12 lg:h-12 w-8 h-8"
              />
            </Button>
          </Box>
          <Box className="mt-[146px] flex flex-col justify-center items-center">
            <img src={VideoIcon} alt="video" className="w-[105px] h-[105px]" />
            <p className="my-4 font-semibold text-white text-5xl leading-[84px]">
              Getting Stared
            </p>
            <p className="mb-4 font-semibold text-white text-2xl">
              Let create your first video on LuminFX
            </p>
          </Box>
          <Box className="flex flex-row w-full items-center justify-center px-2.5 gap-6">
            <Button
              onClick={() => {
                toggleModal?.()
              }}
              className="w-48 h-12 py-3 px-12 bg-[#191A1E] rounded-md border-[1px] border-[#3C3C3C] text-base text-white font-semibold normal-case"
            >
              Maybe later
            </Button>
            <Button
              className="w-48 h-12 py-3 px-12 bg-[#5271FF] rounded-md  text-base text-white font-semibold normal-case"
              onClick={() => {
                toggleModal?.();
                startTour?.();
              }}
            >
              Show me
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
