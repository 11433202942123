

import React from 'react';
import { IValueStatusButtonProps } from 'interface';

export default function ValueStatusButton({
    icon,
    value,
    isActive,
    isStatus,
    onClick,
    className,
    textColor,
    textSize,
    textFont,
    statusIcons,
}: IValueStatusButtonProps) {

    textColor = textColor ? textColor : 'text-white';
    textSize = textSize ? textSize : 'text-13px';
    textFont = textFont ? textFont : 'font-semibold';

    const iconComponent = icon ?? null;
    const statusIcon1 = statusIcons ? statusIcons[0] : null;
    const statusIcon2 = statusIcons ? statusIcons[1] : null;
    className = `select-none flex flex-row px-4 items-center self-center border-gradient flex flex-row justify-evenly items-center ${className}`

    return (
        <button
            className={`${className} ${textColor} ${textSize} ${textFont} ${isActive == false ? `hidden` : `block`} w-200`}
        >
            {iconComponent && <span className='w-full flex justify-start px-2'>{iconComponent}</span>}
            <span className={iconComponent ? `text-center px-1` : `text-left px-2 w-full select-none `}>{value}</span>
            {statusIcons && statusIcon1 && statusIcon2 && (
                <span className='flex place-content-center w-fit h-fit px-1'>
                    {isStatus == true ? statusIcon1 : statusIcon2}
                </span>
            )}
        </button>
    );
};
