import moment from "moment";

export const dataURLtoFile = (dataurl: string, filename: string) => {
  try {
    const arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)?.[1],
      bstr = atob(arr[arr.length - 1]);
    let n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    return null;
  }
};

export const arrayBufferToBase64 = (buffer, filename) => {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:${getExtension(filename)};base64,${window.btoa(binary)}`;
};

export const getBlobId = (filename: string, splitExt = false) => {
  try {
    const nameArr = filename.split("/");
    const splitDot = nameArr[nameArr.length - 1].split(".");
    return splitExt ? nameArr[nameArr.length - 1] : splitDot[0];
  } catch (error) {
    return "";
  }
};

export const getExtension = (filename: string) => {
  const name = filename.split(".");
  switch (name[name.length - 1]) {
    case "png":
      return "image/png";
    case "jpg":
      return "image/jpg";
    case "jpeg":
      return "image/jpeg";
    default:
      return null;
  }
};

export const bufferToBase64 = (bufferArray: Buffer, filename: string) => {
  console.log("🚀 ~ bufferToBase64 ~ bufferArray:", bufferArray);
  try {
    if (!getExtension(filename)) return;
    // data:image/jpeg;base64,
    return `data:${getExtension(filename)};base64,${Buffer.from(
      bufferArray
    ).toString("base64")}`;
  } catch (error) {
    return null;
  }
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export function getCurrentInSeconds(): number {
  return moment().unix();
}

export const getBouncePoint = (arr: any[], newPoint: any) =>
  arr.reduce((accumulator, cur: any, i) => {
    const tmp =
      Math.abs(cur.x - newPoint.x) < 3 && Math.abs(cur.y - newPoint.y) < 3;
    accumulator = tmp ? i : accumulator;
    return accumulator;
  }, -1);

export const getScaleIMG = (
  original_width: number,
  original_height: number,
  designer_width: number,
  designer_height: number
) => {
  const original_ratio = original_width * designer_height;
  const designer_ratio = designer_width * original_height;
  if (original_ratio > designer_ratio) {
    designer_height = designer_ratio / original_width;
  } else {
    designer_width = (designer_height * original_width) / original_height;
  }
  return { width: designer_width, height: designer_height };
};

export const getRandomItem = (array: any) => {
  if (array.length === 0) {
    return null;
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};
