import React, { FC, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { publicRoutes, privatedRoutes, RouterType } from "./router";
import SignIn from "./pages/auth/SignIn/SignIn";
import { useSelector } from "react-redux";
// import Page404 from "./pages/404";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AppState } from "./redux/store";
import "flatpickr/dist/flatpickr.min.css";
import "assets/css/satoshi.css";
import "assets/css/style.css";
import "video-react/dist/video-react.css";
import { AdminLayout, BlankLayout } from "components/Layouts";
import { io } from "socket.io-client";
import { useCommon } from "hooks/useCommon";
import { useAuth } from "hooks/useAuth";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
  const isLogin = useSelector((state: AppState) => state.user.isLogin);
  console.info("🚀 ~ isLogin:", isLogin);
  const { onProfile } = useAuth()
  useEffect(() => {
    isLogin && onProfile();
    console.log("🚀 ~ useEffect ~ isLogin:", isLogin)
  }, [isLogin])

  const navigate = useNavigate();
  if (!isLogin) {
    navigate("/login");
    return (
      <BlankLayout>
        <SignIn />
      </BlankLayout>
    );
  }
  return children;
};

function App() {
  const darkMode = useSelector((state: AppState) => state.app.darkMode);
  const { onUpdateParams, expand } = useCommon();
  
  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URI}`, {
      transports: ["websocket"],
      autoConnect: true,
    });
    console.log(
      "🚀 ~ useEffect ~ socket:",
      socket,
      process.env.REACT_APP_SOCKET_URI
    );
    socket && onUpdateParams({ socket, nodeEnv: process.env.NODE_ENV });
    socket.emit("handleEvent", "OK");
  }, []);

  return (
    <div className={darkMode ? "dark" : ""} onClick={() => {
      onUpdateParams({ expand: !expand })
    }}>
      <Router>
        <Routes>
          {publicRoutes.map((route: RouterType, index) => {
            let Layout = route.layout || BlankLayout;
            let Component = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            );
          })}
          {privatedRoutes.map((route: RouterType, index) => {
            let Layout = route.layout || AdminLayout;
            let Component = route.component;
            return (
              <Route
                key={`${index}-${route.path}`}
                path={route.path}
                element={
                  <RequireAuth>
                    <Layout>
                      <Component />
                    </Layout>
                  </RequireAuth>
                }
              />
            );
          })}
          <Route path="*" element={<SignIn />} />
        </Routes>
        <ToastContainer
          style={{
            zIndex: 999999,
          }}
        />
      </Router>
    </div>
  );
}

export default App;
