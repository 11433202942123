import React from 'react'
import 'components/Buttons/style.css';

interface IGradientBorderButton {
    firstIcon?: React.ReactNode;
    lastIcon?: React.ReactNode;
    text: string,
    onClick?: (a: any) => void;
    disabled?: boolean;
    textColor?: any
}

export default function GradientBorderButton({ firstIcon, lastIcon, text, onClick, textColor }: IGradientBorderButton) {
    return (
        <div className="w-full flex shadow-lg border-gradient rounded shrink-0 grow-0 box-border">
            <button className='z-999 w-full flex flex-nowrap items-center justify-center gap-2' onClick={onClick}>
                {firstIcon && (<span>{firstIcon}</span>)}
                <span className={`flex self-center justify-center py-3 ${textColor ?? 'text-white'}`}>{text}</span>
                {lastIcon && (<span>{lastIcon}</span>)}
            </button>
        </div>
    )
}
