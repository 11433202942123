
export type TCreateProject = {
  name: string;
};

export type TStatePayment = {
  manage: boolean,
  paypal: boolean,
  paypalAlert: boolean,
}

export enum ActiveBtn {
	SELECT_OBJECT = "select",
	REMOVE_OBJECT = "remove",
	VISUALIZATION_RESULT = "visualization",
}

export enum EnumActionProject {
  DELETE = "Delete",
  RENAME = "Rename",
}