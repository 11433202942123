import { IconButton } from "@mui/material";
import ScreenIcon from "assets/icons/ScreenIcon";
import React from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import {
	TbPlayerTrackNextFilled,
	TbPlayerTrackPrevFilled,
} from "react-icons/tb";
import { EControl } from "types/video";

interface IFooterFullscreen {
	autoPlay: boolean;
	handleControl: (k: EControl) => void;
	toggleFullScreen: () => void;
}

const FooterFullscreen = ({
	autoPlay,
	handleControl,
	toggleFullScreen,
}: IFooterFullscreen) => {
	return (
		<div className="flex flex-row p-4 mt-5 text-white justify-end absolute bottom-3 w-full px-5">
			<div data-tour="step-4"></div>
			<div
				className={`flex w-1/5 self-center justify-evenly  `}
				data-tour="step-12">
				<button onClick={() => handleControl(EControl.PREV)}>
					<TbPlayerTrackPrevFilled />
				</button>
				<button onClick={() => handleControl(EControl.PLAY)}>
					{!autoPlay ? <FaPlay /> : <FaPause />}
				</button>
				<button onClick={() => handleControl(EControl.NEXT)}>
					<TbPlayerTrackNextFilled />
				</button>
			</div>
			<div className="flex w-2/5 self-center gap-2 justify-end">
				<IconButton onClick={toggleFullScreen}>
					<ScreenIcon />
				</IconButton>
			</div>
		</div>
	);
};

export default FooterFullscreen;
