import {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Box,
	FormControl,
	FormControlLabel,
	Modal,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

interface PropsType {
	open: boolean;
	setOpenCustomRoto: Dispatch<SetStateAction<boolean>>;
	onSubmit?: (valueCus) => void;
	maxLength: number;
	curFrame: number;
}

function ModalCustomRoto({
	open,
	maxLength,
	curFrame,
	setOpenCustomRoto,
	onSubmit,
}: PropsType) {
	const refEndFrame = useRef<any>(null);
	const arrOption = useMemo(() => {
		return [
			{
				value: "all",
				name: "All Frame",
			},
			{
				value: "cus",
				name: "Custom Frame",
			},
		];
	}, []);

	const [valueCus, setValueCus] = useState({
		startFrame: 1,
		endFrame: maxLength,
	});
	const [typeCustom, setTypeCustom] = useState("all");

	const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (Number(e.target?.value) < 0 || Number(e.target?.value) > maxLength) {
			return;
		}
		setValueCus({ ...valueCus, [e.target.name]: e.target.value });
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTypeCustom((event.target as HTMLInputElement).value);
	};

	useEffect(() => {
		if (curFrame > 0) {
			setValueCus({ ...valueCus, startFrame: curFrame });
		}
	}, [curFrame]);

	useEffect(() => {
		if (maxLength > 0) {
			setValueCus({ ...valueCus, endFrame: maxLength });
		}
	}, [maxLength]);

	// Reset all
	useEffect(() => {
		if (typeCustom === "all") {
			setValueCus({ startFrame: 1, endFrame: maxLength });
		}
	}, [typeCustom, maxLength]);

	const onSubmitButton = () => {
		if (valueCus.endFrame <= valueCus.startFrame) {
			return refEndFrame?.current?.focus();
		}
		onSubmit?.(valueCus);
	};

	return (
		<Modal
			open={open}
			onClose={() => {}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className="flex items-center justify-center text-white border-0 border-transparent focus:border-transparent focus:ring-0">
			<Box
				sx={{
					transition: "all 0.25s",
				}}
				minHeight={"317px"}
				height={typeCustom === "all" ? "317px" : "461px"}
				maxHeight={"461px"}
				width={"456px"}
				borderRadius={"10px"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"space-between"}
				bgcolor={"#1F1F1F"}
				padding={"25px"}>
				<Box width={"100%"}>
					<Typography
						fontWeight={"700"}
						fontSize={"16px"}
						textAlign={"center"}
						component={"p"}>
						Run Roto Settings
					</Typography>
				</Box>

				<Box
					flex={1}
					marginTop={"20px"}
					width={"100%"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"space-around"}
					alignItems={"center"}>
					<FormControl fullWidth>
						<Typography
							fontWeight={"600"}
							fontSize={"14px"}
							textAlign={"left"}
							component={"p"}>
							Mode Frame
						</Typography>
						<RadioGroup
							value={typeCustom}
							onChange={handleChange}
							style={{ paddingLeft: 20 }}
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue="female"
							name="radio-buttons-group">
							{arrOption.map((item) => {
								return (
									<FormControlLabel
										key={item?.value}
										style={{ paddingTop: 5 }}
										value={item.value}
										control={
											<Radio
												sx={{
													color: "#ffffff",
													"&.Mui-checked": {
														color: "#4653B8",
													},
												}}
											/>
										}
										label={item.name}
										sx={{
											".MuiFormControlLabel-label": {
												fontSize: "12px",
												fontWeight: 400,
											},
										}}
									/>
								);
							})}
						</RadioGroup>
					</FormControl>

					{/* =========== Custom Roto ================*/}

					<div
						className={`mb-3 mt-3 w-full ${
							typeCustom === "all"
								? "h-0 overflow-hidden"
								: "h-auto overflow-auto"
						}`}>
						<div className="flex items-center justify-between w-full mb-5">
							<Typography
								fontWeight={"600"}
								maxHeight={"461px"}
								fontSize={"14px"}
								textAlign={"left"}
								component={"p"}>
								From Frame
							</Typography>
							<div className="border-gradient flex flex-row justify-evenly">
								<input
									max={maxLength}
									type="number"
									name="startFrame"
									value={valueCus?.startFrame}
									onChange={onChangeValue}
									className="w-[240px] h-[48px] relative z-99999 text-white text-[10px] text-center font-semibold bg-transparent focus py-0 px-2 border-0 border-transparent focus:border-transparent focus:ring-0"
								/>
							</div>
						</div>

						<div className="flex items-center justify-between w-full">
							<Typography
								fontWeight={"600"}
								fontSize={"14px"}
								textAlign={"left"}
								component={"p"}>
								To Frame
							</Typography>
							<div className="border-gradient flex flex-row justify-evenly">
								<input
									ref={refEndFrame}
									max={maxLength}
									type="number"
									name="endFrame"
									value={valueCus.endFrame}
									onChange={onChangeValue}
									className="w-[240px] h-[48px] relative z-99999 text-white text-[10px] text-center font-semibold bg-transparent focus py-0 px-2 border-0 border-transparent focus:border-transparent focus:ring-0"
								/>
							</div>
						</div>
					</div>
					{/* =========== End Custom Roto =========== */}

					<div className="flex items-center justify-between gap-3">
						<div
							style={{
								backgroundImage:
									"linear-gradient(to right, #7340D8B2 , #5271FFB2,#48D8E4B2)",
							}}
							onClick={() => onSubmitButton()}
							className="w-[192px] h-[48px] rounded-md text-center font-medium size-[16px] text-white leading-[46px] transition-all cursor-pointer hover:opacity-[0.6]">
							Run
						</div>
						<div
							onClick={() => setOpenCustomRoto?.(false)}
							className="bg-[#B3B6B6] transition-all cursor-pointer w-[192px] h-[48px] rounded-md text-center font-medium size-[16px] text-white leading-[46px] hover:opacity-[0.6]">
							Cancel
						</div>
					</div>
				</Box>
			</Box>
		</Modal>
	);
}

export default ModalCustomRoto;
