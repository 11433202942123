import SvgIcon from "assets/SvgIcon";
import RenameProject from "components/Inputs/RenameProject";
import { SubmitButton } from "components/RightSidebar";
import { EModeFrame } from "types/ai";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "components/Sidebar/routes";
import DropdownFX from "components/Buttons/DropdownFX";
import { useTour } from "@reactour/tour";
import { useCommon } from "hooks/useCommon";
import { UndoRedoButton } from "components/Buttons";
import BSplineIcon from "assets/BsplineIcon";

export default function TopContentBar({
	modeFrame,
	onModeFrame,
	onExport,
	onBsplineCurve,
	projectName = "",
	isDisable,
	handleUndo,
	handleRedo,
	isUndo,
	isRedo,
	onNewBsplineCurve,
	visMod,
	showBtn,
}: any) {
	const router = useNavigate();
	const { currentStep, isOpen } = useTour();
	const { onUpdateParams } = useCommon();

	return (
		<>
			<div
				className={`items-center flex justify-between px-6 py-4 ${
					isDisable && "isDisable"
				}`}
				onClick={() => {
					onUpdateParams({ expand: true });
				}}>
				<div className="flex flex-row items-center justify-start">
					<button onClick={() => router(ROUTES.ADMIN.PROJECT)}>
						{SvgIcon.HomeIcon()}
					</button>
					{/* <input 
            type="text"  
            className="bg-transparent border-none text-white font-normal border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient w-fit"
            value="Untitled Project"
            />
            {SvgIcon.PencilEditIcon()} */}

					{/* start update ui develop v2 */}
					<RenameProject projectName={projectName} />
					<UndoRedoButton
						onUndo={() => handleUndo()}
						onRedo={() => handleRedo()}
						isUndo={isUndo}
						isRedo={isRedo}
					/>
					{currentStep === 12 && isOpen && (
						<div className="ml-2" data-tour="step-13">
							{SvgIcon.AskIcon()}
						</div>
					)}
				</div>

				{/* start update ui develop v2 */}
				{showBtn && (
					<div className="flex flex-row items-center">
						<div className="flex items-center px-1 " data-tour="step-6">
							<DropdownFX
								onSelected={(mode) => {
									onExport?.(mode);
								}}
							/>
						</div>

						{/* <div className={`flex items-center px-1 `} data-tour="step-7">
						<SubmitButton
							onClick={() => onChangeModeFrame?.()}
							text={"Edit with Bezier Curve"}
							icon={SvgIcon.PencilEditIcon()}
						/>
					</div> */}

						{/* {visMod !== "images" && modeFrame !== EModeFrame.BSPLEDIT && (
							<div className="flex items-center px-1 gap-2">
								<SubmitButton
									text={"Edit with B-Spline Curve"}
									icon={SvgIcon.PencilEditIcon()}
									onClick={onBsplineCurve}
								/>
							</div>
						)}

						<div className="flex items-center px-1 gap-2">
							<SubmitButton
								className={`w-auto ${
									modeFrame === EModeFrame.BSPLEDIT
										? "bg-gradient-to-r from-[#7340d88c] #5272ff71 to-[#48d7e49a] rounded-md"
										: undefined
								} `}
								text={"B-Spline Curve"}
								icon={BSplineIcon.Line()}
								onClick={onNewBsplineCurve}
							/>
						</div> */}

						{modeFrame === EModeFrame.EDIT && (
							<div className="mx-1 ">
								<SubmitButton text={"Done"} onClick={onModeFrame} />
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
}
