import React, { useState } from "react";

interface IInputEnterProps {
	onEnter: (v: any) => void;
	onEsc?: () => void;
	value?: string;
	placeholder?: string;
}

const InputWithEnter: React.FC<IInputEnterProps> = ({
	onEnter,
	onEsc,
	value,
	placeholder = "Input project name and press Enter",
}) => {
	const [inputValue, setInputValue] = useState(value ?? "");
	const [tempValue, setTempValue] = useState(value ?? "");

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			onEnter?.(tempValue);
			setInputValue(tempValue);
		} else if (event.key === "Escape") {
			onEsc?.()
			setTempValue(inputValue); // Revert to the last saved input value
		}
	};

	const handleChange = (event) => {
		event.stopPropagation();
		setTempValue?.(event.target.value);
	};

	return (
		<div className="border-gradient mt-3">
			<div className="relative">
				<input
					type="text"
					value={tempValue}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					placeholder={placeholder}
					className="text-white text-semibold font-raleway bg-transparent border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient"
					autoFocus={true}
				/>
			</div>
		</div>
	);
};

export default InputWithEnter;
