import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "hooks/useAuth";
import GoogleSignIn from "assets/images/auth/btn-google.png";
import Input from "../Inputs/Input";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { signInSchema } from "utils/validField";

export default function FormSignIn() {
  const { onLogin, onLoginSocial } = useAuth();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const buttonRef = useRef<any>();

  const handleSubmit = async () => {
    try {
      await signInSchema.validate(formData, { abortEarly: false });
      onLogin(formData);
    } catch (error: any) {
      const validationErrors: any = {};
      if (error?.inner) {
        error?.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
      }
      setErrors(validationErrors);
      console.log("errors", errors);
    }
  };

  const router = useNavigate();
  const handelSinUp = () => {
    router("/auth/signup");
  };
  const handelForgotPassword = () => {
    router("/auth/forgot-password");
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const loginSocial = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      try {
        onLoginSocial({ idToken: access_token })
      } catch (error: any) {
        const validationErrors: any = {};
        if (error?.inner) {
          error?.inner.forEach((err: any) => {
            validationErrors[err.path] = err.message;
          });
        }
        setErrors(validationErrors);
      }

    },
  });
  // Handle click when keydown inter
  useEffect(() => {
    const handleInter = (e) => {
      if (e.keyCode === 13) {
        buttonRef.current?.click();
      }
    };
    window.addEventListener("keydown", handleInter);

    return () => {
      window.removeEventListener("keydown", handleInter);
    };
  }, []);

  return (
    <div>
      <div>
        <img
          src={GoogleSignIn}
          alt={''}
          className='mx-auto mt-10'
          onClick={() => {
            loginSocial()
          }}
        />
      </div>


      <div className=" flex justify-around flex-row items-center py-5">
        <div className="w-[160px] h-[0.5px] bg-white"></div>
        <span className=" text-[#ffffff] font-normal text-[16px]">OR</span>
        <div className="w-[160px] h-[0.5px] bg-white"></div>
      </div>

      <form
        className="pt-10"
        autoComplete="off"
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          type="email"
          name="email"
          placeholder="Email Address*"
          formData={formData}
          setFormData={setFormData}
          errors={errors?.email}
          setErrors={setErrors}
        />
        <div className="mb-3">
          <div className="relative">
            <Input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password*"
              formData={formData}
              setFormData={setFormData}
              errors={errors?.password}
              setErrors={setErrors}
            />
            <span className="absolute right-4 top-4" onClick={togglePassword}>
              {!showPassword ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9999 18.5C10.2332 18.5 8.5999 18.0333 7.0999 17.1C5.5999 16.1667 4.4249 14.8917 3.5749 13.275C3.4749 13.075 3.3999 12.871 3.3499 12.663C3.2999 12.455 3.2749 12.2423 3.2749 12.025C3.2749 11.7917 3.2999 11.5667 3.3499 11.35C3.3999 11.1333 3.4749 10.925 3.5749 10.725C4.4249 9.10833 5.5999 7.83333 7.0999 6.9C8.5999 5.96667 10.2332 5.5 11.9999 5.5C13.7666 5.5 15.3999 5.96667 16.8999 6.9C18.3999 7.83333 19.5749 9.10833 20.4249 10.725C20.5249 10.925 20.5999 11.1293 20.6499 11.338C20.6999 11.5467 20.7249 11.7673 20.7249 12C20.7249 12.2327 20.6999 12.4537 20.6499 12.663C20.5999 12.8723 20.5249 13.0763 20.4249 13.275C19.5749 14.8917 18.3999 16.1667 16.8999 17.1C15.3999 18.0333 13.7666 18.5 11.9999 18.5ZM11.9999 15.5C12.9666 15.5 13.7916 15.1583 14.4749 14.475C15.1582 13.7917 15.4999 12.9667 15.4999 12C15.4999 11.0333 15.1582 10.2083 14.4749 9.525C13.7916 8.84167 12.9666 8.5 11.9999 8.5C11.0332 8.5 10.2082 8.84167 9.5249 9.525C8.84157 10.2083 8.4999 11.0333 8.4999 12C8.4999 12.9667 8.84157 13.7917 9.5249 14.475C10.2082 15.1583 11.0332 15.5 11.9999 15.5ZM11.9999 13.5C11.5832 13.5 11.2289 13.3543 10.9369 13.063C10.6449 12.7717 10.4992 12.4173 10.4999 12C10.5006 11.5827 10.6466 11.2287 10.9379 10.938C11.2292 10.6473 11.5832 10.5013 11.9999 10.5C12.4166 10.4987 12.7709 10.6447 13.0629 10.938C13.3549 11.2313 13.5006 11.5853 13.4999 12C13.4992 12.4147 13.3536 12.769 13.0629 13.063C12.7722 13.357 12.4179 13.5027 11.9999 13.5Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.31191 2.82719C4.3146 2.82445 4.3173 2.82172 4.31999 2.81898L7.88701 4.8784C7.88527 4.88169 7.88353 4.88498 7.8818 4.88827L9.62207 5.89301C9.62357 5.88959 9.62508 5.88617 9.62661 5.88276L12.2196 7.37982C12.2174 7.38284 12.2152 7.38585 12.2129 7.38885L13.9521 8.39296C13.9542 8.38985 13.9562 8.38674 13.9582 8.38362L17.4543 10.4021C17.4515 10.4048 17.4487 10.4074 17.4459 10.4101L18.928 11.2658C19.3203 11.4923 19.4548 11.994 19.2282 12.3863C19.0017 12.7787 18.5001 12.9131 18.1077 12.6866L16.0551 11.5015C16.0038 11.5347 15.9521 11.5676 15.8999 11.6C14.3999 12.5333 12.7666 13 10.9999 13C9.23328 13 7.59994 12.5333 6.09994 11.6C4.59994 10.6667 3.42494 9.39167 2.57494 7.775C2.47494 7.575 2.39994 7.371 2.34994 7.163C2.29994 6.955 2.27494 6.74233 2.27494 6.525C2.27494 6.29167 2.29994 6.06667 2.34994 5.85C2.39994 5.63333 2.47494 5.425 2.57494 5.225C2.78111 4.83287 3.0064 4.46085 3.25081 4.10892L0.710325 2.64217C0.317991 2.41565 0.183569 1.91398 0.410083 1.52165C0.636597 1.12931 1.13827 0.994889 1.53061 1.2214L4.31191 2.82719ZM7.50042 6.56243C7.51486 7.50224 7.85637 8.30643 8.52494 8.975C9.20828 9.65833 10.0333 10 10.9999 10C11.6281 10 12.1964 9.85574 12.7049 9.56723L7.50042 6.56243ZM14.4999 6.5C14.4999 6.559 14.4987 6.61748 14.4961 6.67542L18.6283 9.06115C18.9197 8.6596 19.1852 8.23089 19.4249 7.775C19.5249 7.57633 19.5999 7.37233 19.6499 7.163C19.6999 6.95367 19.7249 6.73267 19.7249 6.5C19.7249 6.26733 19.6999 6.04667 19.6499 5.838C19.5999 5.62933 19.5249 5.425 19.4249 5.225C18.5749 3.60833 17.3999 2.33333 15.8999 1.4C14.3999 0.466667 12.7666 0 10.9999 0C9.23328 0 7.59994 0.466667 6.09994 1.4C5.98184 1.47349 5.86575 1.54909 5.75168 1.62681L9.09392 3.55646C9.65236 3.18549 10.2877 3 10.9999 3C11.9666 3 12.7916 3.34167 13.4749 4.025C14.1583 4.70833 14.4999 5.53333 14.4999 6.5Z"
                    fill="white"
                  />
                </svg>
              )}
            </span>
          </div>
        </div>
        <p className="pb-[25px] text-[#ffffff] hover:cursor-pointer " onClick={handelForgotPassword}>Forgot password?</p>
        <div className=" my-5 text-[#ffffff] font-medium text-[20px]">
          <input
            ref={buttonRef}
            type="button"
            value="Sign In"
            onClick={handleSubmit}
            className="w-full p-4 text-black transition bg-white border rounded-lg cursor-pointer border-gradient hover:text-white hover:bg-gradient-to-r hover:from-[#7340d88c] hover:#5272ff71 hover:to-[#48d7e49a]"
          />
        </div>
        <p className="pb-[25px] text-[#ffffff]  ">
          Don't have an account?{" "}
          <span
            className="text-[#3257bd] hover:cursor-pointer "
            onClick={handelSinUp}
          >
            Sign up
          </span>
        </p>
      </form>
    </div>
  );
}
