import { Box, CircularProgress, Modal, Portal, Typography } from '@mui/material'
import { PayPalButtons } from '@paypal/react-paypal-js'
import usePayments from 'hooks/usePayments'
import { useRef, useState } from 'react'
import { styles } from './styles'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { GradientBorderButton } from 'components/Buttons'
import { KeyboardBackspace } from '@mui/icons-material';

export default function PayPal({
  id = '6699d218425bb3f10179bd78',
  qty = "1",
  open = false,
  handleClose = () => { },
  backMethod = (a: any) => { }, }) {
  console.log("🚀 ~ id:", id, qty)
  // const [open, setOpen]= useState(true)
  const prevRef = useRef({ orderID: "" }).current
  const { onCreateOrder, onApprove } = usePayments()
  const { width, height } = useWindowDimensions()
  const [loading, setLoading] = useState(false)

  // const handleClose = () => {setOpen(false)}
  const createOrder = async (data: any, actions: any) => {
    const rs = await onCreateOrder({ reference_id: id, qty })
    if (!rs?.id) {
      backMethod?.(null)
    }
    // 
    prevRef.orderID = rs.id
    console.log("🚀 ~ createOrder ~ rs:", rs)
    return rs.id;
  }
  const approve = async (data: any, actions: any) => {
    console.log("🚀 ~ approve ~ e:", data, actions, prevRef.orderID)
    setLoading(true)
    onApprove({ orderID: prevRef.orderID }, rs => {
      setLoading(false)
      backMethod?.(rs)
    })

    // actions?.reset()
  }

  return (
    <>
      {loading ?
        <Portal>
          <Modal
            open={open}
            onClose={handleClose}
            slotProps={{
              backdrop: { sx: classes.backdrop }
            }}
          >
            <Box sx={{ ...classes.wrapContent, width, height }}>
              <Box sx={classes.content}>
                <Box />
                <Box sx={classes.col}>
                  <Typography variant="h5" component="h6" sx={{ fontWeight: 'bold', color: 'white', marginBottom: 2 }}>
                    'Payment In-progress'
                  </Typography>
                  <CircularProgress size={20} />
                  <Typography variant="body2" component="p" color={'white'}>
                    Error message: Transaction has been cancel
                  </Typography>
                </Box>
                <Box />
              </Box>
            </Box>
          </Modal>
        </Portal> :
        <Modal
          open={open}
          onClose={() => handleClose()}
        >
          <Box sx={{ ...styles.wrapContent, width, height }}>
            <Box sx={styles.content}>
              <PayPalButtons
                createOrder={createOrder}
                onApprove={(data: any, actions: any) => approve(data, actions)}
              />
              <GradientBorderButton
                onClick={() => handleClose()}
                firstIcon={<KeyboardBackspace />}
                textColor={'text-black font-bold'}
                text="Back to choose method"
              />
            </Box>
          </Box>
        </Modal>}
    </>
  )
}

const classes = {
  bg: {
    top: '50%',
    left: '50%',
    backgroundColor: '#fff',
    transform: 'translate(-50%, -50%)',
  },
  wrapContent: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  content: {
    backgroundColor: '#000',
    borderRadius: 2,
    minWidth: 504, minHeight: 710, padding: 5,
    justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', display: 'flex'
  },
  col: { justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex' },
  backdrop: {
    backgroundColor: 'rgb(0 0 0 / 50%)',
    position: 'absolute',
  },
  cenItem: {
  },
  row: { justifyContent: "space-between", alignItems: 'center', flexDirection: 'row', display: 'flex', width: 450 },
  bg1F: {
    backgroundColor: '#1f1f1f',
    borderRadius: 2
  }
};