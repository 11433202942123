import { MainBackground } from "assets/colors";
// import LogoMain from "../public/images/logo/Logo_Main.svg"
import LogoMain from "assets/images/logo/logo.png"


import FormSignIn from './FormSignIn';
import { useLocation } from "react-router-dom";
import FormSignUp from './FormSignUp';
import FormForgotPassword from "./FormForgotPassword";

export function FormLayout() {
    const { pathname } = useLocation()
    console.log("🚀 ~ FormLayout ~ pathname:", pathname)
    const backgroundColor = {
        backgroundColor: { MainBackground },
    };

    return (
        <div className=" flex h-full px-5">
            <div className="flex flex-col self-center justify-center w-[350px] md:w-[450px] px-5 mx-auto shadow-md shadow-[#6b6a6a] bg-[#1f1f1f] border-color-[#3a3939] rounded-3xl">
                <div className="flex flex-col w-full pt-5">
                    <img
                        src={LogoMain}
                        width={200} alt={''}
                        className='mx-auto'
                    />
                    <h1 className='py-3 text-xl font-bold text-center text-white'>
                        {   
                            pathname === `/auth/forgot-password` ? 'Reset your password' 
                            :
                            (pathname === `/auth/signup` ? 'Sign up to get started' : 'Log in to your account')
                        }
                    </h1>
                </div>
                <div className=" ">
                    {
                        ['/auth/forgot-password'].includes(pathname) ? <FormForgotPassword /> 
                        : 
                        (['/auth/signup'].includes(pathname) ? <FormSignUp /> : <FormSignIn />)
                    }
                </div>
            </div>
        </div>
    )
}
