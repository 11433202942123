

import * as React from 'react';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { IRangeSliderFX } from 'types/RightSidebar/IRangeSliderFX';
import { useCommon } from 'hooks/useCommon';
import { useEffect } from 'react';


function ValueLabelComponent(props: SliderValueLabelProps) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

export default function RangeSliderFX({ title, defaultValue, onUpdate, max, min }: IRangeSliderFX) {

    const [value, setValue] = React.useState(defaultValue);

    const handleSliderChange: any = (event: Event, newValue: number) => {
        console.log("🚀 ~ handleSliderChange ~ newValue:", newValue)
        onUpdate?.(newValue)
        setValue(newValue);
    };

    useEffect(() => {
        defaultValue && onUpdate?.(defaultValue)
    }, [defaultValue])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = (event.target.value === '' ? 0 : Number(event.target.value)) ?? 0;
        const vMin = Math.max(min ?? 1, isNaN(v) ? 0 : v);

        setValue(Math.min(max ?? 1, vMin));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 50) {
            setValue(50);
        }
    };

    return (
        <Box sx={{ width: '90%' }}>
            <Box sx={{ mt: 2 }} />
            <Typography gutterBottom className="font-semibold text-white" sx={{ fontSize: '12px' }}>{title}</Typography>
            <Grid className="flex flex-row justify-between items-center">
                <div className="border-gradient">
                    <input
                        type='tel'
                        value={value}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        className='w-[50px] text-white text-[10px] text-center font-semibold bg-transparent focus py-0 px-2 border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient'
                    />
                </div>
                <Slider
                    valueLabelDisplay="auto"
                    slots={{
                        valueLabel: ValueLabelComponent,
                    }}
                    aria-label="custom thumb label"
                    defaultValue={defaultValue}
                    sx={{ ml: 2 }}
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    min={min ?? 0}
                    max={max ?? 50}
                />
            </Grid>
        </Box>
    );
}