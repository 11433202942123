//@ts-nocheck
import React, {
	useRef,
	useState,
	useCallback,
	useEffect,
	useImperativeHandle,
	forwardRef,
} from "react";
import {
	Stage,
	Layer,
	Line,
	Circle,
	Group,
	Image as KonvasImage,
	Rect,
} from "react-konva";
import useImage from "use-image";
import bspline from "b-spline";
import Konva from "konva";
import { ShapeConfig } from "konva/lib/Shape";
import { useBehaviorHandlers } from "hooks/useBehaviorHandlers";
import { useSelectionContainer } from "@air/react-drag-to-select";

import {
	TransformWrapper,
	TransformComponent,
	useControls,
} from "react-zoom-pan-pinch";
import { convertPercentageToDecimal } from "utils/helper";
import useActionBSpline from "hooks/useActionBSpline";
import { useDebounce } from "hooks/useDebounce";
import imgCurDelete from "../../assets/imgCurDelete.png";

interface BSplineCurveProps {
	imageUrl: string;
	width: number;
	height: number;
	isClosed?: boolean;
	shape?: ShapeConfig;
	line?: ShapeConfig;
	circle?: ShapeConfig;
	resPoints?: Array<[number, number]>;
	setControlPoint: Dispatch<SetStateAction<[number, number][] | undefined>>;
	rateScaleWidth?: number;
	rateScaleHeight?: number;
	widthOrigin?: number;
	heightOrigin?: number;
	ref?: any;
	setUndoHistory?: Dispatch<SetStateAction<[number, number][] | undefined>>;
	stateFit?: string;
	setIsNewCurve: Dispatch<SetStateAction<boolean>>;
	setIsDeleteCurve: Dispatch<SetStateAction<boolean>>;
	setIsAddPoint: Dispatch<SetStateAction<boolean>>;
	setIsRemovePoint: Dispatch<SetStateAction<boolean>>;
	isNewCurve: boolean;
	isDeleteCurve: boolean;
	isAddPoint: boolean;
	isRemovePoint: boolean;
	setSelectedSpline: Dispatch<SetStateAction<number>>;
	selectedSpline: number;
}

const pointToSegmentDistance = (px, py, x1, y1, x2, y2) => {
	const A = px - x1;
	const B = py - y1;
	const C = x2 - x1;
	const D = y2 - y1;

	const dot = A * C + B * D;
	const len_sq = C * C + D * D;
	const param = len_sq !== 0 ? dot / len_sq : -1;

	let xx, yy;

	if (param < 0) {
		xx = x1;
		yy = y1;
	} else if (param > 1) {
		xx = x2;
		yy = y2;
	} else {
		xx = x1 + param * C;
		yy = y1 + param * D;
	}

	const dx = px - xx;
	const dy = py - yy;
	return Math.sqrt(dx * dx + dy * dy);
};

function createClosedKnotVector(degree: number, numCtrlPts: number) {
	const numKnots = numCtrlPts + degree + 1;
	const knotVector = [];
	for (let i = 0; i < numKnots; i++) {
		knotVector.push(i);
	}
	return knotVector;
}

// Calculator points
function calculateCentroid(vertices) {
	if (vertices?.length <= 0) return;
	let n = vertices.length;
	let signedArea = 0;
	let Cx = 0;
	let Cy = 0;

	for (let i = 0; i < n - 1; i++) {
		let x0 = vertices[i][0];
		let y0 = vertices[i][1];
		let x1 = vertices[i + 1][0];
		let y1 = vertices[i + 1][1];

		let A = x0 * y1 - x1 * y0;
		signedArea += A;
		Cx += (x0 + x1) * A;
		Cy += (y0 + y1) * A;
	}

	let x0 = vertices[n - 1][0];
	let y0 = vertices[n - 1][1];
	let x1 = vertices[0][0];
	let y1 = vertices[0][1];

	let A = x0 * y1 - x1 * y0;
	signedArea += A;
	Cx += (x0 + x1) * A;
	Cy += (y0 + y1) * A;

	signedArea *= 0.5;
	Cx /= 6 * signedArea;
	Cy /= 6 * signedArea;

	return [Cx, Cy];
}

// Convert line point
const convertToLinePoint = (points) => {
	const linePoints = [];
	for (let i = 0; i < points?.length - 1; i++) {
		linePoints.push([points[i], points[i + 1]]);
	}
	return linePoints;
};

export const transformPointsOriginToScale = (points, scaleX, scaleY) => {
	return points?.map((innerArray) =>
		innerArray?.map(([x, y]) => [x * scaleX, y * scaleY]),
	);
};

export const transformPointsScaleToOrigin = (points, scaleX, scaleY) => {
	return points?.map((innerArray) =>
		innerArray?.map(([x, y]) => [x / scaleX, y / scaleY]),
	);
};

const BSplineCurveCus: React.FC<BSplineCurveProps> = forwardRef(
	(
		{
			imageUrl,
			width,
			height,
			line: _line,
			circle,
			resPoints,
			setControlPoint,
			rateScaleWidth,
			rateScaleHeight,
			setUndoHistory,
			stateFit,
			setIsNewCurve,
			setIsDeleteCurve,
			setIsAddPoint,
			setIsRemovePoint,
			isNewCurve,
			isDeleteCurve,
			isAddPoint,
			isRemovePoint,
			setSelectedSpline,
			selectedSpline,
		},
		ref,
	) => {
		const { DragSelection } = useSelectionContainer({
			eventsElement: document.getElementById("root"),
			onSelectionChange: (box) => {
				const scrollAwareBox = {
					...box,
					top: box.top + window.scrollY,
					left: box.left + window.scrollX,
				};
			},
			onSelectionStart: () => {},
			onSelectionEnd: () => {},
			selectionProps: {
				style: {
					border: "1px dashed purple",
					borderRadius: 4,
					backgroundColor: "brown",
					opacity: 0.5,
				},
			},
			shouldStartSelecting: (target) => {
				return true;
			},
		});
		const [image] = useImage(imageUrl);
		const stageRef = useRef<Konva.Stage>(null);

		const initialSplines = {
			controlPoints: [],
			lines: [],
			closed: false,
			coordinate: { x: 0, y: 0 },
		};

		const [splines, setSplines] = useState([]);
		const [history, setHistory] = useState([]);
		const [redoStack, setRedoStack] = useState([]);

		const handleExitBSpline = () => {
			setSplines([]);
			setHistory([]);
			setRedoStack([]);
		};

		const handleUndo = () => {
			if (history.length > 1) {
				const newHistory = history.slice(0, -1);
				const lastSpline = history[history.length - 1];
				setHistory(newHistory);
				setSplines(newHistory[newHistory.length - 1]);
				setRedoStack((prevRedoStack) => [...prevRedoStack, lastSpline]);
			} else if (history.length === 1) {
				const lastSpline = history[0];
				setHistory([]);
				setSplines([]);
				setRedoStack((prevRedoStack) => [...prevRedoStack, lastSpline]);
			}
		};

		const handleRedo = () => {
			if (redoStack.length > 0) {
				const newRedoStack = [...redoStack];
				const lastRedo = newRedoStack.pop();
				const newHistory = [...history, lastRedo];
				setHistory(newHistory);
				setSplines(lastRedo);
				setRedoStack(newRedoStack);
			}
		};

		const handleSetHistory = (newArr) => {
			setHistory((pre) => {
				return [...pre, JSON.parse(JSON.stringify(newArr))];
			});
		};

		useImperativeHandle(ref, () => ({
			onUndo: handleUndo,
			onRedo: handleRedo,
			onNewCurve: onNewCurve,
			onDeleteCurve: onDeleteCurve,
			onAddPoint: handleStageClick,
			onRemovePoint: handleStageClick,
			handleExitBSpline: handleExitBSpline,
		}));

		// ===============Actions Button=====================

		const onNewCurve = () => {
			const newArr = [...splines, initialSplines];
			setSplines(newArr);
			handleSetHistory(newArr);
			setSelectedSpline((pre) => pre + 1);
		};

		const onDeleteCurve = useCallback(() => {
			const newArr = [...splines];
			newArr.splice(selectedSpline, 1);
			setSplines(newArr);
			setSelectedSpline((pre) => {
				return pre === 0 ? 0 : pre - 1;
			});
			handleSetHistory(newArr);
		}, [splines, selectedSpline]);

		// ===============End Actions Button==================

		// // Active btn new curve when close sharp
		// useEffect(() => {
		// 	setIsNewCurve(splines?.every((item) => item.closed === true));
		// }, [splines]);

		useEffect(() => {
			if (splines?.length === 0 || splines[0]?.controlPoint?.length === 0) {
				setIsNewCurve(true);
				setIsRemovePoint(false);
				setIsAddPoint(false);
			}
		}, [splines]);

		useEffect(() => {
			const newArr = [];
			splines?.forEach((sp) => {
				!!sp.controlPoints?.length && newArr.push(sp.controlPoints);
			});

			setControlPoint(newArr);
		}, [splines]);

		// Handle response mask to control point
		useEffect(() => {
			if (resPoints?.length > 0) {
				const newArr = [];
				transformPointsOriginToScale(
					resPoints,
					rateScaleWidth,
					rateScaleHeight,
				)?.forEach((item) => {
					if (item?.length > 1) {
						newArr.push({
							controlPoints: [...item, item?.[0]],
							lines: convertToLinePoint(item),
							closed: true,
							coordinate: {
								x: calculateCentroid(item)?.[0],
								y: calculateCentroid(item)?.[1],
							},
						});
					}
				});
				setSplines(newArr);
				handleSetHistory(newArr);
			}
		}, [resPoints]);

		const euclideanDistance = (p1, p2) => {
			return Math.sqrt(
				Math.pow(p1?.[0] - p2?.[0], 2) + Math.pow(p1?.[1] - p2?.[1], 2),
			);
		};

		// End convert line point
		const handleStageClick = useCallback(
			(event: any) => {
				// if (isNewCurve) return;
				const stage = event?.target?.getStage();
				const pointerPosition = stage?.getPointerPosition();
				let splinesArr = [...splines];
				if (isAddPoint) {
					if (!splines?.[selectedSpline]) return;
					if (pointerPosition) {
						const newPoint = [pointerPosition.x, pointerPosition.y];
						const updatedSplines = splinesArr.map((spline, index) => {
							if (index === selectedSpline) {
								let insertIndex = spline.controlPoints.length;
								let minDistance = Infinity;
								for (let i = 0; i < spline.controlPoints.length - 1; i++) {
									const [x1, y1] = spline?.controlPoints[i];
									const [x2, y2] = spline?.controlPoints[i + 1];
									const distance = pointToSegmentDistance(
										newPoint[0],
										newPoint[1],
										x1,
										y1,
										x2,
										y2,
									);
									if (distance < minDistance) {
										minDistance = distance;
										insertIndex = i + 1;
									}
								}
								const newControlPoints = [
									...spline.controlPoints.slice(0, insertIndex),
									newPoint,
									...spline.controlPoints.slice(insertIndex),
								];

								return {
									...spline,
									controlPoints: newControlPoints,
									lines: convertToLinePoint(newControlPoints),
									closed: true,
									coordinate: {
										x: calculateCentroid(newControlPoints)?.[0],
										y: calculateCentroid(newControlPoints)?.[1],
									},
								};
							}
							return spline;
						});

						setSplines(updatedSplines);
						handleSetHistory(updatedSplines);
					}
				} else if (isRemovePoint) {
					if (pointerPosition) {
						const newPoint = [pointerPosition.x, pointerPosition.y];

						const updatedSplines = splinesArr.map((spline, index) => {
							if (index === selectedSpline) {
								const newControlPoints = [...spline?.controlPoints];
								let closestPointIndex = -1;
								let minDistance = Infinity;
								for (let i = 0; i < newControlPoints.length; i++) {
									const distance = euclideanDistance(
										newControlPoints[i],
										newPoint,
									);
									if (distance < minDistance) {
										minDistance = distance;
										closestPointIndex = i;
									}
								}
								if (closestPointIndex !== -1) {
									const closestPoint = newControlPoints[closestPointIndex];
									newControlPoints.splice(closestPointIndex, 1);
								}
								if (newControlPoints?.length === 0) {
									setSelectedSpline((pre) => {
										return pre === 0 ? 0 : pre - 1;
									});
								}

								return {
									...spline,
									controlPoints: newControlPoints,
									lines: convertToLinePoint(newControlPoints),
									closed: newControlPoints?.length < 5 ? false : true,
									coordinate: {
										x: calculateCentroid(newControlPoints)?.[0],
										y: calculateCentroid(newControlPoints)?.[1],
									},
								};
							}

							return spline;
						});
						const newArrCheckEmp = updatedSplines.filter(
							(spline) => spline.controlPoints?.length > 0,
						);
						setSplines(newArrCheckEmp);
						handleSetHistory(newArrCheckEmp);
					}
				} else {
					if (isNewCurve || splines?.length === 0) return;
					if (pointerPosition) {
						const newPoint = [pointerPosition.x, pointerPosition.y];
						const newSplines = [...splines];
						let currentSpline = newSplines[newSplines?.length - 1];

						if (
							currentSpline.controlPoints?.length > 2 &&
							Math.abs(currentSpline.controlPoints?.[0]?.[0] - newPoint?.[0]) <
								10 &&
							Math.abs(currentSpline.controlPoints?.[0]?.[1] - newPoint?.[1]) <
								10
						) {
							currentSpline.controlPoints.push(
								currentSpline.controlPoints?.[0],
							);
							currentSpline.lines.push([
								currentSpline.controlPoints[
									currentSpline.controlPoints?.length - 2
								],
								currentSpline.controlPoints?.[0],
							]);
							currentSpline.closed = true;
							currentSpline.coordinate = {
								x: calculateCentroid(currentSpline.controlPoints)?.[0],
								y: calculateCentroid(currentSpline.controlPoints)?.[1],
							};
							setIsNewCurve(true);
						} else {
							currentSpline.controlPoints.push(newPoint);
							if (currentSpline.controlPoints?.length > 1) {
								currentSpline.lines.push([
									currentSpline.controlPoints[
										currentSpline.controlPoints?.length - 2
									],
									newPoint,
								]);
							}
						}
						setSplines(newSplines);
						handleSetHistory(newSplines);
					}
				}
			},
			[splines, isAddPoint, isRemovePoint, isNewCurve, selectedSpline],
		);

		const [stateMove, setStatMove] = useState([]);
		const valueMovePoint = useDebounce(stateMove, 300);

		useEffect(() => {
			if (valueMovePoint[0]?.controlPoints) {
				setSplines(valueMovePoint);
				handleSetHistory(valueMovePoint);
			}
		}, [valueMovePoint]);

		const handlePointDragMove = useCallback(
			(splineIndex: number, pointIndex: number, newX: number, newY: number) => {
				const updatedSplines = [...splines];
				const updatedPoints = [...updatedSplines[splineIndex].controlPoints];
				updatedPoints[pointIndex] = [newX, newY];
				updatedSplines[splineIndex].controlPoints = updatedPoints;

				const updatedLines: number[][][] = [];
				for (let i = 1; i < updatedPoints.length; i++) {
					updatedLines.push([updatedPoints[i - 1], updatedPoints[i]]);
				}
				updatedSplines[splineIndex].lines = updatedLines;
				setStatMove(updatedSplines);
			},
			[splines],
		);

		const handleGroupDragMove = useCallback(
			(splineIndex: number, pos, event: Konva.KonvaEventObject<DragEvent>) => {
				const newX = event.layerX;
				const newY = event.layerY;
				const newSplines = [...splines];
				const controlPoints = newSplines[splineIndex].controlPoints;
				const coordinates = newSplines[splineIndex].coordinate;

				const deltaX =
					newX -
					(coordinates.x ||
						controlPoints?.[0]?.[0] -
							controlPoints?.[controlPoints?.length / 2][0]);
				const deltaY =
					newY -
					(coordinates.y ||
						controlPoints?.[0]?.[1] -
							controlPoints?.[controlPoints?.length / 2][1]);

				newSplines[splineIndex].controlPoints = controlPoints.map(([x, y]) => [
					x + deltaX,
					y + deltaY,
				]);

				newSplines[splineIndex].lines = convertToLinePoint(
					newSplines[splineIndex].controlPoints,
				);
				newSplines[splineIndex].coordinate = {
					x: newX,
					y: newY,
				};

				setSplines(newSplines);
				setStatMove(newSplines);
			},
			[splines],
		);

		const handleSplineClick = (splineIndex) => {
			if (isRemovePoint || isAddPoint) return;
			const isClosedFull = splines?.every((item) => item.closed === true);
			if (!isClosedFull) {
				return setSelectedSpline(splines.length - 1);
			}
			return setSelectedSpline(splineIndex);
		};

		const drawSpline = useCallback(
			(points: number[][], isClosed: boolean) => {
				const degree = 3;
				if (isClosed && points?.length > 1) {
					points = [...points, points?.[0], points?.[1], points?.[2]];
				}

				if (degree < points?.length - 1) {
					const knotVector = createClosedKnotVector(degree, points.length);
					const splinePoints = [];

					for (let t = 0; t < 1; t += 0.001) {
						const point: any[] = bspline(t, degree, points, knotVector);
						splinePoints.push(point?.[0], point?.[1]);
					}

					return (
						<Line
							points={splinePoints}
							stroke="yellow"
							strokeWidth={1}
							closed={isClosed}
							lineCap="round"
							lineJoin="round"
						/>
					);
				}
			},
			[splines],
		);

		useBehaviorHandlers({
			undo: handleUndo,
			redo: handleRedo,
		});

		// ============== Hover sharp change cursor ===============
		const handleMouseEnter = () => {
			// document.body.style.cursor = "move";
		};
		const handleMouseLeave = () => {
			// document.body.style.cursor = "default";
		};

		const [activeCircle, setActiveCircle] = useState(null);

		const handleMouseCircleEnter = (pointIndex) => {
			setActiveCircle(pointIndex);
		};
		const handleMouseCircleLeave = (pointIndex) => {
			setActiveCircle(pointIndex);
		};

		useEffect(() => {
			if (isAddPoint) {
				document.body.style.cursor = "copy";
			} else if (isRemovePoint) {
				document.body.style.cursor = `url(${imgCurDelete}), auto`;
			} else {
				document.body.style.cursor = "default";
			}
			return () => {
				document.body.style.cursor = "default";
			};
		}, [isAddPoint, isRemovePoint]);

		return (
			<TransformWrapper
				initialScale={1}
				doubleClick={{
					disabled: true,
				}}
				panning={{
					allowLeftClickPan: false,
				}}>
				<TransformComponent>
					<BSplineCurveCusTransform
						width={width}
						height={height}
						handleStageClick={handleStageClick}
						stageRef={stageRef}
						image={image}
						splines={splines}
						handleSplineClick={handleSplineClick}
						handleMouseEnter={handleMouseEnter}
						handleMouseLeave={handleMouseLeave}
						drawSpline={drawSpline}
						selectedSpline={selectedSpline}
						handleMouseCircleEnter={handleMouseCircleEnter}
						handleMouseCircleLeave={handleMouseCircleLeave}
						circle={circle}
						activeCircle={activeCircle}
						handlePointDragMove={handlePointDragMove}
						handleGroupDragMove={handleGroupDragMove}
						DragSelection={DragSelection}
						stateFit={stateFit}
						setSplines={setSplines}
					/>
				</TransformComponent>
			</TransformWrapper>
		);
	},
);

export default BSplineCurveCus;

const BSplineCurveCusTransform = ({
	width,
	height,
	handleStageClick,
	stageRef,
	image,
	splines,
	handleSplineClick,
	handleMouseEnter,
	handleMouseLeave,
	drawSpline,
	selectedSpline,
	handleMouseCircleEnter,
	handleMouseCircleLeave,
	circle,
	activeCircle,
	handlePointDragMove,
	DragSelection,
	stateFit,
	setSplines,
	handleGroupDragMove,
}: any) => {
	const { instance, zoomIn, zoomOut, setTransform, centerView, ...rest } =
		useControls();

	useEffect(() => {
		centerView(convertPercentageToDecimal(stateFit));
	}, [convertPercentageToDecimal(stateFit)]);

	return (
		<>
			<Stage
				width={width}
				height={height}
				onClick={handleStageClick}
				id="bspline-container"
				ref={stageRef}>
				<Layer>
					{image && <KonvasImage image={image} width={width} height={height} />}
					{splines?.map((spline, splineIndex) => (
						<Group
							// globalCompositeOperation={
							// 	selectedSpline === splineIndex
							// 		? "destination-over"
							// 		: "destination-in"
							// }
							key={splineIndex}
							draggable={splineIndex === selectedSpline}
							dragBoundFunc={(pos, event) => {
								handleGroupDragMove(splineIndex, pos, event);
							}}
							onClick={() => handleSplineClick(splineIndex)}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}>
							{drawSpline(spline.controlPoints, spline.closed)}
							<React.Fragment>
								{spline.closed
									? splineIndex === selectedSpline &&
									  spline?.controlPoints?.map(([x, y], pointIndex) => (
											<Rect
												onMouseEnter={() => handleMouseCircleEnter(pointIndex)}
												onMouseLeave={() => handleMouseCircleLeave(pointIndex)}
												key={pointIndex}
												x={x}
												y={y}
												hitStrokeWidth={30}
												width={5}
												height={5}
												fill={activeCircle === pointIndex ? "green" : "red"}
												draggable
												dragBoundFunc={(pos) => {
													return {
														x: Math.max(0, Math.min(pos.x, width)),
														y: Math.max(0, Math.min(pos.y, height)),
													};
												}}
												onDragMove={(event) => {
													const newX = event.target.x();
													const newY = event.target.y();
													handlePointDragMove(
														splineIndex,
														pointIndex,
														newX,
														newY,
													);
												}}
											/>
									  ))
									: spline?.controlPoints?.map(([x, y], pointIndex) => (
											<Rect
												onMouseEnter={() => handleMouseCircleEnter(pointIndex)}
												onMouseLeave={() => handleMouseCircleLeave(pointIndex)}
												key={pointIndex}
												x={x}
												y={y}
												hitStrokeWidth={30}
												width={5}
												height={5}
												fill={activeCircle === pointIndex ? "green" : "red"}
												draggable
												dragBoundFunc={(pos) => {
													return {
														x: Math.max(0, Math.min(pos.x, width)),
														y: Math.max(0, Math.min(pos.y, height)),
													};
												}}
												onDragMove={(event) => {
													const newX = event.target.x();
													const newY = event.target.y();
													console.log("New X ====> ", newX, newY);
													handlePointDragMove(
														splineIndex,
														pointIndex,
														newX,
														newY,
													);
												}}
											/>
									  ))}
							</React.Fragment>
							{(splines?.length === 1 || splineIndex === selectedSpline) &&
								spline?.lines?.map((line, lineIndex) => (
									<Line
										key={lineIndex}
										points={line.flatMap((p) => p)}
										stroke="white"
										strokeWidth={0.5}
										lineCap="round"
										lineJoin="round"
										dash={[10, 5]}
									/>
								))}
						</Group>
					))}
				</Layer>
			</Stage>
			{/* <DragSelection /> */}
		</>
	);
};
