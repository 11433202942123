import React, { useState } from 'react';
import ReactProSideBar from "components/ReactProSideBar/ReactProSideBar";

import { AiOutlineMenu } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";

interface INavigationAdmin {
    title: string;
    url: string;
    icon?: React.ComponentType<any>; // Optional icon component for menu items
    active?: boolean;
}

const navigationData = [
    { title: "Home", url: "/", icon: AiOutlineMenu },
    { title: "Search", url: "/search", icon: IoSearch },
    { title: "Settings", url: "/settings", icon: IoMdSettings },
    { title: "Add New", url: "/add", icon: FaPlusCircle },
];

export default function NavigationIcon() {
    const [isShown, setIsShown] = useState(true);

    const [collapsed, setCollapsed] = useState(true);

    const handleToggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className='flex bg-[#1F1F1F] p-2'>
            <ReactProSideBar />
        </div >
    );
    // return (
    //     <div className='flex'>
    //         <div className='flex' onMouseEnter={() => setIsShown(false)} onMouseLeave={() => setIsShown(true)}>
    //             <div className='bg-[#1F1F1F]' style={{ display: 'flex', }}>
    //                 <Sidebar collapsed={true} collapsedWidth="60px" backgroundColor={'#1f1f1f'}
    //                 >
    //                     <Menu className="grid-cols-1" style={{ backgroundColor: '#1f1f1f' }}>
    //                         <Link className='grid text-white' href={"/admin/edit"}>
    //                             <MenuItem className='grid w-full' icon={<AiOutlineMenu className='font-medium text-white text-[16px]' />}>

    //                             </MenuItem>
    //                         </Link>
    //                         {/* <Link className='grid text-white' href={"/admin/edit/media"}> */}
    //                         <Link className='grid text-white bg-gradient-to-r from-[#7340D8] [#5271FF] to-[#48D8E4]' href={"/admin/edit/media"}>
    //                             <MenuItem active className='grid w-full' icon={<FaPlusCircle className='font-medium text-white text-[16px]' />}>
    //                                 Media
    //                             </MenuItem>
    //                         </Link>
    //                         <Link className='grid text-white' href={"/admin/edit/mode"}>
    //                             <MenuItem className='grid w-full' icon={<FaLayerGroup className='font-medium text-white text-[16px]' />}>
    //                                 Mode
    //                             </MenuItem>
    //                         </Link>
    //                     </Menu>
    //                 </Sidebar>
    //             </div>
    //         </div>

    //         <ReactProSideBar />
    //     </div >
    // );
}
