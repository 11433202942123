
import React, { useState, ReactNode, useEffect } from "react";
import SidebarDashboard from "components/Sidebar/SidebarDashboard";
import NavigationIcon from "components/Inputs/Navigation/NavigationIcon";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/common/Loader";

export function AdminLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  console.log("🚀 ~ RootLayout:", loading);

  // const pathname = usePathname();

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <body suppressHydrationWarning={true} className="bg-[#f1f1f1]">
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <NavigationIcon />
        <SidebarDashboard />
        {loading ? <Loader /> : children}
      </div>
    </body>

  );
};

