import React, { useCallback, useEffect } from "react";
import { PlanItem } from "./PlanItem";
import { ENukeShape, EPricingPlan, PackagePlan } from "types/payment";
import usePricingPlan from "hooks/usePricingPlan";

interface MonthlyProps {
	type: EPricingPlan;
	isNuke?: boolean;
	onClick?: (plan?: PackagePlan) => void;
}

export const Monthly: React.FC<MonthlyProps> = ({ type, isNuke, onClick }) => {
	const { onGetPackages, packages } = usePricingPlan();

	const initData = useCallback(() => {
		onGetPackages({
			quantity: type === EPricingPlan.ANNUAL ? 12 : 1,
			nukeShape: isNuke ? ENukeShape.WITH_NUKE : ENukeShape.WITHOUT_NUKE,
		});
	}, [type, isNuke]);

	useEffect(() => {
		initData();
	}, [type, isNuke]);

	return (
		<div className="flex flex-row gap-6">
			{packages?.map((item) => {
				return <PlanItem key={item.id} {...item} onClick={onClick} />;
			})}
		</div>
	);
};

export default React.memo(Monthly, (prevProps, nextProps) => {
	return (
		prevProps.isNuke === nextProps.isNuke ||
		prevProps.type === nextProps.type ||
		prevProps.onClick === nextProps.onClick
	);
});
