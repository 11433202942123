import SvgIcon from "assets/SvgIcon";
import UserSettingIcon from "assets/UserSettingIcon";

export const ROUTES_API = {
	AUTH: {
		LOGIN: "/auth/login",
		LOGIN_SOCIAL: "/auth/login-social",
		REGISTER: "/auth/register",
		VERIFY_OTP: "auth/verify-otp",
		CHANGE_PASSWORD: "auth/change-password",
	},
	USER: {
		PROFILE: "/user/me",
		LOGOUT: "/user/logout",
		CHECK_READY_AI: "/online/check-ready-ai",
	},
	PROJECT: {
		CREATE: "/projects",
		DETAIL: "/projects/detail-mode",
		PLAY_SEQUENCE: "/projects/play-sequence",
		IMPORT_KEY: "/projects/import-keys",
		DELETE: '/projects'
	},
	MEDIA: {
		ROOT: "/media",
		SPLIT_FRAME: "/media/split-frame",
	},
	AI: {
		SAM_PREDICT: "/ai/sam-predict",
		EXPORT_BEZIER_CURVE: "/ai/export-bezier-curve",
		UPDATE_CONTROL_POINT: "/ai/update-control-point-v2",
		ON_RESET: "/ai/on-reset",
		SEQUENCE: "/ai/on-sequence",
		OBJECT_REMOVAL: "/ai/object-removal",
		DETECT_EDIT_MASK: "/ai/detect-edit-mask",
		EXPORT: "/ai/export",
		MASK_TO_LINE: "ai/export-bspline",
		LINE_TO_MASK: "ai/import-bspline",
	},
	PAYMENT: {
		PAYPAL: {
			ORDERS: "/payments/paypal/orders",
			APPROVE: "/payments/paypal/approve",
		},
	},
	PLAN: {
		GET_PACKAGES: "/plan",
		GET_INVOICES: "/transaction-plans/user",
	},
	SEND_MAIL: {
		OTP: "/send-mail/otp",
	},
};
export const QueryParams = {
	limit: 10,
	page: 1,
};

export const resStreamLenght = {
	maxContentLength: 100000000,
	maxBodyLength: 1000000000,
};

export type TQueryParams = {
	limit: number;
	page: number;
};

export type TImportKeys = {
	code: string;
};

export type TQueryDetailParams = {
	code: string;
	type: string;
	name?: string;
	paused?: boolean;
	isDeleted?: boolean;
	page?: number;
	limit?: number;
};

export type TUpdateProjectParams = {
	name: string;

	code?: string;
	type?: string;
};

export const colorRand = {
	1: "rgb(255, 0, 0)",
	2: "rgb(255, 190, 255)",
	3: "rgb(0, 228, 255)",
	4: "rgb(255, 255, 0)",
	5: "rgb(255, 0, 175)",
	6: "rgb(160, 115, 211)",
	7: "rgb(255, 255, 201)",
	8: "rgb(79, 174, 94)",
	9: "rgb(255, 150, 19)",
	10: "rgb(190, 25, 255)",
};

export const settingsFade = {
	dots: false,
	fade: true,
	infinite: false,
	speed: 200,
	slidesToShow: 1,
	slidesToScroll: 1,
	waitForAnimate: false,
};

export const USER_EX = "user_ex";
export const BrushModeProps = {
	title: "Brush Mode",
	obj: [
		{
			icon: "+",
			text: "Include",
		},
		{
			icon: "-",
			text: "Exclude",
		},
	],
};

export const SubmitButtonProps = {
	icon: SvgIcon.RunIcon({ width: 12, height: 12 }),
	text: "Run Roto",
	pauseText: "Pause Roto",
	continueText: "Continue Roto",
};


export const RangeSliderFXProps = [
	// {
	//   title: "Brush Size",
	//   defaultValue: 50,
	//   min: 0,
	//   max: 100,
	// },
	{
		title: "Alpha Ratio",
		defaultValue: 50,
		min: 0,
		max: 100,
	},
	{
		title: "Anti-alias",
		defaultValue: 50,
		min: 0,
		max: 100,
	},
	{
		title: "Blur Radio",
		defaultValue: 50,
		min: 0,
		max: 100,
	},
	{
		title: "Erode/Dilate",
		defaultValue: 0,
		min: -100,
		max: 100,
	},
];

export const ArrFit = ["200%", "150%", "100%", "75%", "50%"];

export const LocalKey = {
	GETTING_STARED: "GETTING_STARED",
};

export const initialOptions = {
	clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
	currency: "USD",
	intent: "capture",
};

export const MenuKeys = {
	SETTINGS: "Settings",
	// SUBSCRIPTION_OVERVIEW: "Subscription Overview",
	PAYMENT_DETAILS: "Payment Details",
	INVOICE: "Invoice",
};

export const MenusModalSetting = [
	{
		title: MenuKeys.SETTINGS,
		icon: UserSettingIcon.Setting,
	},
	// {
	// 	title: MenuKeys.PAYMENT_DETAILS,
	// 	icon: UserSettingIcon.Payment,
	// },
	{
		title: MenuKeys.INVOICE,
		icon: UserSettingIcon.Invoice,
	},
];

export const InfoUserSetting = [
	{
		label: "Name",
		key: "userName",
	},
	{
		label: "Email Address",
		key: "email",
	},
];

export const PaymentState = {
	manage: false,
	paypal: false,
	paypalAlert: false,
};
