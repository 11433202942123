import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { ISelectProps } from 'interface';

export default function ButtonGroupFX({ values, onSelected, selected, disabled = false, group = false }: ISelectProps) {
    const [checked, setChecked] = useState < any > ({});

    useEffect(() => {
        setChecked(Object.assign({}, { [selected]: true }))
    }, [selected])

    const onChange = (k: string) => {
        if (group && checked[k]) {
            return
        }
        setChecked(Object.assign({ [k]: !checked[k] }))
        onSelected?.(k)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                '& > *': {
                    m: 0,
                },
            }}
        >
            <ButtonGroup
                orientation="vertical"
                aria-label="Vertical button group"
                // variant="contained"
                variant="text"
                className='w-full m-0 p-0'
            >

                {values.map(([k, v]) => (
                    <Button key={v} className={checked[k] ? `bg-gradient-to-r from-[#7340d88c] #5272ff71 to-[#48d7e49a] rounded-[10px]` :
                        ` flex-col cursor-pointer flex shadow-lg shrink-0 grow-0 box-border`}
                        onClick={() => !disabled && onChange(k)}>
                        <div className='w-full h-[48px] flex flex-row text-white'>
                            <div className={`flex self-center w-[20px] h-[20px] rounded-full border-2 ${checked[k] ? `border-[#5272ff]` : ``} mx-4`}>
                                <div className={checked[k] ? `bg-gradient-to-r from-[#7340D8]  #5271FF to-[#48D8E4] flex self-center w-[10px] h-[10px] rounded-full mx-auto` : ` `}></div>
                            </div>
                            <div className="flex self-center capitalize" key={k}>{v}</div>
                        </div>
                    </Button>
                ))}


            </ButtonGroup>
        </Box>
    );
}