import React from "react";
import SvgIcon from "assets/SvgIcon"; // Adjust the path based on your project structure

interface UndoRedoButtonProps {
	onUndo: () => void;
	onRedo: () => void;
	isUndo: boolean;
	isRedo: boolean;
}

const UndoRedoButton: React.FC<UndoRedoButtonProps> = ({
	onUndo,
	onRedo,
	isUndo,
	isRedo,
}) => {
	return (
		<div className="flex px-6">
			<button
				className="flex items-center justify-center bg-transparent-500 text-white px-2 py-2 rounded-md"
				onClick={onUndo}>
				{SvgIcon.UndoIcon({ color: isUndo ? "#FFFFFF" : "#3C3C3C" })}
			</button>
			<button
				className="flex items-center justify-center bg-transparent text-white px-2 py-2 rounded-md"
				onClick={onRedo}>
				{SvgIcon.RedoIcon({ color: isRedo ? "#FFFFFF" : "#3C3C3C" })}
			</button>
			<button
				className="flex items-center justify-center bg-transparent text-white px-2 py-2 rounded-md ml-2"
				onClick={() => {}}>
				{SvgIcon.InfoIcon()}
			</button>
		</div>
	);
};

export default UndoRedoButton;
