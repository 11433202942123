import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { useCommon } from "hooks/useCommon";
import AvatarDefault from "assets/images/header/avatar.png";
import SvgIcon from "assets/SvgIcon";

const DropdownUser = () => {
	const { onLogout } = useAuth();
	const { profile, avt, onUpdateParams } = useCommon();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const trigger = useRef<any>(null);
	const dropdown = useRef<any>(null);

	// close on click outside
	useEffect(() => {
		console.log("====================================");
		console.log(profile);
		console.log("====================================");
	}, [profile]);

	useEffect(() => {
		const clickHandler = ({ target }: MouseEvent) => {
			if (!dropdown.current) return;
			if (
				!dropdownOpen ||
				dropdown.current.contains(target) ||
				trigger.current.contains(target)
			)
				return;
			setDropdownOpen(false);
		};
		document.addEventListener("click", clickHandler);
		return () => document.removeEventListener("click", clickHandler);
	});

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }: KeyboardEvent) => {
			if (!dropdownOpen || keyCode !== 27) return;
			setDropdownOpen(false);
		};
		document.addEventListener("keydown", keyHandler);
		return () => document.removeEventListener("keydown", keyHandler);
	});

	return (
		<div className="relative">
			<Link
				ref={trigger}
				onClick={() => setDropdownOpen(!dropdownOpen)}
				className="flex items-center gap-4"
				to="#">
				<div className=" flex flex-row items-center justify-center h-full">
					<span className="mr-2 rounded-full">
						{false ? (
							<img
								width={112}
								height={112}
								src={`data:image/jpeg;base64,${avt.avatar}`}
								style={{
									width: "auto",
									height: "auto",
								}}
								alt="User"
							/>
						) : (
							<img
								width={112}
								height={112}
								src={AvatarDefault}
								style={{
									width: "auto",
									height: "auto",
								}}
								alt="User"
							/>
						)}
					</span>
					<span className="hidden text-right lg:block">
						<span className="block text-[16px] text-white">
							{profile?.userName}
						</span>
					</span>
				</div>

				{SvgIcon.DownArrowIcon()}
			</Link>

			{/* <!-- Dropdown Start --> */}
			<div className="absolute right-0 mt-2">
				<div
					ref={dropdown}
					onFocus={() => setDropdownOpen(true)}
					onBlur={() => setDropdownOpen(false)}
					className={`border-gradient flex w-[199px] flex-col shadow-default ${
						dropdownOpen === true ? "block" : "hidden"
					}`}>
					<ul className="bg-[#1F1F1F] flex flex-col border-b border-indigo-500 text-white px-6">
						<li
							className="z-999 py-4"
							onClick={() => {
								onUpdateParams({ openModalSetting: true });
								setDropdownOpen(!dropdownOpen);
							}}>
							<button className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out lg:text-base">
								<span>{SvgIcon.SettingsIcon()}</span>
								<span>Settings</span>
							</button>
						</li>
					</ul>
					<button
						onClick={onLogout}
						className="z-999 flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hovr:text-preimary lg:text-base text-white ">
						<span>{SvgIcon.LogoutIcon()}</span>
						<span>Log Out</span>
					</button>
				</div>
			</div>
			{/* <!-- Dropdown End --> */}
		</div>
	);
};

export default DropdownUser;
