import React from "react";

import { BlankLayout } from "components/Layouts/BlankLayout";
import { LeftLayout } from "components/Auth/LeftLayout";
import { FormLayout } from "components/Auth/FormLayout";

const SignIn: React.FC = () => {

  return (
    <BlankLayout>
      <div className="w-full flex justify-evenly items-center ">
        <LeftLayout />
        <FormLayout />
      </div>

    </BlankLayout>
  );
};

export default SignIn;
