import { Box, Modal, Typography } from "@mui/material";
import { ReactProgressBar } from "components/BottomContentBar";

interface PropsType {
	open: boolean;
	isSubmit?: boolean;
	progress?: number;
	totalFrame?: number;
}

function ModalLoading({ open, progress, totalFrame }: PropsType) {
	return (
		<Modal
			open={open}
			onClose={() => {}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className="flex items-center justify-center text-white border-0 border-transparent focus:border-transparent focus:ring-0">
			<Box
				height={"250px"}
				width={"400px"}
				borderRadius={"10px"}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				bgcolor={"#1F1F1F"}
				padding={"25px"}>
				<Box width={"100%"} height={"40px"} borderBottom={"1px solid #3C3C3C"}>
					<Typography
						fontWeight={"700"}
						fontSize={"16px"}
						textAlign={"left"}
						component={"p"}>
						Upload video
					</Typography>
				</Box>
				<Box
					flex={1}
					width={"100%"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}>
					<Typography
						width={"100%"}
						fontWeight={"600"}
						fontSize={"12px"}
						textAlign={"left"}
						lineHeight={"40px"}
						component={"p"}>
						You are almost done
					</Typography>
					<ReactProgressBar cur={progress} total={totalFrame} />
					<Typography
						width={"100%"}
						fontWeight={"400"}
						fontSize={"12px"}
						textAlign={"left"}
						lineHeight={"40px"}
						marginTop={"-5px"}
						component={"p"}>
						1 minutes left...
					</Typography>
				</Box>
			</Box>
		</Modal>
	);
}

export default ModalLoading;
