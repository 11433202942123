const BSplineIcon = {
    Line: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
            <path d="M2.13268 10C1.50329 5.33507 2.95088 0.7445 10 1.01107" stroke="white" />
            <rect width="2" height="2" fill="#D9D9D9" />
            <path d="M6.44644 7.596C6.2411 7.596 6.0571 7.54933 5.89444 7.456C5.73177 7.36533 5.60777 7.24133 5.52244 7.084L5.78644 6.832C5.8611 6.94667 5.9571 7.03733 6.07444 7.104C6.19177 7.17333 6.3171 7.208 6.45044 7.208C6.55977 7.208 6.6571 7.184 6.74244 7.136C6.82777 7.09067 6.89444 7.02667 6.94244 6.944C6.9931 6.85867 7.01844 6.76133 7.01844 6.652C7.01844 6.54533 6.99444 6.452 6.94644 6.372C6.89844 6.28933 6.83444 6.22667 6.75444 6.184C6.67444 6.13867 6.58244 6.116 6.47844 6.116C6.3771 6.116 6.28244 6.13867 6.19444 6.184C6.10644 6.22933 6.03444 6.29333 5.97844 6.376H5.59044L5.91444 4.716H7.30244V5.12H6.23444L6.09044 5.896C6.13577 5.848 6.1971 5.80933 6.27444 5.78C6.35177 5.748 6.43977 5.732 6.53844 5.732C6.7171 5.732 6.87577 5.772 7.01444 5.852C7.15577 5.92933 7.26644 6.036 7.34644 6.172C7.4291 6.308 7.47044 6.46533 7.47044 6.644C7.47044 6.83333 7.4251 6.99867 7.33444 7.14C7.24377 7.284 7.1211 7.396 6.96644 7.476C6.81177 7.556 6.63844 7.596 6.44644 7.596Z" fill="white" />
        </svg>
    ),
    Plus: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
            <path d="M6.5 4H4V6.5C4 6.775 3.775 7 3.5 7C3.225 7 3 6.775 3 6.5V4H0.5C0.225 4 0 3.775 0 3.5C0 3.225 0.225 3 0.5 3H3V0.5C3 0.225 3.225 0 3.5 0C3.775 0 4 0.225 4 0.5V3H6.5C6.775 3 7 3.225 7 3.5C7 3.775 6.775 4 6.5 4Z" fill="white" />
        </svg>
    ),
    Delete: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9" fill="none">
            <path d="M0.5 8C0.5 8.55 0.95 9 1.5 9H5.5C6.05 9 6.5 8.55 6.5 8V3C6.5 2.45 6.05 2 5.5 2H1.5C0.95 2 0.5 2.45 0.5 3V8ZM2 3H5C5.275 3 5.5 3.225 5.5 3.5V7.5C5.5 7.775 5.275 8 5 8H2C1.725 8 1.5 7.775 1.5 7.5V3.5C1.5 3.225 1.725 3 2 3ZM5.25 0.5L4.895 0.145C4.805 0.055 4.675 0 4.545 0H2.455C2.325 0 2.195 0.055 2.105 0.145L1.75 0.5H0.5C0.225 0.5 0 0.725 0 1C0 1.275 0.225 1.5 0.5 1.5H6.5C6.775 1.5 7 1.275 7 1C7 0.725 6.775 0.5 6.5 0.5H5.25Z" fill="white" />
        </svg>
    ),
    AddPoint: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
            <path d="M7.29991 9.737L3.81691 10.3835C3.14891 10.5075 2.81491 10.5695 2.62241 10.3775C2.43041 10.185 2.49241 9.851 2.61641 9.1825L3.26291 5.7C3.36691 5.1415 3.41841 4.8625 3.60291 4.6935C3.78691 4.525 4.12341 4.492 4.79691 4.426C5.44591 4.3625 6.05991 4.14 6.69991 3.5L9.49991 6.3C8.85991 6.94 8.63741 7.554 8.57341 8.203C8.50791 8.8765 8.47491 9.213 8.30591 9.3975C8.13741 9.5815 7.85791 9.633 7.29991 9.737Z" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.5 7.605C6.22158 7.56342 5.96353 7.43457 5.763 7.237M5.763 7.237C5.56543 7.03647 5.43658 6.77842 5.395 6.5M5.763 7.237L3 10M6.75 3.5C7.0665 3.033 7.745 2.0805 8.3805 2.005C8.8145 1.953 9.1735 2.3125 9.892 3.031L9.969 3.108C10.6875 3.8265 11.047 4.1855 10.995 4.6195C10.9195 5.2545 9.967 5.9335 9.5 6.25M2.5 3.5V0.5M1 2H4" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    ),
    RemovePoint: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
            <path d="M7.29991 8.987L3.81691 9.6335C3.14891 9.7575 2.81491 9.8195 2.62241 9.6275C2.43041 9.435 2.49241 9.101 2.61641 8.4325L3.26291 4.95C3.36691 4.3915 3.41841 4.1125 3.60291 3.9435C3.78691 3.775 4.12341 3.742 4.79691 3.676C5.44591 3.6125 6.05991 3.39 6.69991 2.75L9.49991 5.55C8.85991 6.19 8.63741 6.804 8.57341 7.453C8.50791 8.1265 8.47491 8.463 8.30591 8.647C8.13741 8.8315 7.85791 8.883 7.29991 8.987Z" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.5 6.855C6.22158 6.81342 5.96353 6.68457 5.763 6.487M5.763 6.487C5.56543 6.28647 5.43658 6.02842 5.395 5.75M5.763 6.487L3 9.25M6.75 2.75C7.0665 2.283 7.745 1.3305 8.3805 1.255C8.8145 1.203 9.1735 1.5625 9.892 2.281L9.969 2.358C10.6875 3.0765 11.047 3.4355 10.995 3.8695C10.9195 4.5045 9.967 5.1835 9.5 5.5M1 1.25H4" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    ),
    SavePoint: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
            <path d="M7.295 0.295C7.105 0.105 6.85 0 6.585 0H1C0.445 0 0 0.45 0 1V8C0 8.55 0.45 9 1 9H8C8.55 9 9 8.55 9 8V2.415C9 2.15 8.895 1.895 8.705 1.71L7.295 0.295ZM4.5 8C3.67 8 3 7.33 3 6.5C3 5.67 3.67 5 4.5 5C5.33 5 6 5.67 6 6.5C6 7.33 5.33 8 4.5 8ZM5 3H2C1.45 3 1 2.55 1 2C1 1.45 1.45 1 2 1H5C5.55 1 6 1.45 6 2C6 2.55 5.55 3 5 3Z" fill="white" />
        </svg>
    )
}
export default BSplineIcon