import React from 'react'

interface IRightModal {
    children: React.ReactNode
    className?: string
}

export default function RightModal({
    children,
    className

}: IRightModal) {
    return (
        <div className={` p-4 rounded-r-[8px] flex flex-col ${className}`}>
            {children}
        </div>
    )
}
