import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import appReducer from "./slices/app";
import managementReducer from "./slices/management";
import projectReducer from "./slices/projects";

const store = configureStore({
  reducer: {
    user: userReducer,
    app: appReducer,
    management: managementReducer,
    project: projectReducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
