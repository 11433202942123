import { Sidebar } from "react-pro-sidebar";
import Dropzone from "components/Inputs/Dropzone";
import "assets/css/GradientBorder.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import { visModeOption } from "utils/data/video";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import ButtonGroupFX from "../Buttons/ButtonGroupFX";
import SvgIcon from "assets/SvgIcon";
import { useTour } from "@reactour/tour";
import { colorRand } from "utils/constants";

export default function ReactProSideBar({
	visMod,
	images,
	onChangeVisMode,
	onChange,
	objectIdx,
	onObjectIdx,
	autoPlay,
	isTour,
	isDisable,
}: any) {
	// const [collapsed, setCollapsed] = React.useState(false);
	const { height } = useWindowDimensions();
	const { currentStep, isOpen } = useTour();

	return (
		<div
			className={`flex flex-col content-between place-content-between pl-2 relative ${isDisable && "isDisable"
				}`}
			style={{ display: "flex", height: height - 170 }}>
			<div className="bg-[#1F1F1F] mt-5 border border-[#3c3c3c] rounded-lg py-1 lg:px-4 px-2 h-full overflow-y-scroll scrollbar-hide">
				<Sidebar
					backgroundColor="#1f1f1f"
					width={"219px"}
					transitionDuration={1000}
					rootStyles={{
						border: "none",
					}}>
					<div
						data-tour="step-1"
						className={`${currentStep === 0 && isOpen && "border-gradient px-3"
							}`}>
						<div className="grid w-full h-[56px] bg-[#1F1F1F]">
							<div className="flex self-center px-0">
								<h1 className="font-medium text-white text-[16px]"> Media </h1>
							</div>
						</div>
						<div className="py-4 bg-[#1F1F1F] h-fit">
							<Dropzone onChange={onChange} />
						</div>
					</div>

					{!!images?.length || isTour ? (
						<div
							data-tour="step-2"
							className={`${currentStep === 1 && isOpen && "border-gradient px-3"
								}`}>
							<h3 className="py-4 font-semibold text-white">Choose ObjectID</h3>
							<Box
								sx={{ minWidth: 120 }}
								className="border-gradient text-white">
								<FormControl
									fullWidth
									sx={{
										boxShadow: "none",
										".MuiOutlinedInput-notchedOutline": { border: 0 },
									}}>
									<Select
										labelId="select-label"
										id="select-label"
										disabled={autoPlay}
										value={objectIdx}
										onChange={onObjectIdx}
										IconComponent={() => <SvgIcon.DownArrowIcon />}
										sx={{
											padding: "0 10px",
											boxShadow: "none",
											".MuiOutlinedInput-notchedOutline": { border: 0 },
											svg: { color: "white", width: "15px" },
											color: "white",
											backgroundColor: colorRand?.[objectIdx],

										}}>
										{[...Array(11)].map((_, v) =>
											v ? (
												<MenuItem
													sx={{
														color: "#ffffff",
														backgroundColor: colorRand?.[v],
													}}
													key={v}
													value={v}>
													{v}
												</MenuItem>
											) : null,
										)}
									</Select>
								</FormControl>
							</Box>
							{/* <BasicModal /> */}
						</div>
					) : (
						<></>
					)}

					{images?.length > 0 || isTour ? (
						<div
							data-tour="step-3"
							className={` py-4 ${currentStep === 2 && isOpen && "border-gradient px-3"
								}`}>
							<h3 className="py-4 font-semibold text-white">Mode</h3>
							<div className="border-gradient">
								<ButtonGroupFX
									values={Object.entries(visModeOption)}
									selected={visMod}
									disabled={autoPlay}
									group={true}
									onSelected={onChangeVisMode}
								/>
							</div>
						</div>
					) : (
						<></>
					)}
				</Sidebar>
			</div>

			{/* <BottomLeftSideBar /> */}
		</div>
	);
}
