import { StepType } from "@reactour/tour";
import { TourItem } from "components/TourGuide";
import { USER_EX } from "utils/constants";

const steps: StepType[] = [
  {
    selector: '[data-tour="step-1"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Upload Video"
        content="You can upload a video/image to be processed after the tour guide finishes."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setIsOpen(false)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: [270, 100],
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-2"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Choose ObjectID"
        content="This is where you can choose the number of masks. Each number will have a different color"
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "right",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-3"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Choose Mode"
        content="Once you've selected the Object ID, click here to see the modes:
Davis: Show the applied mask with the image.
Image: Show the original image and mask.
Mask: Show only the mask.
Transparent: Show the final result.
"
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "right",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-4"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Frame and progress"
        content="The total number of frames and the progress are shown here!"
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "top",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-5"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Timeline Bar"
        content="You can see the timeline here."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "top",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-6"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Export Video"
        content="Click here to export the result."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "bottom",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-7"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Edit with Bezier Curve"
        content="Bezier Curves are smooth linear transitions between two vertices. The shape of the curve is defined by the locations of the vertices and additional control points at the end of blue Bezier handles that radiate from each vertex."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "bottom",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-8"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Reset Object ID"
        content="If you select the wrong Object ID, you can reset here."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "left",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-9"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Brush Mode"
        content="Include/exclude: You can use the brush to add or delete the selected area."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "left",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-10"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Alpha Ratio"
        content="You can customize the intensity of the current mask here."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "left",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-11"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Run Roto"
        content="If you want to apply the current mask to all frames of the video, you can click here."
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "left",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-12"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Button Action"
        content="Play: Play video
                  Previous: Go back 1 frame.
                  Next: Move to 1 frame"
        btnBackTitle="Previous"
        btnNextTitle="Next"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={() => setCurrentStep(currentStep + 1)}
      />
    ),
    position: "top",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
  {
    selector: '[data-tour="step-13"]',
    content: ({ currentStep, setCurrentStep, setIsOpen }) => (
      <TourItem
        title="Intro Guide"
        content="You can view this tour at anytime by clicking the ? icon"
        btnBackTitle="Previous"
        btnNextTitle="Finish"
        onClose={() => setIsOpen(false)}
        onBack={() => setCurrentStep(currentStep - 1)}
        onNext={async () => {
          await localStorage.setItem(USER_EX, 'ex_user')
          setIsOpen(false)
        }}
      />
    ),
    position: "bottom",
    styles: {
      popover: (base) => ({
        ...base,
        backgroundColor: "#191A1E",
        color: "white",
        padding: "0px",
        borderRadius: "8px",
      }),
    },
  },
];

export { steps };
