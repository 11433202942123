export type TOrderPayPal = {
	reference_id: string;
	qty?: string;
};

export type TApprovePayPal = {
	orderID: string;
};

export enum EPricingPlan {
	ANNUAL = "Annual",
	MONTHLY = "Monthly",
}

export enum EnumPlanType {
	FREE = "FREE",
	PRO = "PRO",
	PREMIUM = "PREMIUM",
	ENTERPRISE = "ENTERPRISE",
}

export type PackagePlan = {
	id: string;
	packageName?: string;
	price?: number;
	priceSale?: number;
	description?: string[];
	btnText?: string;
	unit?: string;
	discountPercent?: number;
	serviceTile?: string;
	isBuyMore?: boolean;
	isNukeShape?: boolean;
	sortType?: number;
	annualPrice?: number;
};
export type ServiceItem = {
	id?: number;
	name?: string;
};
export type TModalType = "warn" | "success";

export type TPackageParams = {
	limit?: number;
	page?: number;
	nukeShape?: ENukeShape; // 0: all, 1: nukeShape: false, 2: nukeShape: true
	quantity?: number; // 1: month, 12: year
};
export enum ENukeShape {
	ALL = 0,
	WITHOUT_NUKE = 1,
	WITH_NUKE = 2,
}

export enum BtnPlanTitle {
	"FREE" = "Get Started",
	"PRO" = "Go Pro",
	"PREMIUM" = "Go Premium",
	"ENTERPRISE" = "Go Enterprise",
}

export enum QUANTITY {
	"Annual" = "12",
	"Monthly" = "1",
}

export type InvoiceType = {
	data: string;
	invoice?: string;
	credit?: number;
	amount?: number;
};
