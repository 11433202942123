
import React, { useState, ReactNode, useEffect } from "react";
import Sidebar from "components/Sidebar";
import Header from "components/Header";
import { useAuth } from "hooks/useAuth";

export default function DefaultLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { onProfile } = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    onProfile();
  }, [])
  return (
    <>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden bg-[#1F1F1F]">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
          {/* <!-- ===== Header Start ===== --> */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="p-4 mx-auto md:p-6 2xl:p-10">
              {children}

            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </>
  );
}
