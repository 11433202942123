import { object, string, number, ref } from "yup";

export const samPredictSchema = object({
  points: string().required().min(3),
  labels: string().required().min(3),
  object_idx: string().required().min(1),
  vis_mode: string().required().min(3),
  code: string().required().min(3),
  segment_type: string().required().min(3),
});

export const getVisModeSchema = object({
  blob_id: string().required().min(1),
  code: string().required().min(3),
  type: string().required().min(3),
});

export const streamSequenceSchema = object({
  mask: string().required().min(1),
  code: string().required().min(3),
  type: string().required().min(3),
});

export const getExportBezierCurveSchema = object({
  blob_id: string().required().min(3),
  object_idx: string().required().min(1),
  code: string().required().min(3),
  width: string().required().min(1),
  height: string().required().min(1),
});

export const getUpdateBezierCurveSchema = object({
  mask: string().required().min(3),
  type: string().required().min(1),
  code: string().required().min(3),
});

export const onResetSchema = object({
  blob_id: string().required().min(3),
  object_idx: string().required().min(1),
  vis_mode: string().required(),
  type: string().required(),
  code: string().required().min(3),
});

export const onDetectMaskSchema = object({
  blur_ratio: number().required(),
  anti_alias_level: number().required(),
  erode_ratio: number().required(),
  dilate_ratio: number().required(),
  type: string().required(),
  code: string().required().min(3),
  mask: string().required().min(3),
});

export const onExportRotoSchema = object({
  typeExport: string().required(),
  type: string().required(),
  code: string().required().min(3),
  mask: string().required().min(3),
});

export const validationSchema = object({
  userName: string().required("Name is required"),
  email: string().email("Invalid email address").required("Email is required"),
  password: string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  repassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const changePasswdSchema = object({
  newPassword: string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

export const signInSchema = object({
  email: string().email("Invalid email address").required("Email is required"),
  password: string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

export const forgotPasswordSchema = object({
  email: string()
    .email("Invalid email address")
    .required("Email is not in the correct format, please try again"),
  // otp: string().required("OTP expried").required("OTP is incorrect, please try again"),
});
