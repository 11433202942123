import { useEffect, useState } from "react";
import { useCommon } from "hooks/useCommon";

import { IDropdownFX, IOption } from "interface";
import SvgIcon from "assets/SvgIcon";
import DefaultSelection from "components/SelectGroup/DefaultSelection";
import { TypeExport } from "types/video";

const defaults_options = [
	{
		label: `Video`,
		value: TypeExport.VIDEO,
	},
	{
		label: `Project`,
		value: TypeExport.PROJECT,
	},
	{
		label: `Roto Shape`,
		value: TypeExport.SHAPE,
	},
];

interface IDropdownMore extends IDropdownFX {
	icon?: any
}
export default function DropdownMore({
	onSelected,
	options = defaults_options,
	icon = SvgIcon.MoreIcon()
}: IDropdownMore) {
	const { onUpdateParams, expand } = useCommon();

	const [hidden, setHidden] = useState<boolean>(true);

	const handleOptionClick = (option) => {
		onSelected(option.value);
		setHidden(!hidden);
	};

	useEffect(() => {
		if (expand) {
			setHidden(true);
			onUpdateParams({ expand: true });
		}
	}, [expand]);

	return (
		<div
			className="relative w-200"
			onClick={(e) => {
				e.stopPropagation();

				setHidden(!hidden);
			}}>
			<button>{icon}</button>

			{!hidden ? (
				<div className="absolute top-0 right-3 w-[146px] mt-6">
					<DefaultSelection
						options={options}
						onOptionClick={handleOptionClick}
						className="pl-4 p-2 text-[13px] "
					/>
				</div>
			) : (
				""
			)}
		</div>
	);
}
