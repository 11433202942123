import Robot from 'assets/images/robot/ROBOT.png';
export function LeftLayout() {
    return (
        <div className="lg:flex items-center content-center hidden">
            <img
                src={Robot}
                alt="Logo"
                width={732}
                height={767.88}
            />
        </div>

    )
}
