export const visModeOption = {
	images: "Image",
	davis: "Davis",
	mask: "Mask",
	transparent: "Transparent",
	binary_masks: "Binary Mask"
	// layer: 'layer',
};

export const segmentTypeOption = {
	click: "Click",
	box: "box",
};

export const modeVideoOption = {
	view: "View",
	edit: "Edit",
};

export const resetOption = {
	// FRAME: 'FRAME',
	OBJECT: "OBJECT",
};

export const imagesState = {
	images: [],
	mask: [],
	transparent: [],
	davis: [],
	binary_masks: [],
};

export const controlPoints = [
	[
		"297",
		"286",
		"335.00189999999697",
		"276.4704288052158",
		"315.99809999999695",
		"281.1380288052158",
	],
	[
		"295",
		"281",
		"291.0456795147689",
		"288.04507951476893",
		"291.66720000000055",
		"286.3328000000006",
	],
	[
		"301.5592892315844",
		"273.33409999999975",
		"265.66589999999974",
		"308.8940892315844",
		"317",
		"258",
	],
	[
		"391",
		"359.6756269653137",
		"246.0012",
		"335.0040269653137",
		"233.99880000000002",
		"222",
	],
	["216", "391", "396", "222"],
	[
		"199.3325000000001",
		"207.6675000000001",
		"387.75326810631697",
		"191",
		"382.75226810631693",
		"381",
	],
	[
		"396",
		"383.6351651785717",
		"378.63416517857166",
		"182",
		"184.99970000000022",
		"188.0003000000002",
	],
	[
		"452",
		"198",
		"421.9616524934105",
		"440.63205249341047",
		"187.3328",
		"192.6672",
	],
	[
		"207.999",
		"228",
		"466.80591213822447",
		"218.001",
		"460.47131213822445",
		"471",
	],
	[
		"233",
		"503.1432722445249",
		"231.3335000000008",
		"229.6665000000008",
		"503",
		"492.47447224452486",
	],
	[
		"552",
		"530.7655272347432",
		"255",
		"514.4289272347432",
		"247.66739999999996",
		"240.33259999999996",
	],
	[
		"612.9112617438933",
		"252.33319999999975",
		"251",
		"633",
		"585.9058617438933",
		"253.66679999999977",
	],
	[
		"256.3327999999999",
		"267",
		"261.6671999999999",
		"641.3044631791038",
		"647",
		"645.9720631791038",
	],
	[
		"272.6661000000002",
		"643.2500628539027",
		"284",
		"642",
		"278.3339000000002",
		"641.5830628539027",
	],
	[
		"654.9946834319541",
		"276",
		"281.3335999999992",
		"661",
		"278.6663999999992",
		"648.6600834319541",
	],
	[
		"278.0001000000003",
		"279",
		"276.9999000000003",
		"684.5643461538485",
		"687",
		"675.8959461538485",
	],
	[
		"283.66620000000006",
		"716",
		"709.7844659306655",
		"700.1158659306656",
		"288.33380000000005",
		"293",
	],
	[
		"299.0003",
		"721.1316321594843",
		"295.99969999999996",
		"302",
		"719.4646321594843",
		"721",
	],
	[
		"304.66640000000024",
		"310",
		"715",
		"307.3336000000002",
		"721.6957736972711",
		"723.6961736972711",
	],
	[
		"693.6828167844074",
		"310.6666000000006",
		"312",
		"689",
		"702.3512167844074",
		"311.33340000000055",
	],
	[
		"334.66440000000006",
		"357.33560000000006",
		"659",
		"666.2864116328855",
		"380",
		"676.2884116328854",
	],
	[
		"655.0492330221408",
		"385.9994000000002",
		"649.3814330221409",
		"392.0006000000002",
		"642",
		"398",
	],
	[
		"400.66639999999904",
		"573",
		"403.3335999999991",
		"406",
		"613.9159621797595",
		"590.9113621797595",
	],
	[
		"560.6931750000406",
		"569.0281750000406",
		"548",
		"404.33350000000047",
		"401",
		"402.66650000000044",
	],
	[
		"411.6672000000001",
		"532.9575351606542",
		"406.33280000000013",
		"417",
		"520",
		"542.2927351606542",
	],
	[
		"486.3119335182825",
		"465",
		"504.6489335182825",
		"427.0004999999986",
		"421.99949999999865",
		"432",
	],
	[
		"468.38758340819487",
		"406",
		"414.66579999999976",
		"423.3341999999997",
		"469",
		"469.72118340819486",
	],
	[
		"468.17086599699303",
		"383",
		"460",
		"465.17026599699307",
		"398.33410000000043",
		"390.6659000000005",
	],
	[
		"452",
		"459.66670000001733",
		"380.66689999999886",
		"456.99950000001735",
		"378.33309999999886",
		"376",
	],
	[
		"367",
		"369.9997000000006",
		"373.0003000000006",
		"447.1041664277703",
		"416",
		"435.1017664277703",
	],
	[
		"413.22414418604046",
		"360.3329999999997",
		"414",
		"363.6669999999997",
		"357",
		"412.55734418604044",
	],
	[
		"414.67361577181055",
		"354.6668999999989",
		"350",
		"352.3330999999989",
		"416.3406157718105",
		"419",
	],
	[
		"347.6668999999997",
		"343",
		"434.2567420770459",
		"441.9249420770459",
		"345.3330999999997",
		"442",
	],
	[
		"456.17033642501275",
		"332.9994999999994",
		"328",
		"338.0004999999994",
		"462",
		"449.50233642501274",
	],
	[
		"470",
		"311.3324999999998",
		"466.44326322161254",
		"303",
		"469.1104632216126",
		"319.6674999999998",
	],
	[
		"469.6797973731373",
		"291.6660999999997",
		"297.33389999999963",
		"286",
		"470.3465973731373",
		"468",
	],
	[
		"266",
		"272.6659999999998",
		"461.4222150199512",
		"279.33399999999983",
		"453",
		"466.4232150199512",
	],
	[
		"452.536216015529",
		"263.3336",
		"441.200616015529",
		"419",
		"258",
		"260.66639999999995",
	],
	[
		"248.66619999999995",
		"418",
		"419.88869999999935",
		"244",
		"420.22209999999933",
		"253.33379999999994",
	],
	[
		"415.22969192535436",
		"230",
		"234.66620000000003",
		"411.89569192535436",
		"408",
		"239.33380000000002",
	],
	[
		"233",
		"404.0003999999883",
		"232.00009999999932",
		"403.00019999998835",
		"405",
		"230.99989999999931",
	],
	[
		"412.0460161930788",
		"415",
		"415.3800161930788",
		"241.3325000000001",
		"258",
		"249.6675000000001",
	],
	[
		"395",
		"273",
		"398.221214452898",
		"262.9994999999999",
		"268.00049999999993",
		"404.889214452898",
	],
	[
		"392.53831923076865",
		"391",
		"391.20471923076866",
		"282.33380000000056",
		"277.6662000000006",
		"287",
	],
	[
		"394",
		"390.05555725300235",
		"389.05535725300234",
		"313.6680000000002",
		"327",
		"300.33200000000016",
	],
	[
		"333.6660000000002",
		"399.7983063348091",
		"412",
		"405.7995063348091",
		"340.33400000000023",
		"347",
	],
	[
		"412.2444200000001",
		"364.33419999999944",
		"411",
		"373",
		"355.6657999999995",
		"411.91102000000006",
	],
	[
		"394",
		"393",
		"379.99930000000086",
		"387.00070000000085",
		"401.0348067281006",
		"395.0336067281006",
	],
	[
		"394",
		"428.0016999999998",
		"410.9982999999998",
		"390.24493690141003",
		"445",
		"390.57833690141",
	],
	[
		"440",
		"359.81126785724757",
		"441.66650000000146",
		"443.33350000000144",
		"373.81406785724755",
		"352",
	],
	[
		"324.7163356529087",
		"338.0523356529087",
		"312",
		"436.00040000000064",
		"428",
		"431.99960000000067",
	],
	["312", "428", "406", "290"],
	[
		"273",
		"381.33209999999934",
		"285.4345369035221",
		"279.7667369035221",
		"369",
		"393.6678999999993",
	],
];
