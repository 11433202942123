import { InputFX } from 'components/Inputs'
import React from 'react'
import { IInputFX } from 'types/auth'


export default function InputSendOTP({
    type,
    name,
    value,
    placeholder,
    formData,
    setFormData,
    errors,
    setErrors,
    required = false
}: IInputFX) {
    return (<InputFX
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        formData={formData}
        setFormData={setFormData}
        errors={errors?.email}
        setErrors={setErrors}
        required={required}
    />)
}
