



export default function Input(prop: any) {
  const { name, type, placeholder, setFormData, formData, value, setErrors, errors } = prop
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  return (
    <div className="mb-5">
      <div className=" border-gradient ">
        <div className="relative">
          <input
            onChange={handleInputChange}
            autoComplete="off"
            type={type}
            placeholder={placeholder}
            className="w-full py-4 pl-6 pr-10 bg-transparent border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient"
          />
        </div>

      </div>
      {errors && <div className="text-[#e03636] text-sm pt-1">{errors} *</div>}
    </div>

  )
}
