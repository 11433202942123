import { useEffect, useRef } from "react";
import { useCallback, useState } from "react";
import { EControl, TypeExport } from "types/video";
import Loader from "components/common/Loader";
import Timeline from "./Timeline";
import useAITools from "hooks/useAITools";
import { getBlobId, getScaleIMG } from "utils/libs";
import { EMode, EModeFrame, ESegmentType } from "types/ai";

import BezierCurve from "./BezierCurve";
import { useLocation, useParams } from "react-router-dom";
import DetailLayout from "components/Layouts/DetailLayout";
import ReactProSideBar from "components/ReactProSideBar/ReactProSideBar";
import useWindowDimensions from "hooks/useWindowDimensions";
import BottomContentBar from "./BottomContentBar";
import TopContentBar from "./TopContentBar";
import { useCommon } from "hooks/useCommon";
import { convertPercentageToDecimal, loadImage } from "utils/helper";
import useProject from "hooks/useProject";
import { RightSidebar } from "components/RightSidebar";
import InPainting from "./InPainting";
import { USER_EX } from "utils/constants";
import { useTour } from "@reactour/tour";
import BSplineCurveCus, {
	transformPointsScaleToOrigin,
} from "components/DrawingLine/BSplineCurveCus";
import BezierCurveCus from "components/DrawingLine/BezierCurveCus";
import useFullScreen from "hooks/useFullScreen";
import FooterFullscreen from "./FooterFullscreen";
import useProgressUpload from "hooks/useProgressUpload";
import { ResetType } from "types/RightSidebar/IBrushMode";
import ModalCustomRoto from "components/modals/ModalCutomRoto";
import { ModalExport } from "components/modals";
import ModalStatusExport from "components/modals/ModalStatusExport";
import useProgressExport from "hooks/useProgressExport";
import { imagesState } from "utils/data/video";
import usePsLine from "hooks/usePsLine";
import ModalControlPoint from "components/modals/ModalControlPoint";
import { useAlert } from "hooks/useAlert";
import RightSideBSPline from "components/RightSidebar/RightSideBSPline";
import SelectionObject from "./SelectionObject";
import { useRemovalObject } from "hooks/useRemovalObject";
import { useHotkeys } from "react-hotkeys-hook";

export default function DetailPage() {
	const { warn } = useAlert();
	// ===================== STATE B_SPLINE ====================
	const { onMaskToBsPLine, onBsPLineToMask, loadingPsLine } = usePsLine();
	const [controlPoints, setControlPoints] = useState<Array<[number, number]>>(
		[],
	);
	const [openControlPoint, setOpenControlPoint] = useState(false);
	const [controlPoint, setControlPoint] = useState<Array<[number, number]>>();
	const [undoHistory, setUndoHistory] = useState([]);

	const [isNewCurve, setIsNewCurve] = useState(true);
	const [isDeleteCurve, setIsDeleteCurve] = useState(false);
	const [isAddPoint, setIsAddPoint] = useState(false);
	const [isRemovePoint, setIsRemovePoint] = useState(false);
	const [selectedSpline, setSelectedSpline] = useState(0);

	// ===================== END STATE B_SPLINE ====================

	const { width: widthWindow, height: heightWindow } = useWindowDimensions();
	const { elementRef, isFullScreen, toggleFullScreen } = useFullScreen();

	const { plug: code } = useParams() || "";
	const {
		onSamPredict,
		onUpdateBezierCurve,
		onReset,
		loading,
		onDetectEditMask,
		onExportRoto,
		onSequence,
	} = useAITools();
	const {
		onSplitFrame,
		onUpdateParams,
		brushMode,
		socket,
		profile,
		totalFrame: totalFrames,
	} = useCommon();

	const { onGetDetail, onImportKeys } = useProject();
	const [curFrame, setCurFrame] = useState<any>(1);
	const [vertices, setVertices] = useState<any>({});
	const [visMod, setVisMode] = useState<EMode>(EMode.IMAGE);
	const [segmentType, setSegmentType] = useState<ESegmentType>(
		ESegmentType.CLICK,
	);
	const [objectIdx, setObjectIdx] = useState<string>("1");
	const [modeFrame, setModeFrame] = useState<EModeFrame>(EModeFrame.VIEW);
	const [imagesOrigin, setImagesOrigin] = useState<any>(imagesState);
	const [imageAttr, setImageAttr] = useState<any>({ width: 0, height: 0 });
	const [imagesMask, setImagesMask] = useState<any>({});

	const [status, setStatus] = useState("IDLE");
	const [isTour, setIsTour] = useState(false);
	const [isShowBtn, setIsShowBtn] = useState(false);

	const { setIsOpen } = useTour();
	const [stateFit, setStateFit] = useState("100%");
	const [isProgressUpload, setIsProgressUpload] = useState(false);

	const [countThumbSequence, setCountThumbSequence] = useState(0);
	const [currentBuffer, setCurrentBuffer] = useState<{
		url: string;
		name: string;
	} | null>(null);

	const [loadingSequence, setLoadingSequence] = useState(false);

	const intervalRef = useRef<any>(null);
	const [autoPlay, setAutoPlay] = useState(false);
	const videoRef = useRef({ duration: 0 }).current;
	const [isProcessingSequence, setIsProcessingSequence] = useState(false);
	const { progress, setProgress, totalFrame } = useProgressUpload(code!);
	const { state } = useLocation();
	const [isChanged, setIsChanged] = useState<number | null>(null);
	const [isModalExportSharp, setIsModalExportSharp] = useState(false);
	const {
		loadingRm,
		onUpdateLoadingRm,
		onUpdateDisabledRm,
		onRemovalObject,
		onHideRectSelection,
	} = useRemovalObject();

	const { width, height } = getScaleIMG(
		imageAttr?.width,
		imageAttr?.height,
		widthWindow - (isFullScreen ? 0 : 340),
		heightWindow - (isFullScreen ? 0 : 340),
	);

	// ============================== ACTION BSPLINE ===============================
	const handleMaskToBsLine = () => {
		const imgs = imagesOrigin[visMod][curFrame - 1];
		code &&
			onMaskToBsPLine(
				{
					code,
					mask: `${getBlobId(imgs?.url, true)}`,
					numberCtPoint: 0,
					objectId: +objectIdx,
				},
				(res) => {
					if (res) {
						setOpenControlPoint(false);
						setControlPoints(res.controlPoint);
						setModeFrame(EModeFrame.BSPLEDIT);
						console.log("Response mask to line", res.controlPoint);
					} else {
						setOpenControlPoint(false);
					}
				},
			);
	};

	const onSaveSpline = () => {
		const imgs = imagesOrigin[visMod][curFrame - 1];
		code &&
			onBsPLineToMask(
				{
					mutibleControlpoints: transformPointsScaleToOrigin(
						controlPoint!,
						width / imageAttr?.width,
						height / imageAttr?.height,
					),
					type: visMod,
					code,
					mask: `${getBlobId(imgs?.url, true)}`,
					objectId: +objectIdx,
				},
				(res) => {
					if (res) {
						setOpenControlPoint(false);
						setVisMode(EMode.DAVIS);
						setModeFrame(EModeFrame.VIEW);
					} else {
						setOpenControlPoint(false);
						warn("Please try again!");
					}
				},
			);
	};

	const onActionBtnBsPLine = () => {
		if (modeFrame !== EModeFrame.BSPLEDIT) {
			// setOpenControlPoint(true);
			handleMaskToBsLine();
		}
	};

	const bsLineRef = useRef<any>();

	const handleUndo = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.onUndo();
		}
	};

	const handleRedo = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.onRedo();
		}
	};

	const onNewCurve = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.onNewCurve();
		}
	};

	const onDeleteCurve = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.onDeleteCurve();
		}
	};

	const onRemovePoint = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.onRemovePoint();
		}
	};

	const onAddPoint = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.onAddPoint?.();
		}
	};

	const handleExitBSpline = () => {
		if (bsLineRef.current) {
			bsLineRef.current?.handleExitBSpline();
			setControlPoints([]);
			setModeFrame(EModeFrame.VIEW);
		}
	};

	// ============================== END ACTION BSPLINE ===============================

	useEffect(() => {
		const checkUserEX = async () => {
			const isUserEx = await localStorage.getItem(USER_EX);
			setIsTour(!isUserEx);
			isUserEx && setIsOpen(false);
		};
		checkUserEX();
	}, []);

	useEffect(() => {
		defaultFn();
		code && onImportKeys({ code });
	}, [code]);

	const updateFrameByMode = (listFrame, f = false) => {
		const rs = { listFrame };
		listFrame?.[0]?.url &&
			loadImage(listFrame?.[0]?.url).then(({ width, height }: any) => {
				setImageAttr({ width, height });
			});
		setImagesOrigin(Object.assign(imagesOrigin, { [visMod]: rs?.listFrame }));
		f && setStatus("IDLE");
	};

	useEffect(() => {
		if (!imagesOrigin?.[visMod] && imagesOrigin?.[visMod]?.length > 10) return;
		if (code && visMod)
			onGetDetail(
				{ code, type: visMod },
				(rs) => {
					setIsShowBtn(true);
					videoRef.duration = Math.round((Number(rs?.duration) * 1000) / 10);
					if (rs?.listFrame) {
						rs.listFrame?.[0]?.url &&
							loadImage(rs.listFrame?.[0]?.url).then(
								({ width, height }: any) => {
									setImageAttr({ width, height });
								},
							);
						setImagesOrigin(
							Object.assign(imagesOrigin, { [visMod]: rs?.listFrame }),
						);
						setStatus("IDLE");
						// rs.listFrame.length < curFrame &&
						// 	setCurFrame(rs.listFrame.length - 1);
						onUpdateParams({
							nFrame: { cur: curFrame, total: rs.listFrame.length },
						});
					}
				},
				(listIms) => {
					setImagesOrigin(Object.assign(imagesOrigin, { [visMod]: listIms }));
					onUpdateParams({ nFrame: { cur: curFrame, total: listIms.length } });
				},
				totalFrames,
			);
	}, [code, visMod, totalFrames]);

	useEffect(() => {
		setVertices({});
	}, [curFrame]);

	// =============== RUN ROTO CUSTOM ==================
	const [openCustomRoto, setOpenCustomRoto] = useState(false);
	const handleStopSequence = useCallback(() => {
		if (socket) socket.disconnect();
		if (code && currentBuffer) {
			onSequence(
				{
					code,
					mask: currentBuffer?.name,
					type: visMod,
					isEmit: true,
					startFrame: "",
					endFrame: currentBuffer?.name,
					paused: true,
				},
				(rs) => {
					setLoadingSequence(false);
					onUpdateParams({ startSequence: false });
				},
			);

			onGetDetail(
				{ code, type: visMod },
				(rs) => {
					videoRef.duration = Math.round((Number(rs?.duration) * 1000) / 10);
					if (rs?.listFrame) {
						rs.listFrame?.[0]?.url &&
							loadImage(rs.listFrame?.[0]?.url).then(
								({ width, height }: any) => {
									setImageAttr({ width, height });
								},
							);
						setImagesOrigin(
							Object.assign(imagesOrigin, { [visMod]: rs?.listFrame }),
						);
						setStatus("IDLE");
						onUpdateParams({
							nFrame: { cur: curFrame, total: rs.listFrame.length },
						});
					}
				},
				(listIms) => {
					setImagesOrigin(Object.assign(imagesOrigin, { [visMod]: listIms }));
					onUpdateParams({ nFrame: { cur: curFrame, total: listIms.length } });
				},
				imagesOrigin?.["images"]?.length,
			);
		}
	}, [code, currentBuffer, socket]);

	const onRunRoto = (data) => {
		setLoadingSequence(true);
		setCurFrame(+data.startFrame);
		onUpdateParams({ startSequence: true });
		setOpenCustomRoto(false);
		socket?.connect();
		setIsChanged(null);
		code &&
			onSequence(
				{
					code,
					mask: imagesOrigin[visMod][+data.startFrame - 1]?.url,
					type: visMod,
					isEmit: true,
					startFrame: imagesOrigin[visMod][+data.startFrame - 1]?.url,
					endFrame: imagesOrigin?.["images"]?.[+data.endFrame - 1]?.url,
					paused: false,
				},
				(rs) => {
					if (rs) {
						console.log("rs on sequence", rs);
					}
				},
			);
	};

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (socket) socket.disconnect();
			handleStopSequence();
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [socket]);

	// =============== END RUN ROTO CUSTOM ==================

	const removeInterval = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
			autoPlay && setAutoPlay(false);
			return;
		}
	};

	const callInterval = () => {
		if (!autoPlay) {
			setAutoPlay(true);
			if (imagesOrigin?.[visMod]?.length === 1) return;
			if (intervalRef.current) clearInterval(intervalRef.current);
			intervalRef.current = setInterval(() => {
				setCurFrame((s: number) => {
					if (s >= imagesOrigin?.[visMod]?.length - 1) {
						setAutoPlay(false);
						clearInterval(intervalRef.current);
					}
					const nFrame = s >= imagesOrigin?.[visMod]?.length ? 1 : s + 1;
					return nFrame;
				});
			}, 450);
		} else {
			setAutoPlay(false);
			removeInterval();
		}
	};

	const handleControl = (k: EControl) => {
		if (loadingSequence) return;
		setVertices({});
		setObjectIdx("1");
		switch (k) {
			case EControl.NEXT:
				removeInterval();
				setCurFrame((s: number) => {
					return s >= imagesOrigin?.[visMod]?.length ? 1 : s + 1
				}
					
				);
				break;
			case EControl.PREV:
				removeInterval();
				setCurFrame((s: number) => (s <= 1 ? 1 : s - 1));
				break;
			case EControl.PLAY:
				setIsChanged(null);
				callInterval();
				break;
			default:
				removeInterval();
				break;
		}
	};

	const defaultFn = () => {
		setImagesOrigin({
			images: [],
			mask: [],
			transparent: [],
			davis: [],
			binary_masks: [],
		});
		setImagesMask({});
		setObjectIdx("1");
		removeInterval();
		setImageAttr({ width: 0, height: 0 });
	};

	const onInput = async (event: any) => {
		try {
			defaultFn();
			setIsProgressUpload(true);
			const [file]: [File] = event.target.files;
			code &&
				onSplitFrame(file, code, (rsUpload) => {
					if (rsUpload) {
						onGetDetail(
							{ code, type: visMod },
							(rs) => {
								videoRef.duration = Math.round(
									(Number(rs?.duration) * 1000) / 10,
								);
								if (rs?.listFrame) {
									setProgress(totalFrame);
									setIsProgressUpload(false);
									updateFrameByMode(rs?.listFrame, true);
									onUpdateParams({
										nFrame: { cur: curFrame, total: rs.listFrame.length },
									});
								}
							},
							(listIms) => {
								if (!listIms.length) return;
								setImagesOrigin(
									Object.assign(imagesOrigin, { [visMod]: listIms }),
								);
								onUpdateParams({
									nFrame: { cur: curFrame, total: listIms.length },
								});
							},
							rsUpload?.totalFrames,
						);
					} else {
						setIsProgressUpload(false);
					}
				});
		} catch (error) {
			setStatus("IDLE");
		}
	};

	const handleAddVertex = (event: any, mode: EMode) => {
		event.preventDefault();
		if (autoPlay || !imageAttr?.width) {
			setAutoPlay(false);
			removeInterval();
			return;
		}

		console.log("🚀 ~ handleAddVertex ~ event:", event.button, mode);
		const isRightClick = event.button === 2;
		const image = event.target;
		const imgs = imagesOrigin["images"][curFrame - 1];

		const scaleX = imageAttr?.width / image?.width;

		const scaleY = imageAttr.height / image.height;

		const rect = image.getBoundingClientRect();
		const x = (event.clientX - rect.left) * scaleX;
		const y = (event.clientY - rect.top) * scaleY;

		if (modeFrame.includes(EModeFrame.EDIT)) return;

		const vert = isRightClick
			? {
					[`${x}${y}[${event.button}]`]: [x, y],
			  }
			: Object.assign(vertices, {
					[`${x}${y}[${event.button}]`]: [x, y],
			  });

		setStatus("");

		imgs?.url &&
			code &&
			onSamPredict(
				{
					is_new_image: curFrame,
					vis_mode: mode,
					points: JSON.stringify(Object.values(vert)),
					labels: JSON.stringify(
						Object.keys(vert).map((e) => (e.includes("[2]") ? 0 : 1)),
					),
					object_idx: `${event.button === 2 ? 0 : objectIdx}`,
					segment_type: segmentType,
					code,
				},
				imgs?.url,
				(rs) => {
					console.log("Response action SAM", rs, vertices);
					setStatus("IDLE");
					if (!rs) {
						return false;
					}
					setVertices(vert);
					setCurrentBuffer({
						url: rs?.mask_blob_id,
						name: `${getBlobId(rs?.mask_blob_id)}.png`,
					});
					updateFrame({
						...rs,
						index: `${getBlobId(imgs?.url)}_${visMod}`,
					});
				},
			);
	};

	useEffect(() => {
		if (isChanged) {
			setCurrentBuffer({
				name: getBlobId(imagesOrigin[visMod][curFrame - 1]?.url, true),
				url: imagesOrigin[visMod][curFrame - 1]?.url,
			});
		}
	}, [isChanged]);

	const updateFrame = (rs: any, upd = true) => {
		const { mask_blob_id, name, index } = rs;
		const imgMask = Object.assign(imagesMask, {
			[`${index}`]: {
				url: mask_blob_id,
				name,
			},
		});
		upd && setImagesMask(imgMask);
		setStatus("IDLE");
	};

	// ================== REMOVAL OBJECTS =================
	const handelRemoveObject = (params) => {
		setCurFrame(+params.startFrame);
		socket?.connect();
		onUpdateLoadingRm(true);
		onUpdateDisabledRm(true);
		setIsChanged(null);
		code &&
			onRemovalObject(
				{
					code,
					objectId: +objectIdx,
					startFrame: imagesOrigin[visMod][Number(params.startFrame) - 1]?.url,
					endFrame:
						imagesOrigin?.["images"]?.[Number(params.endFrame) - 1]?.url,
					xStart: params.xStart,
					xEnd: params.xEnd,
					yStart: params.yStart,
					yEnd: params.yEnd,
				},
				(rs) => {
					if (rs) {
						console.log("rs on onRemovalObject", rs);
					}
				},
			);
	};
	// ================== END REMOVAL OBJECT =================

	const renderThumb = useCallback(() => {
		const { width, height } = getScaleIMG(
			imageAttr?.width,
			imageAttr?.height,
			(widthWindow - (isFullScreen ? 0 : 340)) *
				(!isFullScreen ? convertPercentageToDecimal("100%") : 1),
			heightWindow -
				(isFullScreen ? 0 : 340) *
					(!isFullScreen ? convertPercentageToDecimal("100%") : 1),
		);
		if (!imageAttr?.width || !width || !height) return;
		const imgs =
			imagesOrigin?.[visMod]?.[curFrame - 1] ??
			imagesOrigin["images"]?.[curFrame - 1];

		return (
			<div
				className="relative"
				style={{
					width,
					height,
				}}>
				{[EModeFrame.EDIT, EModeFrame.SAVE].includes(modeFrame) && (
					<BezierCurve
						modeFrame={modeFrame}
						width={width}
						height={height}
						keyMode={`${getBlobId(imgs?.url)}_${visMod}`}
						blobId={`${getBlobId(imgs?.url, true)}`}
						objectIdx={objectIdx}
						image={imagesOrigin["mask"]?.[curFrame - 1]}
						onCancel={() => {
							setModeFrame(EModeFrame.VIEW);
						}}
						onUpdateBCurve={(rs) => {
							setStatus("LOADING");
							onSaveBezierCurve(rs);
							// prevRef.controlPoints = rs;
							// prevRef.wh = { width, height };
						}}
					/>
				)}
				{[EModeFrame.BSPLEDIT].includes(modeFrame) && (
					<BSplineCurveCus
						resPoints={controlPoints}
						imageUrl={imagesOrigin["images"]?.[curFrame - 1]?.url}
						width={width}
						height={height}
						setControlPoint={setControlPoint}
						rateScaleWidth={width / imageAttr?.width}
						rateScaleHeight={height / imageAttr?.height}
						widthOrigin={imageAttr?.width}
						heightOrigin={imageAttr?.height}
						ref={bsLineRef}
						setUndoHistory={setUndoHistory}
						stateFit={stateFit}
						setIsNewCurve={setIsNewCurve}
						setIsDeleteCurve={setIsDeleteCurve}
						setIsAddPoint={setIsAddPoint}
						setIsRemovePoint={setIsRemovePoint}
						isNewCurve={isNewCurve}
						isDeleteCurve={isDeleteCurve}
						isAddPoint={isAddPoint}
						isRemovePoint={isRemovePoint}
						setSelectedSpline={setSelectedSpline}
						selectedSpline={selectedSpline}
					/>
				)}
				{[EModeFrame.REMOVAL].includes(modeFrame) && (
					<SelectionObject
						imageUrl={imgs?.url}
						width={width}
						height={height}
						rateScaleWidth={width / imageAttr?.width}
						rateScaleHeight={height / imageAttr?.height}
					/>
				)}
				{[EModeFrame.BEDIT].includes(modeFrame) && (
					<BezierCurveCus imageUrl={imgs?.url} width={width} height={height} />
				)}
				{/* {modeFrame === EModeFrame.EDIT && <BezierCurveFromMask
					imagesMask={imagesMask}
					width={width}
					height={height}
					keyMode={`${getBlobId(imgs?.url)}_${visMod}`}
					blobId={`${getBlobId(imgs?.url, true)}`}
					objectIdx={objectIdx}
					onUpdateBCurve={(rs) => {
						prevRef.controlPoints = rs;
						prevRef.wh = { width, height };
         		 }}
        		/>} */}
				{!!brushMode && imagesOrigin["davis"]?.[curFrame - 1]?.url && (
					<InPainting
						width={width}
						height={height}
						objectIdx={objectIdx}
						image={imagesOrigin["davis"]?.[curFrame - 1]}
					/>
				)}
				{![EModeFrame.BEDIT, EModeFrame.BSPLEDIT, EModeFrame.REMOVAL].includes(
					modeFrame,
				) && (
					<img
						key={imgs}
						src={imgs?.url}
						alt=""
						width={width}
						height={height}
						onContextMenu={(e: any) =>
							segmentType === ESegmentType.CLICK && handleAddVertex(e, visMod)
						}
						onClick={(e: any) =>
							segmentType === ESegmentType.CLICK && handleAddVertex(e, visMod)
						}
					/>
				)}
				{imagesMask?.[`${getBlobId(imgs?.url)}_${visMod}`]?.url &&
					modeFrame !== EModeFrame.BSPLEDIT && (
						<div
							style={{
								backgroundColor: "white",
								width,
								height,
								position: "absolute",
								zIndex: 50,
								top: 0,
								left: 0,
							}}>
							<img
								key={imagesMask[`${getBlobId(imgs?.url)}_${visMod}`]?.url}
								src={imagesMask[`${getBlobId(imgs?.url)}_${visMod}`]?.url}
								alt=""
								width={width}
								height={height}
								onContextMenu={(e: any) =>
									ESegmentType.CLICK && handleAddVertex(e, visMod)
								}
								onClick={(e: any) =>
									ESegmentType.CLICK && handleAddVertex(e, visMod)
								}
							/>
						</div>
					)}
			</div>
		);
	}, [
		imageAttr,
		curFrame,
		modeFrame,
		JSON.stringify(imagesOrigin),
		visMod,
		vertices,
		objectIdx,
		segmentType,
		JSON.stringify(imagesMask),
		widthWindow,
		heightWindow,
		status,
		brushMode,
		isFullScreen,
		stateFit,
		JSON.stringify(controlPoints),
		handleMaskToBsLine,
		JSON.stringify(controlPoint),
	]);

	const onChangeVisMode = async (e: any) => {
		if (e === visMod) return;
		setVisMode(e);
		setModeFrame(EModeFrame.VIEW);
		const index = `${getBlobId(
			imagesOrigin?.["images"]?.[curFrame - 1]?.url,
		)}_${e}`;
		if (imagesMask[`${index}`]) {
			return;
		}
	};

	const onChangeReset = (r) => {
		console.log("🚀 ~ onChangeReset ~ r:", autoPlay, !r);
		if (autoPlay || !r) return;
		const imgs = imagesOrigin[visMod][curFrame - 1];
		setVertices({});
		console.log("🚀 ~ onChangeReset ~ r:", code && imgs?.url);

		code &&
			imgs?.url &&
			onReset(
				{
					code,
					blob_id: `${getBlobId(imgs?.url, true)}`,
					object_idx: objectIdx,
					vis_mode: visMod,
					type: r,
				},
				(rs) => {
					if (!rs || r === ResetType.ALL_FRAME) {
						setImagesOrigin({
							images: imagesOrigin?.["images"],
							mask: [],
							transparent: [],
							davis: [],
							binary_masks: [],
						});
						console.log("🚀 ~ onChangeReset ~ imagesOrigin:", {
							images: imagesOrigin?.["images"],
							mask: [],
							transparent: [],
							davis: [],
						});
						return false;
					}
					updateFrame({
						...rs,
						index: `${getBlobId(imgs?.url)}_${visMod}`,
					});
				},
			);
	};

	const onObjectIdx = (e: any) => {
		setVertices({});
		setModeFrame(EModeFrame.VIEW);
		setObjectIdx(e.target.value);
	};

	// ======================= HANDLE EXPORT PROJECT ===================
	const [statusSuccessExport, setStatusSuccessExport] = useState("NORMAL");
	const [isModalStatus, setIsModalStatus] = useState(false);
	const { progressExport, totalProcess, setProgressExport } = useProgressExport(
		code!,
	);

	const onExport = (mode) => {
		setStatusSuccessExport("NORMAL");
		if (autoPlay) return;
		if (mode === TypeExport.SHAPE) {
			return setIsModalExportSharp(true);
		} else {
			setIsModalStatus(true);
		}
		setVertices({});
		const imgs = imagesOrigin[visMod][curFrame - 1];
		code &&
			onExportRoto(
				{
					typeExport: mode,
					code,
					type: visMod,
					mask: `${getBlobId(imgs?.url, true)}`,
				},
				(rs) => {
					setProgressExport(0);
					if (rs) {
						return setStatusSuccessExport("SUCCESS");
					}
					return setStatusSuccessExport("FAIL");
				},
			);
	};

	const onExportShape = useCallback(
		(infoExport) => {
			setIsModalExportSharp(false);
			setIsModalStatus(true);
			const imgs = imagesOrigin[visMod][curFrame - 1];
			loadImage(imgs.url).then(({ width, height }: any) => {
				code &&
					onExportRoto(
						{
							typeExport: TypeExport.SHAPE,
							code,
							type: visMod,
							mask: `${getBlobId(imgs?.url, true)}`,
							frameRate: +infoExport.frameRate,
							numberObjects: +infoExport.numberObjects,
							scalePoint: +infoExport.scalePoint,
							choosingFrame: +infoExport.choosingFrame,
							startKeyframe: +infoExport.startKeyframe,
							startFrame: +infoExport.startFrame,
							endFrame: +infoExport.endFrame,
							width,
							height,
						},
						(rs) => {
							setProgressExport(0);
							if (rs) {
								return setStatusSuccessExport("SUCCESS");
							}
							return setStatusSuccessExport("FAIL");
						},
					);
			});
		},
		[code, visMod, imageAttr],
	);

	// ======================= END HANDLE EXPORT PROJECT ===================

	// onBsplineCurve
	const onBsplineCurve = () => {
		if (autoPlay) return;
		setVertices({});
		setModeFrame(
			EModeFrame.BEDIT === modeFrame ? EModeFrame.VIEW : EModeFrame.BEDIT,
		);
	};

	const onDone = () => {
		// const imgs = imagesOrigin[visMod][curFrame];
		// prevRef.controlPoints &&
		//   code && imgs?.url &&
		setModeFrame(EModeFrame.SAVE);
	};

	const onSaveBezierCurve = (file: File) => {
		const imgs = imagesOrigin["images"][curFrame - 1];
		file &&
			code &&
			onUpdateBezierCurve(
				{
					mask: `${getBlobId(imgs?.url, true)}`,
					code,
					type: visMod,
					file,
					...imageAttr,
				},
				(rs) => {
					setModeFrame(EModeFrame.VIEW);
					rs &&
						updateFrame({
							...rs,
							index: `${getBlobId(imgs?.url)}_${visMod}`,
						});
				},
			);
	};

	// ============================== RENDER THUMB USE SOCKET ==================================
	useEffect(() => {
		if (
			countThumbSequence === imagesOrigin["images"].length &&
			imagesOrigin["images"].length > 0
		) {
			setIsProcessingSequence(false);
			setCurrentBuffer(null);
			setCountThumbSequence(0);
		}
	}, [countThumbSequence]);

	useEffect(() => {
		if (socket) {
			socket.connect();
		}
		return () => {
			if (socket) {
				socket.disconnect();
			}
		};
	}, [socket]);

	useEffect(() => {
		const handleSocketEvent = (dataSocket: any) => {
			console.log("On sequence socket =========", dataSocket);
			setCurrentBuffer(dataSocket);
			setCurFrame(dataSocket.index);
			if (dataSocket.isFinish) {
				setLoadingSequence(false);
				onUpdateParams({ startSequence: false });
				setIsChanged(null);
				code &&
					onGetDetail(
						{ code, type: visMod },
						(rs) => {
							videoRef.duration = Math.round(
								(Number(rs?.duration) * 1000) / 10,
							);
							rs?.listFrame && updateFrameByMode(rs?.listFrame);
						},
						(listIms) => {
							setImagesOrigin(
								Object.assign(imagesOrigin, { [visMod]: listIms }),
							);
						},
						imagesOrigin?.["images"]?.length,
					);
			}
		};
		if (socket && profile?.id) {
			socket.on(`eventSequence-${profile?.id}`, handleSocketEvent);
		}
		return () => {
			if (socket) {
				socket.off(`eventSequence-${profile?.id}`, handleSocketEvent);
			}
		};
	}, [socket, profile?.id, visMod, code]);
	// ============================== END RENDER THUMB USE SOCKET ==================================

	console.log("controlPoints==============", controlPoints, controlPoint);

	useEffect(() => {
		const handleSocketEvent = (dataSocket: any) => {
			setCurrentBuffer(dataSocket);
			setCurFrame(dataSocket.index);
			if (dataSocket.isFinish) {
				onUpdateLoadingRm(false);
				onUpdateDisabledRm(false);
				onHideRectSelection(false);
				setIsChanged(null);
				setModeFrame(EModeFrame.VIEW);
				code &&
					onGetDetail(
						{ code, type: visMod },
						(rs) => {
							videoRef.duration = Math.round(
								(Number(rs?.duration) * 1000) / 10,
							);
							rs?.listFrame && updateFrameByMode(rs?.listFrame);
						},
						(listIms) => {
							setImagesOrigin(
								Object.assign(imagesOrigin, { [visMod]: listIms }),
							);
						},
						imagesOrigin?.["images"]?.length,
					);
			}
		};
		if (socket && profile?.id) {
			socket.on(`eventRemoveObject-${profile?.id}`, handleSocketEvent);
		}
		return () => {
			if (socket) {
				socket.off(`eventRemoveObject-${profile?.id}`, handleSocketEvent);
			}
		};
	}, [socket, profile?.id, visMod, code]);
	// ============================== END RENDER THUMB USE SOCKET ==================================

	console.log("controlPoints==============", controlPoints, controlPoint);

	return (
		<>
			<DetailLayout
				isProgressUpload={isProgressUpload}
				progress={progress}
				totalFrame={totalFrame}
				isDisable={loadingSequence}
				sideLeftBar={() => (
					<ReactProSideBar
						visMod={visMod}
						images={imagesOrigin["images"]}
						autoPlay={autoPlay}
						onChangeVisMode={onChangeVisMode}
						onChange={onInput}
						objectIdx={objectIdx}
						onObjectIdx={onObjectIdx}
						isTour={isTour}
						isDisable={loadingSequence || loading}
					/>
				)}
				rightSidebar={() =>
					imagesOrigin["images"]?.length || isTour ? (
						<>
							{modeFrame !== EModeFrame.BSPLEDIT ? (
								<RightSidebar
									onChangeReset={(e) => onChangeReset(e)}
									onSaveAll={(p) => {
										if (autoPlay) return;
										const imgs = imagesOrigin["images"][curFrame - 1];
										code &&
											onDetectEditMask(
												{
													...p,
													code,
													type: visMod,
													mask: `${getBlobId(imgs?.url, true)}`,
												},
												(rs) => {
													rs &&
														updateFrame({
															...rs,
															index: `${getBlobId(imgs?.url)}_${visMod}`,
														});
												},
											);
									}}
									autoPlay={autoPlay}
									loadingSequence={loadingSequence}
									handleStopSequence={handleStopSequence}
									isProcessingSequence={isProcessingSequence}
									setIsProcessingSequence={setIsProcessingSequence}
									isDisable={loadingSequence}
									setIsChanged={setIsChanged}
									curFrame={curFrame}
									setCurFrame={setCurFrame}
									setOpenCustomRoto={setOpenCustomRoto}
									isDisableSam={loading}
									maxLength={imagesOrigin["images"]?.length}
									totalFrame={imagesOrigin["images"]?.length}
									setModeFrame={(mode) => setModeFrame(mode)}
									setVisModeRemove={() => setVisMode(EMode.IMAGE)}
									onRemoveObject={handelRemoveObject}
								/>
							) : (
								<RightSideBSPline
									lines={[]}
									selectedLine={{}}
									handleSelected={(slLine) => {
										console.log(slLine);
									}}
									handleExitBSpline={handleExitBSpline}
									onSaveSpline={onSaveSpline}
									isShowBtnSaveBsLine={
										controlPoint?.length! > 0 &&
										modeFrame === EModeFrame.BSPLEDIT
									}
									controlPoint={controlPoint || []}
									isNewCurve={isNewCurve}
									isDeleteCurve={isDeleteCurve}
									isAddPoint={isAddPoint}
									isRemovePoint={isRemovePoint}
									setIsAddPoint={setIsAddPoint}
									setIsDeleteCurve={setIsDeleteCurve}
									setIsRemovePoint={setIsRemovePoint}
									setIsNewCurve={setIsNewCurve}
									selectedSpline={selectedSpline}
									setSelectedSpline={setSelectedSpline}
									onNewCurve={onNewCurve}
									onDeleteCurve={onDeleteCurve}
									onAddPoint={onAddPoint}
								/>
							)}
						</>
					) : (
						<></>
					)
				}
				bottomBar={
					imagesOrigin["images"]?.length > 0 || isTour ? (
						<Timeline
							curFrame={curFrame}
							totalFrame={imagesOrigin["images"]?.length}
							setCurFrame={setCurFrame}
							autoPlay={autoPlay}
							images={imagesOrigin["images"]}
							stopAutoPlay={() => handleControl(EControl.STOP)}
							isTour={isTour}
							duration={videoRef.duration}
							isDisable={loadingSequence || loading}
							setIsChanged={setIsChanged}
							isChanged={isChanged}
						/>
					) : null
				}>
				<TopContentBar
					images={imagesOrigin["images"]}
					modeFrame={modeFrame}
					imagesMask={Object.keys(imagesMask)}
					onModeFrame={onDone}
					onExport={onExport}
					onBsplineCurve={onActionBtnBsPLine}
					onChangeModeFrame={() => setModeFrame(EModeFrame.BEDIT)}
					projectName={state?.name}
					isDisable={loadingSequence || loading}
					handleUndo={handleUndo}
					handleRedo={handleRedo}
					isUndo={controlPoint?.length! > 0}
					isRedo={undoHistory?.length > 0}
					onNewBsplineCurve={() => setModeFrame(EModeFrame.BSPLEDIT)}
					visMod={visMod}
					showBtn={isShowBtn}
				/>

				<div className="flex justify-between items-center flex-col flex-1">
					{(loading || loadingSequence || loadingPsLine || loadingRm) && (
						<Loader bg={""} type="await" />
					)}
					{!!imagesOrigin["images"]?.length && (
						<div
							className="justify-center items-center flex-1 flex w-full h-full overflow-hidden bg-[#191A1E] border-[1px] border-solid border-[#3C3C3C] rounded-md"
							ref={elementRef}>
							{currentBuffer && loadingSequence ? (
								<div className="relative">
									<img
										width={width}
										height={height}
										src={currentBuffer?.url}
										alt="imgSequence"
									/>
								</div>
							) : (
								renderThumb()
							)}
							{/* ================== Footer Fullscreen ================== */}
							{isFullScreen && (
								<FooterFullscreen
									autoPlay={autoPlay}
									handleControl={handleControl}
									toggleFullScreen={toggleFullScreen}
								/>
							)}
						</div>
					)}
				</div>

				{!!imagesOrigin["images"]?.length || isTour ? (
					<BottomContentBar
						prev={() => handleControl(EControl.PREV)}
						play={() => handleControl(EControl.PLAY)}
						next={() => handleControl(EControl.NEXT)}
						curFrame={curFrame}
						totalFrame={imagesOrigin["images"]?.length}
						onChange={(v: number) => {
							if (v) {
								setCurFrame(Number(v));
								setCurrentBuffer({
									name: getBlobId(
										imagesOrigin[visMod][Number(v) - 1]?.url,
										true,
									),
									url: imagesOrigin[visMod][Number(v) - 1]?.url,
								});
							}
						}}
						autoPlay={loadingSequence ? loadingSequence : autoPlay}
						stateFit={stateFit}
						setStateFit={setStateFit}
						toggleShowFullScreen={toggleFullScreen}
						isDisable={loadingSequence || loading}
						modeFrame={modeFrame}
					/>
				) : (
					<div className="grid grid-cols-5"></div>
				)}
			</DetailLayout>
			{/* ========== Modal Custom Roto ============*/}
			<ModalCustomRoto
				open={openCustomRoto}
				setOpenCustomRoto={setOpenCustomRoto}
				onSubmit={onRunRoto}
				maxLength={imagesOrigin["images"]?.length}
				curFrame={curFrame}
			/>
			{/* ========== Modal Export ============*/}
			<ModalExport
				open={isModalExportSharp}
				toggleModalExport={() => setIsModalExportSharp((pre) => !pre)}
				onExportShape={onExportShape}
			/>
			{/* ========= Modal status Export ============ */}
			<ModalStatusExport
				isOpen={isModalStatus}
				progressExport={progressExport}
				totalProcess={totalProcess}
				statusExport={statusSuccessExport}
				setIsModalStatus={setIsModalStatus}
				setStatusSuccessExport={setStatusSuccessExport}
			/>
			{/* ========== Modal Mask To BPsLine ========== */}
			{/* <ModalControlPoint
				open={openControlPoint}
				setOpenControlPoint={setOpenControlPoint}
				onSubmit={handleMaskToBsLine}
			/> */}
		</>
	);
}
