import { Typography } from "@mui/material";
import { GradientBorderButton } from "components/Buttons";
import { ModalManagePayment, ModalPayment } from "components/modals";
import { PayPal } from "components";
import { TStatePayment } from "types/project";
import { PaymentState } from "utils/constants";
import React, { useCallback, useMemo, useState } from "react";
import Enable from "assets/images/icon/switch-enabled.png";
import Disabled from "assets/images/icon/switch-disabled.png";
import {
	EPricingPlan,
	EnumPlanType,
	PackagePlan,
	QUANTITY,
} from "types/payment";
import { Monthly } from "../components/Monthly";
import { TModalType } from "types/payment";

import { useNavigate } from "react-router-dom";
import SvgIcon from "assets/SvgIcon";

const PricingPlan = () => {
	const navigate = useNavigate();

	const [typePlan, setTypePlan] = useState(EPricingPlan.ANNUAL);
	const [open, setOpen] = useState<TStatePayment>(PaymentState);
	const [isNuke, setIsNuke] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState<PackagePlan>();

	const toggleNuke = () => setIsNuke(!isNuke);

	const handelChoosePlan = useCallback((plan: PackagePlan) => {
		if (plan?.packageName === EnumPlanType.FREE) {
			navigate("/admin/project");
			return;
		} else {
			setSelectedPlan(plan);
			activeModal("manage");
		}
	}, []);

	const renderPricingPlan = useMemo(() => {
		return (
			<Monthly
				type={typePlan}
				isNuke={isNuke}
				//@ts-ignore
				onClick={(pl) => handelChoosePlan(pl)}
			/>
		);
	}, [typePlan, isNuke, setIsNuke, toggleNuke]);

	const [alertData, setAlertData] = useState<any>();
	const [alertType, setAlertType] = useState<TModalType>("success");

	const activeModal = useCallback((a, f = true) => {
		console.log("🚀 ~ activeModal ~ a, f:", a, f);
		setOpen({ ...PaymentState, [`${a}`]: f });
	}, []);

	const cbPayment = (rs) => {
		setAlertData(rs);
		setAlertType(rs ? "success" : "warn");
		activeModal("paypalAlert");
	};

	const handleChoosePayment = (ind) => {
		switch (ind) {
			case 0:
				activeModal("paypal");
				break;
			default:
				activeModal("manage");
				break;
		}
	};
	console.log("open", open["paypal"]);
	return (
		<>
			<div
				className="py-10 px-10 w-full h-full bg-[#1F1F1F] flex flex-col items-center gap-4 relative"
				onClick={(event: React.MouseEvent) => {
					event.stopPropagation();
				}}>
				<button
					onClick={() => navigate("/admin/project")}
					className="flex flex-row justify-start content-center items-center gap-2 font-semibold text-[10px] text-[#B3B6B6] absolute top-20 left-20">
					{SvgIcon.BackIcon()}
				</button>

				<Typography fontSize={"26px"} fontWeight={"700"} color={"#FFFFFF"}>
					Choose a Plan
				</Typography>

				{/* ========== Button ========== */}
				<div
					className="w-[185px] h-[36px] bg-[#191A1E] relative
             rounded-md flex items-center cursor-pointer">
					<div
						className="flex items-center justify-center w-[50%]"
						onClick={() => setTypePlan(EPricingPlan.ANNUAL)}>
						<Typography fontSize={"18px"} fontWeight={"600"} color={"#FFFFFF"}>
							Annual
						</Typography>
					</div>
					<div
						className="flex items-center justify-center w-[50%]"
						onClick={() => setTypePlan(EPricingPlan.MONTHLY)}>
						<Typography fontSize={"18px"} fontWeight={"600"} color={"#FFFFFF"}>
							Monthly
						</Typography>
					</div>
					<div
						className={`bg-[#3C3C3C] w-[88px] h-[32px] flex items-center justify-center absolute top-[50%] transition-all translate-y-[-50%] rounded-md ${
							typePlan === "Annual" ? "left-0" : "right-0"
						}`}>
						<Typography fontSize={"18px"} fontWeight={"600"} color={"#FFFFFF"}>
							{typePlan}
						</Typography>
					</div>
				</div>

				<div className="flex justify-center items-center">
					<button
						onClick={toggleNuke}
						className="flex justify-center items-center">
						{isNuke ? <img src={Enable} /> : <img src={Disabled} />}
						<Typography
							fontSize={"16px"}
							fontWeight={"700"}
							color={"#FFFFFF"}
							sx={{ marginLeft: 2 }}>
							With Nuke Shape
						</Typography>
					</button>
				</div>
				{/* ========== Pricing plan ==========*/}
				<div className="">{renderPricingPlan}</div>
				{/* ========== Button Toggle ========== */}
				{/* <div className="w-100 flex flex-col items-center content-center gap-3">
					<GradientBorderButton
						onClick={() => activeModal("manage")}
						text="Payment method"
					/>
				</div> */}
			</div>

			<ModalManagePayment
				open={open["manage"]}
				handleClose={() => activeModal("manage", false)}
				payNow={(ind) => handleChoosePayment(ind)}
				plan={selectedPlan}
				period={typePlan}
			/>
			<PayPal
				open={open["paypal"]}
				handleClose={() => handleChoosePayment(null)}
				qty={QUANTITY[typePlan]}
				id={selectedPlan?.id}
				backMethod={(r) => cbPayment(r)}
			/>
			<ModalPayment
				open={open["paypalAlert"]}
				data={alertData}
				handleClose={() => {activeModal("paypalAlert", false); navigate(-1)}}
				type={alertType}
			/>
		</>
	);
};

export default PricingPlan;
