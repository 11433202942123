export enum EControl {
  NEXT = "next",
  PREV = "prev",
  PLAY = "play",
  STOP = "stop",
}

export interface IControlProps {
  handleControl?: (k: EControl) => void;
  images: [];
  autoPlay: boolean;
}

export enum TypeExport {
  VIDEO = "VIDEO",
  IMAGES = "IMAGES",
  PROJECT = "PROJECT",
  SHAPE = "SHAPE",
}

