import { ROUTES_API } from "utils/constants";
import { useAlert } from "./useAlert";
import { useState } from "react";
import axiosClient from "utils/axios";
import { TApprovePayPal, TOrderPayPal } from "types/payment";

export default function usePayments() {

    const { error } = useAlert();
	const [loading, setLoading] = useState(false);
    
    const onCreateOrder = async (input: TOrderPayPal) => {
		try {
			// const isValid = await getVisModeSchema.validate(input);
			// if (!isValid) {
			// 	error("Field is required");
			// 	return;
			// }
			setLoading(true);
			const rs: any = await axiosClient
				.post(ROUTES_API.PAYMENT.PAYPAL.ORDERS, input)
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onCreateOrder ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				console.log("🚀 ~ onCreateOrder ~ rs:", data);
				return data;
			}
			error(`Err: ${message}`);
			return null;
		} catch (err) {
			console.log("🚀 ~ onCreateOrder ~ err:", err);
			setLoading(false);
			// error(JSON.stringify(error))
			return null;
		}
	};

    const onApprove = async (input: TApprovePayPal, callback?: (a: any) => void,) => {
		try {
			// setLoading(true);
			const rs: any = await axiosClient
				.post(ROUTES_API.PAYMENT.PAYPAL.APPROVE, input)
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onApprove ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				console.log("🚀 ~ onApprove ~ rs:", data);
				return callback?.(data);
			}
			error(`Err: ${message}`);
			return callback?.(null);
		} catch (err) {
			console.log("🚀 ~ onApprove ~ err:", err);
			setLoading(false);
			// error(JSON.stringify(error))
			return callback?.(null);
		}
	};
  return {
    onCreateOrder,
    onApprove
  }
}
