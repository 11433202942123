import {
	TDetectMask,
	TExportBezierCurve,
	TExportInput,
	TGetVisMode,
	TReset,
	TSamPredict,
	TSequence,
	TUpdateBezierCurve,
} from "types/ai";
import { useAlert } from "./useAlert";
import axiosClient from "utils/axios";
import { ROUTES_API } from "utils/constants";
import {
	getExportBezierCurveSchema,
	getUpdateBezierCurveSchema,
	getVisModeSchema,
	onDetectMaskSchema,
	onExportRotoSchema,
	onResetSchema,
	samPredictSchema,
	streamSequenceSchema,
} from "utils/validField";
import { useRef, useState } from "react";
import { getBlobId } from "utils/libs";
import { saveAs } from "file-saver";
import { useCommon } from "./useCommon";

export default function useAITools() {
	const prevRef = useRef({ index: -1 }).current;
	const { error, success, warn } = useAlert();
	const [loading, setLoading] = useState(false);
	const [loadingSequence, setLoadingSequence] = useState(false);
	const { onUpdateParams } = useCommon();

	const onSamPredict = async (
		input: TSamPredict,
		file: string,
		callback?: (a: any) => void,
	) => {
		console.log("🚀 ~ onSamPredict ~ input:", input);
		try {
			const isValid = await samPredictSchema.validate(input);
			console.log("🚀 ~ onSamPredict ~ isValid:", isValid);
			if (!isValid) {
				error("Field is required");
				return;
			}
			const formData: any = new FormData();

			const ftmp = file.split("/");

			formData.append("path_file", ftmp[ftmp.length - 1]);
			formData.append("is_new_image", input.is_new_image !== prevRef.index);
			formData.append("file", file);
			formData.append("points", input.points);
			formData.append("labels", input.labels);
			formData.append("vis_mode", input.vis_mode);
			formData.append("code", input.code);
			formData.append("object_idx", input.object_idx);
			formData.append("segment_type", input.segment_type);
			setLoading(true);
			const { statusCode, data, message }: any = await axiosClient.post(
				ROUTES_API.AI.SAM_PREDICT,
				formData,
				{ headers: { "Content-Type": "multipart/form-data" } },
			);
			console.log(
				"🚀 ~ onSamPredict ~ statusCode, data, message:",
				statusCode,
				data,
				message,
			);
			setLoading(false);

			if (statusCode === 200) {
				console.log("🚀 ~ onSamPredict ~ rs:", data);
				prevRef.index = input.is_new_image;
				return callback?.(data);
			}
			// callback?.(null);
			return error(`Err: ${message.substr(0, 100)}`);
		} catch (err) {
			console.log("🚀 ~ useAITools ~ err:", err);
			setLoading(false);
			// error(JSON.stringify(error))
			callback?.(null);
		}
	};

	const onGetSamPredict = async (input: TGetVisMode) => {
		try {
			const isValid = await getVisModeSchema.validate(input);
			if (!isValid) {
				error("Field is required");
				return;
			}
			setLoading(true);
			const rs: any = await axiosClient
				.get(ROUTES_API.AI.SAM_PREDICT, { params: input })
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onGetSamPredict ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				console.log("🚀 ~ onSamPredict ~ rs:", data);
				// success("Register successfuly")
				return data;
			}
			error(`Err: ${message}`);
			return null;
		} catch (err) {
			console.log("🚀 ~ onGetSamPredict ~ err:", err);
			setLoading(false);
			// error(JSON.stringify(error))
			return null;
		}
	};

	const onExportBezierCurve = async (input: TExportBezierCurve) => {
		console.log("🚀 ~ onExportBezierCurve ~ input:", input);
		try {
			const isValid = await getExportBezierCurveSchema.validate(input);
			if (!isValid) {
				error("Field is required");
				return;
			}
			setLoading(true);
			const rs: any = await axiosClient
				.get(ROUTES_API.AI.EXPORT_BEZIER_CURVE, { params: input })
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onExportBezierCurve ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			console.log("🚀 ~ onExportBezierCurve ~ data:", data);
			if (statusCode === 200) {
				const { control_point, color } = data;

				if (!control_point) {
					error(`Err: Mask is not exists!`);
					return {};
				}
				// success("Register successfuly")
				return {
					color,
					data: control_point
						.map((e: any) => (e.length === 6 ? e : null))
						.filter((e: any) => e),
				};
			}
			error(`Err: ${message}`);
			return {};
		} catch (err) {
			console.log("🚀 ~ onExportBezierCurve ~ err:", err);
			setLoading(false);
			// error(JSON.stringify(error))
			return {};
		}
	};

	const onUpdateBezierCurve = async (
		input: TUpdateBezierCurve,
		callback?: (a: any) => void,
	) => {
		try {
			const isValid = await getUpdateBezierCurveSchema.validate(input);
			if (!isValid) {
				error("Field is required");
				return;
			}
			setLoading(true);

			const formData: any = new FormData();

			formData.append("file", input.file);
			formData.append("code", input.code);
			formData.append("type", input.type);
			formData.append("mask", input.mask);
			formData.append("width", input.width);
			formData.append("height", input.height);

			const rs: any = await axiosClient
				.post(ROUTES_API.AI.UPDATE_CONTROL_POINT, formData, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onUpdateBezierCurve ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			console.log("🚀 ~ onUpdateBezierCurve ~ data:", data);
			if (statusCode === 200) {
				// success("Register successfuly")
				return callback?.(data);
			}
			error(`Err: ${message}`);
			return callback?.(null);
		} catch (err) {
			console.log("🚀 ~ onUpdateBezierCurve ~ err:", err);
			setLoading(false);
			// error(JSON.stringify(error))
			return callback?.(null);
		}
	};

	const onReset = async (input: TReset, callback?: (a: any) => void) => {
		try {
			const isValid = await onResetSchema.validate(input);
			if (!isValid) {
				error("Field is required");
				return;
			}
			setLoading(true);
			const rs: any = await axiosClient
				.post(ROUTES_API.AI.ON_RESET, input)
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onReset ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			console.log("🚀 ~ onReset ~ data:", data);
			if (statusCode === 200) {
				// success("Register successfuly")
				return callback?.(data);
			}
			error(`Err: ${message}`);
			return callback?.(null);
		} catch (err) {
			setLoading(false);
			return callback?.(null);
		}
	};

	const onSequence = async (input: TSequence, callback?: (a: any) => void) => {
		try {
			const isValid = await streamSequenceSchema.validate(input);
			if (!isValid) {
				error("Field is required");
				return;
			}
			setLoadingSequence(true);
			const mask = getBlobId(input.mask, true);
			const startFrame = getBlobId(input.startFrame, true);
			const endFrame = getBlobId(input.endFrame, true);
			const rs: any = await axiosClient.post(ROUTES_API.AI.SEQUENCE, {
				...input,
				mask,
				startFrame,
				endFrame,
			});
			setLoadingSequence(false);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				callback?.(true);
			}
			if (statusCode === 400) {
				console.log("=======onSequence======", message);
				warn(message);
				onUpdateParams({ startSequence: false });
			}
		} catch (err) {
			setLoadingSequence(false);
			onUpdateParams({ startSequence: false });
			callback?.(false);
		}
	};

	const onDetectEditMask = async (
		input: TDetectMask,
		callback?: (a: any) => void,
	) => {
		try {
			const isValid = await onDetectMaskSchema.validate(input);
			if (!isValid) {
				error("Field is required");
				return;
			}
			setLoading(true);
			const rs: any = await axiosClient
				.post(ROUTES_API.AI.DETECT_EDIT_MASK, input)
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onDetectEditMask ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			console.log("🚀 ~ onDetectEditMask ~ data:", data);
			if (statusCode === 200) {
				// success("Register successfuly")
				return callback?.(data);
			}
			error(`Err: ${message}`);
			return callback?.(null);
		} catch (err) {
			setLoading(false);
			return callback?.(null);
		}
	};

	const onExportRoto = async (
		input: TExportInput,
		callback?: (a: any) => void,
	) => {
		try {
			const isValid = await onExportRotoSchema.validate(input);
			if (!isValid) {
				warn("Field is required");
				return;
			}
			// setLoading(true);
			const rs: any = await axiosClient
				.post(ROUTES_API.AI.EXPORT, input)
				.catch(() => {
					setLoading(false);
				});
			// setLoading(false);
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				data?.url && saveAs(data.url, getBlobId(data.url, true));
				return callback?.(data);
			}
			// warn(`${message}`);
			onUpdateParams({ textWarnExport: message });
			return callback?.(null);
		} catch (err) {
			// setLoading(false);
			return callback?.(null);
		}
	};

	return {
		onSequence,
		onReset,
		onSamPredict,
		onGetSamPredict,
		onUpdateBezierCurve,
		onExportBezierCurve,
		onDetectEditMask,
		onExportRoto,
		loading,
		loadingSequence,
	};
}
