
import { useCommon } from 'hooks/useCommon';
import { Progress } from 'flowbite-react';
import type { CustomFlowbiteTheme } from "flowbite-react";
import * as React from 'react';

const customTheme: CustomFlowbiteTheme["progress"] = {
    "base": "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700 transition-all",
    "label": "mb-1 flex justify-between font-medium dark:text-white",
    "bar": "space-x-2 rounded-full text-center font-medium leading-none text-dark transition-all",
    "color": {
        "dark": "bg-gray-600 dark:bg-gray-300",
        "blue": "bg-blue-600",
        "red": "bg-red-600 dark:bg-red-500",
        "green": "bg-green-600 dark:bg-green-500",
        "yellow": "bg-yellow-400",
        "indigo": "bg-indigo-600 dark:bg-indigo-500",
        "purple": "bg-purple-600 dark:bg-purple-500",
        "cyan": "bg-cyan-600",
        "gray": "bg-gray-500",
        "lime": "bg-lime-600",
        "pink": "bg-pink-500",
        "teal": "bg-teal-600",
        "custom": "bg-gradient-to-r from-[#7340d8]  #5271ff to-[#48d8e4] text-light text-[9px] w-full flex items-center justify-center"
    },
};


export default function ReactProgressBar({ cur, total }: any) {
    const [progress, setProgress] = React.useState<any>(0);
    // const { nFrame } = useCommon()


    React.useEffect(() => {
        // const { cur = null, total = null } = nFrame || {};

        if (!cur || !total) return

        setProgress(parseFloat(`${(cur / total) * 100}`).toFixed(0));
    }, [cur, total]);

    return (
        <div className="w-full h-fit bg-white rounded-full">
            <Progress
                progress={progress}
                size="lg"
                labelProgress
                theme={customTheme}
                color="custom"
            />
        </div>
    );
}
