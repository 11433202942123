import { TextField } from '@mui/material'
import React from 'react'

export default function InputFX(prop: any) {
    const { name, type, placeholder, setFormData, formData, value, setErrors, errors, required = true } = prop
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const styles = {
        // Root class for the input field
        "& .MuiOutlinedInput-root": {
            color: "white",
            fontFamily: "Arial",
            // fontWeight: "bold",
            // Class for the border around the input field
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3257bd",
                borderWidth: "2px",
                classes: 'border-gradient'
            },
            "&:hover:not(.Mui-focused)": {
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#3257bd",
                },
            },
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
        // Class for the label of the input field
        "& .MuiInputLabel-outlined": {
            color: "white",
            fontWeight: "bold",
        },
        '& .MuiFormHelperText-root': {
            color: 'red'
        }
    };

    return (<TextField
        required={required}
        id="outlined-required"
        label={placeholder}
        defaultValue={value}
        fullWidth={true}
        autoComplete='off'

        type={type}
        // placeholder={placeholder}
        onChange={handleInputChange}
        sx={styles}
        helperText={errors}

    />)
}
