import { useRef } from "react";
import { TForgotPassword, TLogin, TLoginSocial, TRegister } from "types/auth";
import axiosClient from "utils/axios";
import { ROUTES_API } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { useAlert } from "./useAlert";
import { useCommon } from "./useCommon";
import { setAuthInfo, setLogIn } from "redux/slices/user";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { googleLogout } from "@react-oauth/google";
import { changePasswdSchema } from "utils/validField";

export const useAuth = () => {
  const { onUpdateParams } = useCommon();
  const { success, error } = useAlert();
  const router = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const preRef = useRef<any>({ timeout: null }).current;
  const onLogin = async (fomData: TLogin, callback?: (a: any) => void) => {
    try {
      const { statusCode, data, message }: any = await axiosClient.post(
        ROUTES_API.AUTH.LOGIN,
        fomData
      );
      if (statusCode === 200 && data?.accessToken) {
        console.log("🚀 ~ onLogin ~ rs:", statusCode);
        // await localStorage.setItem('_token', data.accessToken)
        // await createSession(data.accessToken)
        dispatch(setLogIn(true));
        dispatch(setAuthInfo(data));
        localStorage.setItem("_token", data.accessToken);
        router("/admin/project");
        // success("Login successfuly");
        return callback?.(true);
      }

      console.log("=====message=====", message);
      // error("Username or password wrong");
      error(message);
      // return callback?.(false);
    } catch (err) {
      console.log("🚀 ~ onLogin ~ error:", error);
      error(JSON.stringify(error));
      // return callback?.(false);
    }
  };

  const onLoginSocial = async (
    fomData: TLoginSocial,
    callback?: (a: any) => void
  ) => {
    try {
      const { statusCode, data, message }: any = await axiosClient.post(
        ROUTES_API.AUTH.LOGIN_SOCIAL,
        fomData
      );
      if (statusCode === 200 && data?.accessToken) {
        console.log("🚀 ~ onLogin ~ rs:", statusCode);
        // await localStorage.setItem('_token', data.accessToken)
        // await createSession(data.accessToken)
        dispatch(setLogIn(true));
        dispatch(setAuthInfo(data));
        localStorage.setItem("_token", data.accessToken);
        router("/admin/project");
        // success("Login successfuly");
        return callback?.(true);
      }

      console.log("=====message=====", message);
      // error("Username or password wrong");
      error(message);
      // return callback?.(false);
    } catch (err) {
      console.log("🚀 ~ onLogin ~ error:", error);
      error(JSON.stringify(error));
      // return callback?.(false);
    }
  };

  const onRegister = async (
    fomData: TRegister,
    callback?: (a: any) => void
  ) => {
    console.log("=================================================");
    console.log("fomData", fomData);
    try {
      if (fomData.password !== fomData.repassword) {
        error("Password is not matching");
        return;
      }
      const { statusCode, data, message }: any = await axiosClient.post(
        ROUTES_API.AUTH.REGISTER,
        fomData
      );
      if (statusCode >= 200 && statusCode < 300) {
        console.log("🚀 ~ onRegister ~ rs:", statusCode);
        // success("Register successfuly");
        onProfile();
        router("/auth/signin");
        return callback?.(true);
      }
      error(message);
      // return callback?.(false);
    } catch (err: any) {
      console.log("🚀 ~ onRegister ~ error:", err);
      error(err?.message);
      return callback?.(false);
    }
  };

  const onLogout = async () => {
    const rs: any = await axiosClient.post(ROUTES_API.USER.LOGOUT);
    localStorage.removeItem("_token");
    console.log("🚀 ~ onLogout ~ rs:", rs);
    // success("Logout successfuly");
    googleLogout();
    router("/auth/signin");
  };

  const onProfile = async (callback?: (a: any) => void) => {
    try {
      if (preRef.timeout) {
        clearTimeout(preRef.timeout);
      }
      console.log("🚀 ~ onProfile ~ statusCode, aa:");
      preRef.timeout = setTimeout(async () => {
        const { statusCode, data }: any = await axiosClient.get(
          ROUTES_API.USER.PROFILE
        );
        console.log("🚀 ~ onProfile ~ statusCode, data:", statusCode, data);
        if (statusCode === 200) {
          onUpdateParams({ profile: data });
          // onAvatar(data);
        }
      }, 100);
      // error("Username or email is exists!")
    } catch (err) {
      error(JSON.stringify(error));
      return callback?.(false);
    }
  };

  const onAvatar = async (pro: any) => {
    if (pro?.avatar) {
      console.log("🚀 ~ profile?.avatar:", pro?.avatar);
      const file: any = await axiosClient.get(ROUTES_API.MEDIA.ROOT, {
        params: { path: pro?.avatar },
      });
      console.log("🚀 ~ file:", file);
      onUpdateParams({ avatar: file });
    }
  };

  const onSendOTP = async (fomData: TForgotPassword) => {
    console.log("=================================================");
    console.log("fomData", fomData);
    try {
      if (!fomData.email) {
        error("Invalid or empty email address");
        return;
      }
      const { statusCode, data, message }: any = await axiosClient.post(
        ROUTES_API.SEND_MAIL.OTP,
        fomData
      );
      if (statusCode === 200) {
        success("Please check and enter the code below");
        return statusCode;
      }
      error(message);
      return false;
    } catch (err: any) {
      error(err?.message ?? "Disconnect server");
      return false;
    }
  };

  const onVerifyOTP = async (fomData: TForgotPassword) => {
    console.log("=================================================");
    console.log("fomData", fomData);
    try {
      if (!fomData.otp) {
        error("OTP is incorrect, please try again");
        return;
      }
      const { statusCode, data, message }: any = await axiosClient.post(
        ROUTES_API.AUTH.VERIFY_OTP,
        fomData
      );
      if (statusCode === 200) {
        localStorage.setItem("_uid", data.uid);
        success("Enter your new password");
        return statusCode;
      }
      error(message);
      return false;
    } catch (err: any) {
      error(err?.message);
      return false;
    }
  };

  const onChangePassword = async (
    fomData: TForgotPassword,
    callback?: (a: any) => void
  ) => {
    console.log("=================================================");
    try {
      if (fomData.newPassword !== fomData.repassword) {
        error("Password is not matching");
        return;
      }
      const uid = localStorage.getItem("_uid");
      if (!uid) {
        error("Change password errors");
        return;
      }
      console.log("fomData isValid", fomData);
      await changePasswdSchema.validate(fomData);

      const updatedFomData = { ...fomData, uid };

      const { statusCode, data, message }: any = await axiosClient.post(
        ROUTES_API.AUTH.CHANGE_PASSWORD,
        updatedFomData
      );
      if (statusCode >= 200 && statusCode < 300) {
        // onProfile();
        router("/auth/signin");
        success("Successfully");
        // return callback?.(true);
      }
      // error(message);
      return callback?.(false);
    } catch (err: any) {
      error(err?.message);
      return callback?.(false);
    }
  };

  return {
    onLogin,
    onLoginSocial,
    onLogout,
    onRegister,
    onProfile,
    onSendOTP,
    onVerifyOTP,
    onChangePassword,
  };
};
