import React, { useEffect, useState } from "react";
import { useCommon } from "./useCommon";

const useProgressUpload = (code: string) => {
	const [progress, setProgress] = useState(0);
	const [totalFrame, setTotalFrame] = useState(0);
	const { socket } = useCommon();

	useEffect(() => {
		const handleSocketEvent = (dataSocket) => {
			setProgress(dataSocket?.step);
			setTotalFrame(dataSocket?.totalFrames + 10);
		};
		if (socket && code) {
			socket.on(`eventProgress-${code}`, handleSocketEvent);
		}
		return () => {
			if (socket) {
				socket.off(`eventProgress-${code}`, handleSocketEvent);
			}
		};
	}, [socket, code]);

	return { progress, totalFrame, setProgress };
};

export default useProgressUpload;
