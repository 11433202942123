
export const styles = {
    bg: {
        top: '50%',
        left: '50%',
        backgroundColor: '#000000',
        transform: 'translate(-50%, -50%)',
      },
    wrapContent: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    content: {
        backgroundColor: '#f1f1f1',
        borderRadius: 10,
        minWidth: 600, minHeight: 300, padding: 10,
    },
    backdrop: {
        backgroundColor: '#000000',
        position: 'absolute',
        zIndex: 99999999999
      }  
};