import React, { Dispatch, SetStateAction, useCallback } from "react";
import ModalLayout from "./ModalLayout";
import SvgIcon from "assets/SvgIcon";
import { useCommon } from "hooks/useCommon";

interface IModalStatusExport {
	isOpen: boolean;
	progressExport: number;
	totalProcess: number;
	statusExport: string;
	setIsModalStatus: Dispatch<SetStateAction<boolean>>;
	setStatusSuccessExport: Dispatch<SetStateAction<string>>;
}

const ModalStatusExport = ({
	isOpen,
	progressExport,
	totalProcess,
	statusExport,
	setIsModalStatus,
	setStatusSuccessExport,
}: IModalStatusExport) => {
	const { textWarnExport } = useCommon();
	const progressBarStyle = {
		width: `${((progressExport / totalProcess || 0) * 100)?.toFixed(1) || 0}%`,
	};

	const renderStatusExport = useCallback(() => {
		if (statusExport === "NORMAL") {
			return (
				<div className="flex flex-col h-full">
					<div className="px-4 h-3/4 flex flex-col justify-center items-center content-center gap-2 text-white text-[12px] font-semibold">
						<span className="w-full text-left">You are almost done</span>
						<div className="flex justify-start w-full h-fit bg-white rounded">
							<div
								className={`h-[8px] bg-[#5271FF] rounded transition-all`}
								style={progressBarStyle}></div>
						</div>
						<div className="flex w-full justify-between">
							<span>1 minutes left...</span>
							<span className="italic">{progressBarStyle?.width}</span>
						</div>
					</div>
					<div className="h-1/4 flex flex-col justify-center items-center border-t">
						<button
							className="rounded-lg px-8 py-1 border text-white text-[10px] font-semibold"
							onClick={() => console.log("Handle cancel export")}>
							Cancel
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="flex flex-col h-full">
					<div className="px-4 h-3/4 flex flex-col justify-center items-center content-center gap-2 text-white text-[12px] font-semibold">
						{statusExport === "SUCCESS" ? (
							<>
								<span className="flex w-fix h-fix justify-center p-5 rounded-full text-[32px] bg-[#019A76]">
									{SvgIcon.CheckIcon()}
								</span>
								<div className="flex w-full justify-center text-[14px] font-semibold">
									<span>Your video was exported successfully.</span>
									<span></span>
								</div>
							</>
						) : statusExport === "FAIL" ? (
							<>
								<span className="flex w-fit h-fit justify-center p-5 rounded-full text-[32px]">
									{SvgIcon.WarnIcon()}
								</span>
								<div className="flex flex-col w-full items-center content-center text-[14px] font-semibold">
									<span>Export Warning!</span>
									<span className="text-[#FFCC00] text-[12px]">
										{textWarnExport.length > 0
											? textWarnExport
											: "Warning message"}
									</span>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
					<div className="h-1/4 flex flex-col justify-center items-center border-t">
						<button
							className="rounded-lg px-8 py-1 border text-white text-[10px] font-semibold"
							onClick={() => {
								setStatusSuccessExport("NORMAL");
								setIsModalStatus(false);
							}}>
							Closed
						</button>
					</div>
				</div>
			);
		}
	}, [progressExport, totalProcess, statusExport]);

	return (
		<ModalLayout
			open={isOpen}
			toggleModal={() => setIsModalStatus((pre) => !pre)}
			headerTitle="Export"
			className="w-[400px] h-[250px] bg-[#1F1F1F]">
			{renderStatusExport()}
		</ModalLayout>
	);
};

export default ModalStatusExport;
