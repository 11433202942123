import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import SubmitButton from "./SubmitButton";
import { Box, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";
import BSplineIcon from "assets/BsplineIcon";

export interface ILine {
	controlPoints?: any[];
	lines?: any[];
	closed?: boolean;
	selected?: boolean;
}

interface IRightSideBSPline {
	lines: ILine[];
	selectedLine?: ILine;
	isShowBtnSaveBsLine?: boolean;
	controlPoint: Array<[number, number]>;
	handleSelected?: (line: ILine) => void;
	handleExitBSpline?: () => void;
	onSaveSpline: () => void;
	isNewCurve: boolean;
	isDeleteCurve: boolean;
	isAddPoint: boolean;
	isRemovePoint: boolean;
	setIsNewCurve: Dispatch<SetStateAction<boolean>>;
	setIsDeleteCurve: Dispatch<SetStateAction<boolean>>;
	setIsAddPoint: Dispatch<SetStateAction<boolean>>;
	setIsRemovePoint: Dispatch<SetStateAction<boolean>>;
	handleDeleteCurve?: () => void;
	setSelectedSpline: Dispatch<SetStateAction<number>>;
	selectedSpline: number;
	onNewCurve: () => void;
	onDeleteCurve: () => void;
	onAddPoint: () => void;
}

const showClassAfterCheck = (isCheck, disable = true) => {
	return isCheck
		? "bg-gradient-to-r from-[#7340d88c] #5272ff71 to-[#48d7e49a] rounded-md pointer-events-auto"
		: disable
		? "pointer-events-none"
		: "";
};

export default function RightSideBSPline({
	handleExitBSpline,
	onSaveSpline,
	isNewCurve,
	isDeleteCurve,
	isAddPoint,
	isRemovePoint,
	isShowBtnSaveBsLine,
	controlPoint,
	setIsNewCurve,
	setIsDeleteCurve,
	setIsAddPoint,
	setIsRemovePoint,
	setSelectedSpline,
	selectedSpline,
	onNewCurve,
	onDeleteCurve,
}: IRightSideBSPline) {
	const { height } = useWindowDimensions();

	const ArrButtonAction = [
		{
			icon: BSplineIcon.Plus(),
			text: "New Curve",
			action: () => {
				setIsNewCurve(false);
				setIsAddPoint(false);
				setIsDeleteCurve(false);
				setIsRemovePoint(false);
				onNewCurve();
			},
			class: showClassAfterCheck(isNewCurve),
		},
		{
			icon: BSplineIcon.Delete(),
			text: "Delete Curve",
			action: () => onDeleteCurve?.(),
			class: showClassAfterCheck(isDeleteCurve),
		},
		{
			icon: BSplineIcon.AddPoint(),
			text: "Add Points",
			action: () => {
				setIsAddPoint((pre) => !pre);
				setIsRemovePoint(false);
				// setIsNewCurve(false);
			},
			class: showClassAfterCheck(isAddPoint, false),
		},
		{
			icon: BSplineIcon.RemovePoint(),
			text: "Remove Points",
			action: () => {
				setIsRemovePoint((pre) => !pre);
				setIsAddPoint(false);
				// setIsNewCurve(false);
			},
			class: showClassAfterCheck(isRemovePoint, false),
		},
		{
			icon: BSplineIcon.SavePoint(),
			text: "Save BSpline",
			action: () => onSaveSpline(),
			class: showClassAfterCheck(isShowBtnSaveBsLine),
		},
	];

	console.log("selectedSpline", selectedSpline);

	useEffect(() => {
		if (controlPoint?.length > 0 && selectedSpline! >= 0) {
			return setIsDeleteCurve(true);
		}
		setIsDeleteCurve(false);
	}, [controlPoint, selectedSpline]);

	return (
		<div className="w-[250px] h-full pr-2 py-5">
			<div
				className="border border-[#3c3c3c] rounded-lg bg-[rgb(31,31,31)] lg:px-4 px-2 flex flex-col content-between place-content-between overflow-y-scroll scrollbar-hide "
				style={{ height: height - 190 }}>
				<div>
					<div className="px-4">
						{ArrButtonAction.map((item, index) => {
							return (
								<SubmitButton
									key={index}
									icon={item.icon}
									text={item.text}
									onClick={(f: boolean) => {
										item.action();
									}}
									className={item.class}
								/>
							);
						})}
					</div>

					<Box
						padding={"12px"}
						borderRadius={"8px"}
						sx={{ border: "1px solid #3C3C3C" }}
						display={"flex"}
						flexDirection={"column"}
						gap={"5px"}>
						<Typography
							fontSize={"16px"}
							fontWeight={"700"}
							color={"white"}
							mb={"10px"}>
							Root
						</Typography>
						{controlPoint?.map((_, idx) => {
							return (
								<div
									onClick={() => setSelectedSpline(idx)}
									className={`${
										selectedSpline === idx ? "bg-[#B3B6B633]" : undefined
									} flex items-center justify-start pl-3 text-white font-medium text-[12px] gap-2 cursor-pointer hover:bg-slate-800 transition-all`}>
									{" "}
									{BSplineIcon.Line()} BSpline {idx + 1}
								</div>
							);
						})}
					</Box>
				</div>

				<div>
					<SubmitButton
						icon={<IoClose />}
						text={"Exit Bspline"}
						onClick={(f: boolean) => {
							handleExitBSpline?.();
							setIsNewCurve(true);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
