const UserSettingIcon = {
	Setting: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none">
			<path
				d="M8.71492 5.49C8.73492 5.33 8.74992 5.17 8.74992 5C8.74992 4.83 8.73492 4.67 8.71492 4.51L9.76992 3.685C9.86492 3.61 9.88992 3.475 9.82992 3.365L8.82992 1.635C8.76992 1.525 8.63492 1.485 8.52492 1.525L7.27992 2.025C7.01992 1.825 6.73992 1.66 6.43492 1.535L6.24492 0.21C6.22992 0.09 6.12492 0 5.99992 0H3.99992C3.87492 0 3.76992 0.09 3.75492 0.21L3.56492 1.535C3.25992 1.66 2.97992 1.83 2.71992 2.025L1.47492 1.525C1.35992 1.48 1.22992 1.525 1.16992 1.635L0.16992 3.365C0.10492 3.475 0.13492 3.61 0.22992 3.685L1.28492 4.51C1.26492 4.67 1.24992 4.835 1.24992 5C1.24992 5.165 1.26492 5.33 1.28492 5.49L0.22992 6.315C0.13492 6.39 0.10992 6.525 0.16992 6.635L1.16992 8.365C1.22992 8.475 1.36492 8.515 1.47492 8.475L2.71992 7.975C2.97992 8.175 3.25992 8.34 3.56492 8.465L3.75492 9.79C3.76992 9.91 3.87492 10 3.99992 10H5.99992C6.12492 10 6.22992 9.91 6.24492 9.79L6.43492 8.465C6.73992 8.34 7.01992 8.17 7.27992 7.975L8.52492 8.475C8.63992 8.52 8.76992 8.475 8.82992 8.365L9.82992 6.635C9.88992 6.525 9.86492 6.39 9.76992 6.315L8.71492 5.49ZM4.99992 6.75C4.03492 6.75 3.24992 5.965 3.24992 5C3.24992 4.035 4.03492 3.25 4.99992 3.25C5.96492 3.25 6.74992 4.035 6.74992 5C6.74992 5.965 5.96492 6.75 4.99992 6.75Z"
				fill="white"
			/>
		</svg>
	),
	Subscription: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none">
			<path
				d="M6.085 0.5H1.5C0.95 0.5 0.5 0.95 0.5 1.5V8.5C0.5 9.05 0.95 9.5 1.5 9.5H8.5C9.05 9.5 9.5 9.05 9.5 8.5V3.915C9.5 3.65 9.395 3.395 9.205 3.21L6.79 0.795C6.605 0.605 6.35 0.5 6.085 0.5ZM3 6.5H7C7.275 6.5 7.5 6.725 7.5 7C7.5 7.275 7.275 7.5 7 7.5H3C2.725 7.5 2.5 7.275 2.5 7C2.5 6.725 2.725 6.5 3 6.5ZM3 4.5H7C7.275 4.5 7.5 4.725 7.5 5C7.5 5.275 7.275 5.5 7 5.5H3C2.725 5.5 2.5 5.275 2.5 5C2.5 4.725 2.725 4.5 3 4.5ZM3 2.5H5.5C5.775 2.5 6 2.725 6 3C6 3.275 5.775 3.5 5.5 3.5H3C2.725 3.5 2.5 3.275 2.5 3C2.5 2.725 2.725 2.5 3 2.5Z"
				fill="#B3B6B6"
			/>
		</svg>
	),
	Payment: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none">
			<path
				d="M0.5 2C0.225 2 0 2.225 0 2.5V9C0 9.55 0.45 10 1 10H7.5C7.775 10 8 9.775 8 9.5C8 9.225 7.775 9 7.5 9H1.5C1.225 9 1 8.775 1 8.5V2.5C1 2.225 0.775 2 0.5 2ZM9 0H3C2.45 0 2 0.45 2 1V7C2 7.55 2.45 8 3 8H9C9.55 8 10 7.55 10 7V1C10 0.45 9.55 0 9 0ZM8 4.5H4C3.725 4.5 3.5 4.275 3.5 4C3.5 3.725 3.725 3.5 4 3.5H8C8.275 3.5 8.5 3.725 8.5 4C8.5 4.275 8.275 4.5 8 4.5ZM6 6.5H4C3.725 6.5 3.5 6.275 3.5 6C3.5 5.725 3.725 5.5 4 5.5H6C6.275 5.5 6.5 5.725 6.5 6C6.5 6.275 6.275 6.5 6 6.5ZM8 2.5H4C3.725 2.5 3.5 2.275 3.5 2C3.5 1.725 3.725 1.5 4 1.5H8C8.275 1.5 8.5 1.725 8.5 2C8.5 2.275 8.275 2.5 8 2.5Z"
				fill="white"
			/>
		</svg>
	),
	Invoice: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none">
			<path
				d="M9.5 0.104609C9.435 0.104609 9.37 0.129609 9.325 0.179609L8.93 0.574609C8.83 0.674609 8.675 0.674609 8.575 0.574609L8.18 0.179609C8.08 0.0796094 7.925 0.0796094 7.825 0.179609L7.43 0.574609C7.33 0.674609 7.175 0.674609 7.075 0.574609L6.68 0.179609C6.58 0.0796094 6.425 0.0796094 6.325 0.179609L5.93 0.574609C5.83 0.674609 5.675 0.674609 5.575 0.574609L5.18 0.179609C5.08 0.0796094 4.925 0.0796094 4.825 0.179609L4.43 0.574609C4.33 0.674609 4.175 0.674609 4.075 0.574609L3.675 0.174609C3.575 0.0746094 3.42 0.0746094 3.32 0.174609L2.925 0.574609C2.825 0.674609 2.67 0.674609 2.57 0.574609L2.175 0.174609C2.075 0.0746094 1.92 0.0746094 1.82 0.174609L1.425 0.574609C1.325 0.674609 1.17 0.674609 1.07 0.574609L0.675 0.174609C0.63 0.129609 0.565 0.104609 0.5 0.104609V9.89961C0.565 9.89961 0.63 9.87461 0.675 9.82461L1.07 9.42961C1.17 9.32961 1.325 9.32961 1.425 9.42961L1.82 9.82461C1.92 9.92461 2.075 9.92461 2.175 9.82461L2.57 9.42961C2.67 9.32961 2.825 9.32961 2.925 9.42961L3.32 9.82461C3.42 9.92461 3.575 9.92461 3.675 9.82461L4.07 9.42961C4.17 9.32961 4.325 9.32961 4.425 9.42961L4.82 9.82461C4.92 9.92461 5.075 9.92461 5.175 9.82461L5.57 9.42961C5.67 9.32961 5.825 9.32961 5.925 9.42961L6.32 9.82461C6.42 9.92461 6.575 9.92461 6.675 9.82461L7.07 9.42961C7.17 9.32961 7.325 9.32961 7.425 9.42961L7.82 9.82461C7.92 9.92461 8.075 9.92461 8.175 9.82461L8.57 9.42961C8.67 9.32961 8.825 9.32961 8.925 9.42961L9.32 9.82461C9.37 9.87461 9.435 9.89961 9.495 9.89961V0.104609H9.5ZM7.5 7.49961H2.5C2.225 7.49961 2 7.27461 2 6.99961C2 6.72461 2.225 6.49961 2.5 6.49961H7.5C7.775 6.49961 8 6.72461 8 6.99961C8 7.27461 7.775 7.49961 7.5 7.49961ZM7.5 5.49961H2.5C2.225 5.49961 2 5.27461 2 4.99961C2 4.72461 2.225 4.49961 2.5 4.49961H7.5C7.775 4.49961 8 4.72461 8 4.99961C8 5.27461 7.775 5.49961 7.5 5.49961ZM7.5 3.49961H2.5C2.225 3.49961 2 3.27461 2 2.99961C2 2.72461 2.225 2.49961 2.5 2.49961H7.5C7.775 2.49961 8 2.72461 8 2.99961C8 3.27461 7.775 3.49961 7.5 3.49961Z"
				fill="#B3B6B6"
			/>
		</svg>
	),
	Logout: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none">
			<path
				d="M1.5 9.5C1.225 9.5 0.989667 9.40217 0.794 9.2065C0.598333 9.01083 0.500333 8.77533 0.5 8.5V1.5C0.5 1.225 0.598 0.989667 0.794 0.794C0.99 0.598333 1.22533 0.500333 1.5 0.5H4.5C4.64167 0.5 4.7605 0.548 4.8565 0.644C4.9525 0.74 5.00033 0.858667 5 1C4.99967 1.14133 4.95167 1.26017 4.856 1.3565C4.76033 1.45283 4.64167 1.50067 4.5 1.5H1.5V8.5H4.5C4.64167 8.5 4.7605 8.548 4.8565 8.644C4.9525 8.74 5.00033 8.85867 5 9C4.99967 9.14133 4.95167 9.26017 4.856 9.3565C4.76033 9.45283 4.64167 9.50067 4.5 9.5H1.5ZM7.5875 5.5H4C3.85833 5.5 3.73967 5.452 3.644 5.356C3.54833 5.26 3.50033 5.14133 3.5 5C3.49967 4.85867 3.54767 4.74 3.644 4.644C3.74033 4.548 3.859 4.5 4 4.5H7.5875L6.65 3.5625C6.55833 3.47083 6.5125 3.35833 6.5125 3.225C6.5125 3.09167 6.55833 2.975 6.65 2.875C6.74167 2.775 6.85833 2.72283 7 2.7185C7.14167 2.71417 7.2625 2.76217 7.3625 2.8625L9.15 4.65C9.25 4.75 9.3 4.86667 9.3 5C9.3 5.13333 9.25 5.25 9.15 5.35L7.3625 7.1375C7.2625 7.2375 7.14383 7.2855 7.0065 7.2815C6.86917 7.2775 6.75033 7.22533 6.65 7.125C6.55833 7.025 6.51467 6.90633 6.519 6.769C6.52333 6.63167 6.57117 6.517 6.6625 6.425L7.5875 5.5Z"
				fill="#B3B6B6"
			/>
		</svg>
	),
	Switch: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="8"
			height="11"
			viewBox="0 0 8 11"
			fill="none">
			<path
				d="M4 2.50082V3.39582C4 3.62082 4.27 3.73082 4.425 3.57082L5.82 2.17582C5.92 2.07582 5.92 1.92082 5.82 1.82082L4.425 0.425825C4.27 0.270825 4 0.380825 4 0.605825V1.50082C1.79 1.50082 0 3.29082 0 5.50082C0 6.02082 0.1 6.52082 0.285 6.97582C0.42 7.31082 0.85 7.40083 1.105 7.14582C1.24 7.01082 1.295 6.80582 1.22 6.62582C1.075 6.28083 1 5.89582 1 5.50082C1 3.84582 2.345 2.50082 4 2.50082ZM6.895 3.85582C6.76 3.99082 6.705 4.20082 6.78 4.37582C6.92 4.72582 7 5.10582 7 5.50082C7 7.15582 5.655 8.50082 4 8.50082V7.60582C4 7.38082 3.73 7.27082 3.575 7.43082L2.18 8.82582C2.08 8.92583 2.08 9.08082 2.18 9.18083L3.575 10.5758C3.73 10.7308 4 10.6208 4 10.4008V9.50082C6.21 9.50082 8 7.71082 8 5.50082C8 4.98082 7.9 4.48082 7.715 4.02582C7.58 3.69082 7.15 3.60082 6.895 3.85582Z"
				fill="white"
			/>
		</svg>
	),
	Close: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none">
			<path
				d="M13.3 0.710703C12.91 0.320703 12.28 0.320703 11.89 0.710703L6.99997 5.5907L2.10997 0.700703C1.71997 0.310703 1.08997 0.310703 0.699971 0.700703C0.309971 1.0907 0.309971 1.7207 0.699971 2.1107L5.58997 7.0007L0.699971 11.8907C0.309971 12.2807 0.309971 12.9107 0.699971 13.3007C1.08997 13.6907 1.71997 13.6907 2.10997 13.3007L6.99997 8.4107L11.89 13.3007C12.28 13.6907 12.91 13.6907 13.3 13.3007C13.69 12.9107 13.69 12.2807 13.3 11.8907L8.40997 7.0007L13.3 2.1107C13.68 1.7307 13.68 1.0907 13.3 0.710703Z"
				fill="white"
			/>
		</svg>
	),
};

export default UserSettingIcon;
