export type IBrushMode = {
  title: string;
};

export enum EBrushMode {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}

export enum TypeReset {
  OBJECT = "Object",
  FRAME = "All Object",
  ALL_FRAME = "All Frame",
}

export const ResetType = {
  OBJECT: "OBJECT",
  FRAME: "FRAME",
  ALL_FRAME: "ALL_FRAME",
};
