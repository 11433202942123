export enum EMode {
	IMAGE = "images",
	MASK = "mask",
	TRANSPARENT = "transparent",
	DAVIS = "davis",
	BINARY_MASKS = "binary_masks",
}

export enum ESegmentType {
	CLICK = "click",
	BOX = "box",
	// ANY = "any",
}

export enum EModeFrame {
	EDIT = "edit",
	BEDIT = "bedit",
	SAVE = "save",
	VIEW = "view",
	BSPLEDIT = "bspledit",
	REMOVAL = "removal",
}

export type TSamPredict = {
	is_new_image: number;
	vis_mode: EMode;
	object_idx: string;
	segment_type: ESegmentType;
	points: string;
	labels: string;
	code: string;
};

export type TGetVisMode = {
	blob_id: string;
	code: string;
	type: string;
};

export type TSequence = {
	mask: string;
	code: string;
	type: string;
	paused?: boolean;
	isEmit?: boolean;
	startFrame: string;
	endFrame: string;
};

export type TExportBezierCurve = {
	blob_id: string;
	code: string;
	object_idx: string;
	width: number;
	height: number;
};

export type TUpdateBezierCurve = {
	mask: string;
	code: string;
	type: string;
	file: File;
	width: number;
	height: number;
};

export type TResetType = "FRAME" | "OBJECT";
export enum EResetType {
	"FRAME" = "FRAME",
	"OBJECT" = "OBJECT",
}

export type TReset = {
	blob_id: string;
	code: string;
	object_idx: string;
	vis_mode: string;
	route?: string;
	type: TResetType;
};

export enum EClick {
	R_CLICK = "r_click",
	L_CLICK = "l_click",
	C_CLICK = "C_click",
}

export type TDetectMask = {
	code: string;
	mask: string; // blob_id
	blur_ratio: string;
	anti_alias_level: string;
	erode_ratio: string;
	dilate_ratio: string;
	type: string; // visMOde
};

export type TExportInput = {
	typeExport: string;
	code: string;
	mask: string;
	type: string;
	frameRate?: number;
	numberObjects?: number;
	scalePoint?: number;
	choosingFrame?: number;
	startKeyframe?: number;
	startFrame?: number;
	endFrame?: number;
	width?: number;
	height?: number;
};

export type TMaskToBsPLine = {
	code: string;
	numberCtPoint: number;
	mask: string;
	objectId: number;
};

export type TBsPLineToMask = {
	code: string;
	mutibleControlpoints: Array<[number, number]>;
	mask: string;
	objectId: number;
	type: string;
};

export type TRemovalObject = {
	code: string;
	startFrame: string;
	endFrame: string;
	paused?: boolean;
	objectId?: number;
	xStart: number;
	yStart: number;
	xEnd: number;
	yEnd: number;
};
