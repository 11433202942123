


import { FileInput, Label } from "flowbite-react";
import { MdOutlineFileUpload } from "react-icons/md";


export default function Dropzone({ onChange }: any) {
    return (
        <div className="flex items-center justify-center w-full borderCustomer">
            <div className="w-full rounded-[8px] border-[1.6px] border-dashed border-[#1f1f1f]">
                <Label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center rounded-[8px] justify-center w-full h-[10.25rem] cursor-pointer bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600 bg-[#1f1f1f]"
                >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6 bg-[#1f1f1f]">
                        <div className="p-2 bg-gradient-to-r from-[#7340D8] [#5271FF] to-[#48D8E4] rounded-full text-white text-[28px]"><MdOutlineFileUpload /></div>

                        <p className="mb-2 mt-2 text-[13px] text-white font-semibold text-center">
                            Upload Files
                        </p>
                        <p className="text-medium text-[13px] text-white text-center">
                            <span className="inline-block text-transparent underline bg-gradient-to-r from-[#7340D8] [#5271FF] to-[#48D8E4] bg-clip-text">Choose files </span>
                            <span className=""> Or drag theme here (*.mp4)</span>
                        </p>
                    </div>
                    <FileInput onChange={onChange} id="dropzone-file" className="hidden" />
                </Label>
            </div>
        </div>
    );
}
