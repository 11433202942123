import { ISubmitButton } from "types/RightSidebar/ISubmitButton";
import React, { useState } from "react";
// objectRs, onChangeReset
export default function SubmitButton({
	icon,
	text,
	onClick,
	className,
}: ISubmitButton) {
	const [isClicked, setIsClicked] = useState(false);

	const handleClick = () => {
		const r = !isClicked;
		console.log("🚀 ~ handleClick ~ r:", r);
		setIsClicked(r);
		onClick?.(r);
	};

	return (
		<button
			onClick={handleClick}
			className={`w-full flex flex-row justify-center self-center border-gradient p-2 px-4 font-semibold text-[12px] text-white my-4 ${
				isClicked ? "animate-click" : ""
			} ${className}`}>
			{icon ? <span className={`flex self-center px-1`}>{icon}</span> : null}
			<span className="px-2">{text}</span>
		</button>
	);
}
