/** @format */
import axios, { CreateAxiosDefaults } from "axios";

const config: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_BASE_URI,
  // baseURL: "http://localhost:8181/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
  paramsSerializer: {
    indexes: null,
  },
};
console.log(
  "🚀 ~ config.process.env.REACT_APP_BASE_URI:",
  process.env.REACT_APP_BASE_URI
);

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  async (req: any) => {
    try {
      const token = localStorage.getItem("_token");
      console.log("🚀 ~ token:", token);

      if (token) {
        req.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      // console.log(error, 'error');
    }
    return req;
  },
  (err: any) => Promise.reject(err)
);

axiosClient.interceptors.response.use(
  (res: any) => Promise.resolve(res.data),
  async (err: any) => {
    if (
      [401, 403].includes(
        err?.response?.status
      ) /* && !err.config.__isRetryRequest */
    ) {
      localStorage.removeItem("_token");
      window.location.replace("/");
      if (!window.location.pathname?.includes("login"))
        window.location.pathname = "/auth/signin";
    }

    // if (err && err.response && err.response.status === 401 && !err.config.__isRetryRequest) {
    // onLogout();
    // const refreshToken = getKey(KEY_CONTEXT.REFRESH_TOKEN);
    // const salt = encryptRSA(`${getCurrentTS()}`);
    // const params = JSON.stringify({
    //   ...REFRESH_TOKEN_ACT,
    //   data: [{ refreshToken }],
    // }).replace(/\\n/g, '');
    // return axios
    //   .post(`${process.env.REACT_APP_BASE_URI}${process.env.REACT_APP_GETWAY}`, {
    //     headers: config.headers,
    //   })
    //   .then(async (response: any) => {
    //     const authToken = response.data.result.data.token;
    //     const rfTK = response.data.result.data.refreshToken;
    //     originalRequest.headers = {
    //       ...originalRequest.headers,
    //       authorization: `Bearer ${authToken}`,
    //     };
    //     const key: IKeyAuth = {
    //       authToken,
    //       refreshToken: rfTK,
    //     };
    //     originalRequest.__isRetryRequest = true;
    //     setKeySite(key);
    //     return axiosClient(originalRequest);
    //   })
    //   .catch(() => {
    //     // logoutRequest();
    //   });
    // return store.dispatch(logoutRequest());
    // }
    return Promise.reject(((err || {}).response || {}).data);
  }
);

export default axiosClient;
