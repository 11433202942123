import { Box, Grid, makeStyles, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import usePricingPlan from "hooks/usePricingPlan";
import { useCallback, useEffect } from "react";
import { TPackageParams } from "types/payment";
import { formatPrice } from "utils/helper";

export default function InvoiceOverview() {
	const { onGetInvoices, invoices } = usePricingPlan();

	const onInitInvoices = ({ page = 1, limit = 10 }: TPackageParams) => {
		onGetInvoices({ page, limit });
	};

	useEffect(() => {
		onInitInvoices({ page: 1, limit: 10 });
	}, []);

	return (
		<Box sx={styles.col}>
			<Box mt={3} sx={styles.cenRow}>
				<Box sx={styles.col}>
					<Typography
						component={"span"}
						fontSize={"24px"}
						color={"white"}
						fontWeight={"600"}>
						Invoice Overview
					</Typography>
				</Box>
			</Box>
			<Box sx={styles.cenRow} width={"100%"} mt={3}>
				<Grid container spacing={3}>
					<Grid item xs={3}>
						<Typography variant={"body2"} component={"span"} color={"white"}>
							Date
						</Typography>
					</Grid>
					<Grid item xs={5}>
						<Typography variant={"body2"} component={"span"} color={"white"}>
							Invoice
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant={"body2"} component={"span"} color={"white"}>
							Credit
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant={"body2"} component={"span"} color={"white"}>
							Amount
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box sx={styles.line} />
			{invoices?.map((invoice, index: number) => (
				<Box sx={styles.cenRow} width={"100%"} key={index} mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={3}>
							<Typography variant={"body2"} component={"span"} color={"white"}>
								{invoice?.data ? dayjs(invoice?.data).format("MMMM D YYYY") : ""}
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Typography variant={"body2"} component={"span"} color={"white"}>
								{invoice?.invoice ?? ""}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant={"body2"} component={"span"} color={"white"}>
								{invoice?.credit ? invoice?.credit : 0}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant={"body2"} component={"span"} color={"white"}>
								{invoice?.amount ? formatPrice(invoice?.amount) : "$0"}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			))}
		</Box>
	);
}

const styles = {
	cenRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		display: "flex",
	},
	col: { flexDirection: "column", display: "flex" },
	line: {
		width: "100%",
		height: "1px",
		marginTop: 3,
		marginBottom: 3,
		backgroundColor: "#B3B6B6",
	},
};
