export const toHHMMSS = (duration: string, hh = false) => {
	const sec_num = parseInt(duration, 10); // don't forget the second param
	let hours: any = Math.floor(sec_num / 3600);
	let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
	let seconds: any = sec_num - hours * 3600 - minutes * 60;

	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	const isH = hours === "00" && hh ? "" : hours + ":";
	return isH + minutes + ":" + seconds;
};

export const loadImage = (url: string) =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.addEventListener("load", () => resolve(img));
		img.addEventListener("error", (err) => reject(err));
		img.src = url;
	}).catch((rea) => console.log(rea));

export const convertPercentageToDecimal = (percentage: string) => {
	const number = parseFloat(percentage.replace("%", ""));
	const decimal = number / 100;
	return decimal;
};

export const formatPrice = (price, locale = "en-US", currency = "USD") => {
	return new Intl.NumberFormat(locale, {
		style: "currency",
		currency: currency,
		minimumFractionDigits: 0,
        maximumFractionDigits: 0,
	}).format(price);
};
