import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	IconButton,
	LinearProgress,
	Modal,
	Typography,
} from "@mui/material";
import { useCommon } from "hooks/useCommon";
import AvatarDefault from "assets/images/header/avatar.png";
import AvatarDefaultTwo from "assets/images/header/avt-setting.png";
import { InfoUserSetting, MenuKeys, MenusModalSetting } from "utils/constants";
import UserSettingIcon from "assets/UserSettingIcon";
import { useAuth } from "hooks/useAuth";
import { GradientBorderButton } from "components/Buttons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "components/Sidebar/routes";
import PaymentDetails from "./components/PaymentDetails";
import InvoiceOverview from "./components/InvoiceOverview";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// boxShadow: 24,
};

const modalStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	zIndex: 10000,
};

export default function ModalUserSettings({
	openModalSetting,
}: {
	openModalSetting: boolean;
}) {
	const { onLogout } = useAuth();
	const router = useNavigate()
	const [userInfo, setUserInfo] = useState({})

	const { profile, onUpdateParams } = useCommon();
	const [menuActive, setMenuActive] = useState("Settings");
	console.log("Profile ================", profile);

	useEffect(() => { profile && setUserInfo(profile) }, [profile])

	const SettingComponent = useCallback(() => {
		return (
			<>
				<div>
					<div className="flex flex-col gap-2">
						<img width={64} height={64} src={AvatarDefaultTwo} alt="User" />
						{InfoUserSetting?.map((item) => (
							<div className="flex flex-col">
								<Typography
									component={"span"}
									fontSize={"16px"}
									color={"white"}
									marginTop={"10px"}
									marginBottom={"2px"}
									fontWeight={"600"}>
									{item?.label}
								</Typography>
								<div className="border-gradient w-[378px] py-1 flex items-center">
									<input
										type="text"
										value={userInfo?.[item.key]}
										className="w-full h-full text-[#B3B6B6] text-[16px] font-medium bg-transparent focus  border-0 border-transparent focus:border-transparent focus:ring-0 border-gradient"
									/>
								</div>
							</div>
						))}
						<PaymentDetails profile={profile} />
					</div>
					{/* <div className="mt-5 flex flex-col gap-4">
						<Typography
							component={"h3"}
							fontSize={"24px"}
							color={"#FFFFFF"}
							fontWeight={"700"}>
							Current Balance
						</Typography>
						<div className="flex justify-between items-center">
							<Typography
								component={"span"}
								fontSize={"14px"}
								color={"#B3B6B6"}
								fontWeight={"600"}>
								Storage
							</Typography>
							<div className="flex flex-col items-end">
								<Typography
									component={"span"}
									fontSize={"14px"}
									color={"#B3B6B6"}
									fontWeight={"600"}>
									1.5 GB of 5 GB used
								</Typography>
								<Box sx={{ width: "170px" }}>
									<LinearProgress variant="determinate" value={30} />
								</Box>
							</div>
						</div>
						<div className="flex justify-between items-center">
							<Typography
								component={"span"}
								fontSize={"14px"}
								color={"#B3B6B6"}
								fontWeight={"600"}>
								Credits
							</Typography>
							<Typography
								component={"span"}
								fontSize={"14px"}
								color={"#B3B6B6"}
								fontWeight={"600"}>
								1,100 of 1,200 left
							</Typography>
						</div>
						<div className="flex justify-between items-center">
							<Typography
								component={"span"}
								fontSize={"14px"}
								color={"#B3B6B6"}
								fontWeight={"600"}>
								User Character Uploads
							</Typography>
							<Typography
								component={"span"}
								fontSize={"14px"}
								color={"#B3B6B6"}
								fontWeight={"600"}>
								11 of 15 left
							</Typography>
						</div>
					</div> */}
				</div>
				<div className="w-full flex items-center justify-center">
					<div className=" w-[192px] h-[48px]">
						<GradientBorderButton
							onClick={() => { router(ROUTES.PRICING_PLAN) }}
							// firstIcon={SvgIcon.CheckIcon()}
							text="Upgrade Plan"
						/>
					</div>

					{/* <PayPal /> */}
				</div>
			</>
		);
	}, [userInfo]);

	const renderModalRight = () => {
		switch (menuActive) {
			case MenuKeys.SETTINGS:
				return SettingComponent();
			// case MenuKeys.PAYMENT_DETAILS:
			// 	return PaymentDetails({transactionPlans: profile?.transactionPlans || {}});
			case MenuKeys.INVOICE:
				return <InvoiceOverview />;
			default:
				break;
		}
	};

	const renderModalLeft = () => {
		return (<div className="w-[296px] h-full bg-[#1F1F1F] p-5 rounded-l-[8px] flex flex-col justify-between">
			<div className="">
				<h1 className="text-white text-[24px] font-bold pb-4">
					User Settings
				</h1>
				<div className=" py-4 flex flex-row gap-2">
					<img
						width={112}
						height={112}
						src={AvatarDefault}
						style={{
							width: "auto",
							height: "auto",
						}}
						alt="User"
					/>
					<span className="flex content-center items-center text-white text-[14px] font-normal">
						{userInfo?.userName}
					</span>
				</div>
				<div className="flex flex-col gap-2 mt-3 w-full">
					{MenusModalSetting.map(({ title, icon }) => (
						<div
							onClick={() => setMenuActive(title)}
							key={title}
							className={`cursor-pointer relative w-full transition-all flex flex-row py-2 px-2 rounded-md justify-between items-center  border-solid border-[2px]  ${menuActive === title
									? "border-[#282828]"
									: "border-transparent"
								}`}>
							<Typography
								component={"span"}
								fontSize={"12px"}
								fontWeight={"400"}
								color="#ffffff">
								{title}
							</Typography>
							<span>{icon()}</span>
							<div
								className={`bg-gradient-to-r transition-all from-[#7340D8]  #5271FF to-[#48D8E4] absolute bottom-[-2px] left-0 right-0 ${menuActive === title ? "w-full h-[2px]" : "w-0 h-[0px]"
									} `}></div>
						</div>
					))}
				</div>
			</div>
			<div className="flex items-center justify-center w-full">
				<div
					onClick={() => {
						onLogout();
						onUpdateParams({ openModalSetting: false });
					}}
					className="flex items-center justify-center gap-2 w-[76px] h-[34px] border-[1px] border-solid border-[#383838] rounded-md cursor-pointer hover:opacity-85 transition-all
			">
					<Typography
						component={"span"}
						fontSize={"12px"}
						fontWeight={"600"}
						color="#B3B6B6">
						Log out
					</Typography>
					{UserSettingIcon.Logout()}
				</div>
			</div>
		</div>)
	}

	return (
		<div>
			<Modal
				open={openModalSetting}
				onClose={() => onUpdateParams({ openModalSetting: false })}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="border-0 border-transparent focus:border-transparent focus:ring-0"
				sx={modalStyle}>
				<Box
					border={0}
					sx={style}
					padding={0}
					className="w-[800px] h-[707px] flex flex-row border-0 border-transparent focus:border-transparent focus:ring-0 rounded-[8px]">
					{renderModalLeft()}
					<div className="flex flex-col justify-between relative w-[504px] h-full bg-[#191A1E] p-5 rounded-r-[8px]">
						<div className="absolute top-2 right-2 ">
							<IconButton
								onClick={() => onUpdateParams({ openModalSetting: false })}>
								{UserSettingIcon.Close()}
							</IconButton>
						</div>
						{renderModalRight()}
					</div>
				</Box>
			</Modal>
		</div>
	);
}
