import Konva from "konva";
import { ShapeConfig } from "konva/lib/Shape";
import React, { useEffect, useRef, useState } from "react";
import {
  Stage,
  Layer,
  Line,
  Circle,
  Group,
  Image as KonvasImage,
  Shape,
} from "react-konva";
import useImage from "use-image";

interface BezierProps {
  imageUrl: string;
  width: number;
  height: number;
  shape?: ShapeConfig;
  line?: ShapeConfig;
  circle?: ShapeConfig;
}

export const BezierCurveCus: React.FC<BezierProps> = ({
  imageUrl,
  width,
  height,
  shape,
  line: _line,
  circle,
}) => {
  const initialPoints: number[] = [];
  const [image] = useImage(imageUrl);
  const stageRef = useRef<Konva.Stage>(null);

  const [controlPoints, setControlPoints] = useState<number[]>(initialPoints);
  const [lines, setLines] = useState<number[][]>([]);

  const handleStageClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = stageRef.current;
    const pointerPosition = stage?.getPointerPosition();
    if (pointerPosition) {
      const newPoints = [
        ...controlPoints,
        pointerPosition.x,
        pointerPosition.y,
      ];
      setControlPoints(newPoints);

      if (newPoints.length > 2) {
        const lastTwoPoints = newPoints.slice(-4);
        setLines([...lines, lastTwoPoints]);
      }
    }
  };

  const handlePointDragMove = (index: number, newX: number, newY: number) => {
    const updatedPoints = [...controlPoints];
    updatedPoints[index * 2] = newX;
    updatedPoints[index * 2 + 1] = newY;
    setControlPoints(updatedPoints);

    const updatedLines: any[] = [];
    for (let i = 2; i < updatedPoints.length; i += 2) {
      updatedLines.push([
        updatedPoints[i - 2],
        updatedPoints[i - 1],
        updatedPoints[i],
        updatedPoints[i + 1],
      ]);
    }
    setLines(updatedLines);
  };

  return (
    <Stage
      width={width}
      height={height}
      onClick={handleStageClick}
      ref={stageRef}
    >
      <Layer>
        {image && <KonvasImage image={image} width={width} height={height} />}
        <Group draggable>
          {controlPoints.length >= 6 && (
            <Shape
              sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(controlPoints[0], controlPoints[1]);
                for (let i = 2; i < controlPoints.length; i += 2) {
                  context.lineTo(controlPoints[i], controlPoints[i + 1]);
                }
                context.closePath();
                context.fillStrokeShape(shape);
              }}
              fill={shape?.fill || "rgba(0, 255, 0, 0.2)"}
              stroke={shape?.stroke || "green"}
              strokeWidth={shape?.strokeWidth || 2}
            />
          )}
          {controlPoints.map((_, index) => {
            if (index % 2 === 0) {
              return (
                <Circle
                  key={index}
                  x={controlPoints[index]}
                  y={controlPoints[index + 1]}
                  radius={circle?.radius || 5}
                  fill={circle?.fill || "red"}
                  draggable
                  onDragMove={(event) => {
                    const newX = event.target.x();
                    const newY = event.target.y();
                    handlePointDragMove(index / 2, newX, newY);
                  }}
                />
              );
            }
            return null;
          })}
          {lines.map((line, index) => (
            <Line
              key={index}
              points={line}
              stroke={_line?.stroke || "yellow"}
              strokeWidth={_line?.strokeWidth || 2}
              lineCap="round"
              lineJoin="round"
            />
          ))}
        </Group>
      </Layer>
    </Stage>
  );
};


export default React.memo(BezierCurveCus, (prevProps, nextProps) => {
  return (
    prevProps.imageUrl === nextProps.imageUrl &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.shape === nextProps.shape &&
    prevProps.line === nextProps.line &&
    prevProps.circle === nextProps.circle
  );
});