import { useState } from 'react';
import { IDefaultSelection, IOption } from 'interface';


export default function DefaultSelection({ options, onOptionClick, className }: IDefaultSelection) {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const handleOptionClick = (index: number, option: IOption) => {
        setSelectedIndex(index);
        onOptionClick(option);
    };

    return (
        <div className={`bg-[#1F1F1F] rounded-[8px] border-gradient before: z-99 flex flex-col w-full text-white`}>
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`border-b border-[#1976d280] last-of-type:border-none z-999 w-full cursor-pointer ${className} 
                    ${selectedIndex == index ? 'bg-gradient-to-r from-[#7340D8] to-[#48D8E4] text-white' : ''}`}
                    onClick={() => handleOptionClick(index, option)}
                >
                    <button className=' select-none '>
                        {option.label ?? `${index}`}
                    </button>
                </div>
            ))}
        </div>
    );
}