import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SvgIcon from "assets/SvgIcon";
import useProject from "hooks/useProject";
import { ROUTES } from "./Sidebar/routes";
import Logo from '../assets/images/logo/Logo_text_horizontal.png';
import { ConnectStatus } from "./Status";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const { pathname } = useLocation();
  console.log("🚀 ~ Sidebar ~ pathname:", pathname);

  const trigger = useRef < any > (null);
  const sidebar = useRef < any > (null);

  let storedSidebarExpanded = "true";

  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (!sidebarOpen || key !== "Escape") return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      // Logo_text_horizontal
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  // create a new project
  const { onCreate } = useProject();

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden border border-[#3C3C3C] bg-inherit duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <a href="/" className="w-full flex justify-center">
          <img src={Logo}
            className="w-full"
            alt="Logo"
          />
        </a>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="flex flex-col overflow-y-auto duration-300 ease-linear no-scrollbar">
        {/* <!-- Sidebar Menu --> */}
        <nav className="px-4 lg:px-6">
          {/* <!-- Menu Group --> */}
          <div>
            {/* <h3 className="mb-4 ml-4 text-sm font-semibold text-white">MENU</h3> */}

            <ul className="mb-6 flex flex-col gap-1.5">
              <li onClick={(e) => { onCreate({ name: '' }) }}>
                <Link
                  to={ROUTES.ADMIN.PROJECT}
                  className={` group border-gradient relative flex items-center justify-center gap-2.5 rounded-lg bg-[#1F1F1F] px-4 py-3 my-2 font-medium text-white duration-300 ease-in-out hover:bg-slate-800 hover:text-white dark:hover:bg-meta-4 ${["/video"].includes(pathname) && "text-white dark:bg-meta-4"
                    }`}
                >
                  Create New Project
                </Link>
              </li>
              {/* <li>
                <Link
                  to={ROUTES.ADMIN.VIDEO}
                  className={` group border border-1 border-white  relative flex items-center gap-2.5 rounded-lg bg-[#1F1F1F] px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-slate-800 hover:text-white dark:hover:bg-meta-4 ${["/video"].includes(pathname) && "text-white dark:bg-meta-4"
                    }`}
                >
                  {SvgIcon.VideoMenu()}
                  Home
                </Link>
              </li> */}
              <li>
                <Link
                  to={ROUTES.ADMIN.PROJECT}
                  className={` group border-b-gradient relative flex items-center justify-center gap-2.5 bg-[#1F1F1F] px-4 py-3 my-2 font-medium text-white duration-300 ease-in-out hover:bg-slate-800 hover:text-white dark:hover:bg-meta-4 ${["/video"].includes(pathname) && "text-white dark:bg-meta-4"
                    }`}
                >
                  {SvgIcon.MyProjectIcon()}
                  My Project
                </Link>
              </li>
              {/* <!-- Menu Item Calendar --> */}
            </ul>



            {/* <ul className="mb-6 flex flex-col gap-1.5">
              <li>
                <Link
                  to={ROUTES.ADMIN.PROJECT}
                  className={` group  relative flex items-center gap-2.5 rounded-lg bg-[#1F1F1F] px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-slate-800 hover:text-white dark:hover:bg-meta-4 ${["/video"].includes(pathname) && "text-white dark:bg-meta-4"
                    }`}
                >
                  {SvgIcon.VideoMenu()}
                  Project
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.ADMIN.VIDEO}
                  className={` group  relative flex items-center gap-2.5 rounded-lg bg-[#1F1F1F] px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-slate-800 hover:text-white dark:hover:bg-meta-4 ${["/video"].includes(pathname) && "text-white dark:bg-meta-4"
                    }`}
                >
                  {SvgIcon.VideoMenu()}
                  Tools
                </Link>
              </li>
            </ul> */}

          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}


      </div>
      <div className="absolute bottom-0 w-full flex content-end items-end place-content-end px-6 py-5.5 lg:py-6.5">
        <ConnectStatus />
      </div>
    </aside>
  );
};

export default Sidebar;
