import React, { Dispatch, SetStateAction } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import SvgIcon from "assets/SvgIcon";
import { ArrFit } from "utils/constants";

interface IFitButton {
	stateFit: string;
	setStateFit: Dispatch<SetStateAction<string>>;
}

export default function FitButton({ stateFit, setStateFit }: IFitButton) {
	const onChangeFit = (e) => {
		setStateFit(e?.target?.value);
	};

	return (
		<div data-tour="step-2">
			<Box
				sx={{ minWidth: 74, maxHeight: 31 }}
				className="border-gradient text-white">
				<FormControl
					fullWidth
					sx={{
						boxShadow: "none",
						height: 31,
						".MuiOutlinedInput-notchedOutline": { border: 0, outline: "none" },
						".MuiInputBase-root": { height: "31px" },
					}}>
					<Select
						labelId="select-label"
						id="select-label"
						value={stateFit}
						onChange={onChangeFit}
						IconComponent={() => <SvgIcon.DownArrowIcon />}
						sx={{
							padding: "0 10px",
							boxShadow: "none",
							fontSize: "10px",
							".MuiOutlinedInput-notchedOutline": {
								border: 0,
								outline: "none",
							},

							svg: { color: "white", width: "20px" },
							color: "white",
							outline: "none",
						}}>
						{ArrFit.map((i, v) => (
							<MenuItem
								sx={{
									backgroundColor: "#1F1F1F",
									color: "#ffffff",
									fontSize: "10px",
								}}
								key={v}
								value={i}>
								{i}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{/* <BasicModal /> */}
		</div>
	);
}
