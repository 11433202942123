import React from 'react'

interface ILeftModal {
    children: React.ReactNode
    className?: string
}

export default function LeftModal({
    children,
    className
}: ILeftModal) {
    return (
        // <div className="w-[296px] h-full bg-[#383838] p-5 rounded-l-[8px] flex flex-col">
        <div className={` p-4 rounded-l-[8px] flex flex-col ${className}`}>
            {children}
        </div>
    )
}
