import { useEffect, useState } from 'react'
import { useCommon } from 'hooks/useCommon';

import { IDropdownFX } from 'interface';
import ValueStatusButton from './ValueStatusButton';
import SvgIcon from 'assets/SvgIcon';
import DefaultSelection from 'components/SelectGroup/DefaultSelection';
import { TypeExport } from 'types/video';

export default function DropdownFX({ onSelected, }: IDropdownFX) {
    
    const { onUpdateParams, expand } = useCommon();

    const options = [
        {
            label: `Video`,
            value: TypeExport.VIDEO,
        },
        {
            label: `Project`,
            value: TypeExport.PROJECT,
        },
        // {
        //     label: `Roto Shape`,
        //     value: TypeExport.SHAPE,
        // }
    ];

    const [hidden, setHidden] = useState<boolean>(true);

    // const [selectedOption, setSelectedOption] = useState<IDropdownFX["options"] | null>(
    //     options[0] || null
    // );

    const handleOptionClick = (option) => {
        console.log("🚀 ~ handleOptionClick ~ option:", option)
        onSelected(option.value);
        setHidden(!hidden)
    };

    useEffect(() => {
        if (expand) {
            setHidden(true);
            onUpdateParams({ expand: true });
        }

    }, [expand])

    return (
        <div className='relative w-200' onClick={() => setHidden(!hidden)}>
            <ValueStatusButton
                className={' p-2 w-200'}
                value={"Export"}
                textSize={'text-[12px]'}
                isStatus={hidden}
                icon={SvgIcon.Export()}
                statusIcons={[SvgIcon.DownArrowIcon(), SvgIcon.UpArrowIcon()]}
            />
            {!hidden ? (
                <div className="absolute -ml-[23px] w-[146px] mt-[0.5px]">
                    <DefaultSelection options={options} onOptionClick={handleOptionClick} className="pl-4 p-2 text-[13px] " />
                </div>
            ) : ''}
        </div>
    );
}
