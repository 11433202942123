import { useCommon } from 'hooks/useCommon';
import { useEffect, useRef, useState } from 'react'
import { EBrushMode } from 'types/RightSidebar/IBrushMode';

export default function InPainting({ width, height, image }: any) {
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState<any>();
    const [drag, setDrag] = useState<boolean>(false);
    const { brushSize, brushMode } = useCommon()

    useEffect(() => {
        const canva: any = canvasRef.current;
        const ctx = canva.getContext('2d');
        canva.width = width;
        canva.height = height;

        const img = new Image();
        img.src = image.url
        img.onload = function () {
            ctx.drawImage(img, 0, 0, width, height);
        };
        setCanvas(canva)
    }, [width, height, image]);

    const handleMouseDown = (e: any) => {
        console.log("🚀 ~ handleMouseDown ~ e:")
        setDrag(true)
    }

    const handleMouseMove = (e: any) => {
        if (drag) {
            if (!canvas) return
            const ctx = canvas.getContext('2d');
            console.log("🚀 ~ handleMouseMove ~ e:", e, brushMode)
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            if (brushMode === EBrushMode.EXCLUDE) {
                learCircle(ctx, x, y);
            } else {
                draw(ctx, x, y);
            }
        }
    }

    const handleMouseUp = (e: any) => {
        setDrag(false)
        console.log("🚀 ~ handleMouseUp ~ e:")
    }

    const learCircle = (ctx: any, x: number, y: number) => {
        const topLeftX = x - brushSize;
        const topLeftY = y - brushSize;
        const diameter = brushSize * 2;

        ctx.beginPath();
        ctx.arc(x, y, brushSize, 0, 2 * Math.PI);
        // ctx.clip();
        // ctx.closePath();

        ctx.clearRect(topLeftX, topLeftY, diameter, diameter);

    }

    const draw = (ctx: any, x: number, y: number) => {
        ctx.strokeStyle = "rgba(255, 0, 0, 0.5)";
        // ctx.fillStyle = `0px solid rgba(255, 0, 0, 0.5)`;
        ctx.beginPath();
        ctx.arc(x, y, brushSize, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();
    }

    return (
        <canvas
            ref={canvasRef}
            style={{ position: "absolute", zIndex: 999 }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        />
    )
}
