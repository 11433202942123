import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserStateType {
  isLogin: boolean;
  authInfo: {
    username: string;
    is_active: boolean;
    id: number;
    role: string;
  } | null;
}

const initialState: UserStateType = {
  isLogin: JSON.parse(localStorage.getItem("isLogin") as string) || false,
  authInfo: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogIn: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
      localStorage.setItem("isLogin", JSON.stringify(action.payload));
    },
    setAuthInfo: (state, action: PayloadAction<any>) => {
      state.authInfo = action.payload;
      localStorage.setItem("authInfo", JSON.stringify(action.payload));
    },
  },
});

export const { setLogIn, setAuthInfo } = userSlice.actions;

export default userSlice.reducer;
