import React, { useState } from "react";
import { ModalLayout, LeftModal, RightModal } from "components/modals";
import { IModalLayout } from "interface";
import SvgIcon from "assets/SvgIcon";
import { payment_api_data } from "utils/data/plans";
import { EPricingPlan, EnumPlanType } from "types/payment";
import CheckIcon from "assets/images/icon/check-icon.png";
import MoreIcon from "assets/images/icon/more-icon.png";
import { BuyMore } from "pages/payment/components/BuyMore";

export default function ModalManagePayment({
	open = true,
	handleClose = () => {},
	payNow = (t: number) => {},
	plan,
	period,
}: IModalLayout) {
	const [openPaypal, setOpenPaypal] = useState(false);
	const [ind, setInd] = useState(0);

	const renderPaymentMethods = () =>
		payment_methods?.map((method, index) => {
			const active = ind === index ? "primary" : "white";
			return (
				<div
					key={index}
					className="flex flex-col gap-2"
					onClick={() => setInd(index)}>
					<div className="cursor-pointer flex flex-row justify-start items-center gap-2 border border-[#3C3C3C] rounded-[8px] p-3">
						<div
							className={`w-4 h-4 flex justify-center items-center border-${active} rounded-full`}>
							<div
								className={`w-[10px] h-[10px] rounded-full bg-${active}`}></div>
						</div>
						{method.image_method}
						<div className="flex flex-col justify-center ">
							<span className="flex items-center h-4 font-bold text-[12px] text-white">
								{method?.method_name}
							</span>
						</div>
					</div>
				</div>
			);
		});

	const payment_methods = [
		// {
		// 	method_name: "Momo E-Wallet",
		// 	image_method: SvgIcon.MoMo(),
		// 	sub_title_method:
		// 		"When you continue, this payment method will be linked to help you pay faster",
		// },
		{
			method_name: "Paypal",
			image_method: SvgIcon.PayPal(),
			sub_title_method:
				"When you continue, this payment method will be linked to help you pay faster",
		},
	];

	const handleChoosePayment = () => {
		switch (ind) {
			case 0:
				payNow?.(ind);
				break;
		}
	};

	return (
		<>
			<ModalLayout open={open} className="w-[852px] h-[718px] bg-[#191A1E]">
				<div className="flex flex-row h-full w-[852px] h-[718px]">
					<LeftModal className="w-[348px] h-full bg-[#1F1F1F]">
						<button
							onClick={handleClose}
							className="flex flex-row justify-start content-center items-center gap-2 font-semibold text-[10px] text-[#B3B6B6] mb-4">
							{SvgIcon.BackIcon()} Back to all plans
						</button>
						<div className="h-full flex flex-col justify-between">
							<div className="flex flex-col gap-4 px-6">
								<div className="">
									<h1 className="font-bold text-[24px] text-white">
										{payment_api_data?.title_plan}
									</h1>
									<span className="font-semibold text-[14px] text-[#B3B6B6] ">{`Your new ${
										period === EPricingPlan.ANNUAL ? "yearly" : "monthly"
									} total`}</span>
								</div>
								<div className="flex flex-col gap-2">
									<p className="font-bold text-[28px] text-white">
										{period === EPricingPlan.ANNUAL
											? plan?.annualPrice
											: plan?.priceSale}
										<sub className="font-medium text-[16px] text-[#B3B6B6]">
											/{period === EPricingPlan.ANNUAL ? "year" : "month"}
										</sub>
									</p>
									<div className="flex flex-row justify-between font-semibold text-[12px]">
										<span className="text-[#B3B6B6]">Tax</span>
										<span className="text-white">
											{`$` + payment_api_data?.tax}
										</span>
									</div>
									<div className="w-full bg-white h-[1px]"></div>
									<div className="flex flex-row justify-between font-semibold text-[12px]">
										<span className="text-[#B3B6B6]">Total due today</span>
										<span className="text-white">
											{period === EPricingPlan.ANNUAL
												? plan?.annualPrice
												: plan?.priceSale}
										</span>
									</div>
								</div>
								<div className="">
									<div className="text-xl text-white font-bold mb-3">
										{plan?.packageName}
									</div>
									{plan?.description?.map((service: string, index: number) => {
										return (
											<div
												key={index}
												className="flex items-center justify-between mb-3 gap-1">
												<img src={CheckIcon} alt="" />
												<p className="text-xs text-white flex-1 font-semibold">
													{service}
												</p>
												{plan?.packageName !== EnumPlanType.ENTERPRISE && (
													<button>
														<img src={MoreIcon} alt="" />
													</button>
												)}
											</div>
										);
									})}
									{plan?.packageName === EnumPlanType.PREMIUM && (
										<BuyMore />
									)}
									{plan?.packageName === EnumPlanType.PRO && (
										<BuyMore />
									)}
								</div>
							</div>
							<p className="leading-normal font-semibold text-[10px] text-[#B3B6B6]">
								{payment_api_data?.content}
							</p>
						</div>
					</LeftModal>
					<RightModal className="w-full border-b">
						<h1 className="font-bold text-[24px] text-white mb-4">
							Manage Payment Method
						</h1>
						<div className="h-full flex flex-col justify-between">
							<div className="flex flex-col gap-3">
								<div className="">
									<span className="font-semibold text-[14px] text-white ">
										Add methods
									</span>
								</div>
								<div className="flex flex-col gap-2">
									{renderPaymentMethods()}
								</div>
							</div>
							<div className="w-full flex row justify-center">
								<button
									onClick={handleChoosePayment}
									className="border-gradient font-bold text-[#16] text-white px-8 py-2">
									Pay Now
								</button>
							</div>
						</div>
					</RightModal>
				</div>
			</ModalLayout>
		</>
	);
}
