import {
	FitButton,
	ReactProgressBar,
	TotalFrame,
} from "components/BottomContentBar";
import { useEffect, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import {
	TbPlayerTrackNextFilled,
	TbPlayerTrackPrevFilled,
} from "react-icons/tb";
import { useCommon } from "hooks/useCommon";
import { useTour } from "@reactour/tour";
import { IconButton } from "@mui/material";
import ScreenIcon from "assets/icons/ScreenIcon";
import { EModeFrame } from "types/ai";
import { useHotkeys } from "react-hotkeys-hook";

export default function BottomContentBar(props: any) {
	const {
		next,
		prev,
		play,
		curFrame,
		onChange,
		autoPlay,
		stateFit,
		setStateFit,
		isDisable,
		toggleShowFullScreen,
		modeFrame,
	} = props;
	const [btnPlay, setBtnPlay] = useState(false);
	const { nFrame } = useCommon();
	const { currentStep, isOpen } = useTour();

	useHotkeys("ArrowLeft, ArrowRight, Space", (e) => {
		switch (e.key) {
			case "ArrowRight":
				next();
				break;

			case "ArrowLeft":
				prev();
				break;

			case " ":
				play();
				break;
			default:
				break;
		}
	});

	useEffect(() => {
		setBtnPlay(autoPlay);
	}, [autoPlay]);

	return (
		<>
			<div
				className={`flex flex-row p-4 mt-5 text-white ${
					isDisable && "isDisable"
				}`}>
				<div
					data-tour="step-4"
					className={`w-2/5 ${
						currentStep === 3 && isOpen && "border-gradient px-3"
					}`}>
					<div className="flex self-center items-center text-[12px] gap-2">
						<TotalFrame
							total={nFrame?.total ?? 0}
							curFrame={curFrame}
							onChange={(s) => onChange(s)}
						/>

						<div className="w-[150px] px-1">
							<div className="">
								<ReactProgressBar cur={curFrame} total={nFrame?.total ?? 0} />
							</div>
						</div>
					</div>
				</div>
				<div
					className={`flex w-1/5 self-center justify-evenly  ${
						currentStep === 11 && isOpen && "border-gradient px-3"
					}`}
					data-tour="step-12">
					<button
						onClick={() => {
							prev();
						}}>
						<TbPlayerTrackPrevFilled />
					</button>
					<button
						onClick={() => {
							play();
						}}>
						{!btnPlay ? <FaPlay /> : <FaPause />}
					</button>
					<button
						onClick={() => {
							next();
						}}>
						<TbPlayerTrackNextFilled />
					</button>
				</div>
				<div className="flex w-2/5 self-center gap-2 justify-end">
					<IconButton onClick={toggleShowFullScreen}>
						<ScreenIcon />
					</IconButton>
					{modeFrame === EModeFrame.BSPLEDIT && (
						<FitButton stateFit={stateFit} setStateFit={setStateFit} />
					)}
				</div>
			</div>
		</>
	);
}
