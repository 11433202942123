import { Images } from "assets/images/Images";
import useWindowDimensions from "hooks/useWindowDimensions";

const loaderImages = {
  loading: Images.Loading,
  await: Images.LoadingAwait,
  roto: Images.LoadingRoto,
};


const Loader = ({ bg = "bg-dark", type = "await" }) => {
  const { height } = useWindowDimensions()
  const loaderImage = loaderImages[type] || Images.Loading;
  return (
    <div className="absolute" style={{ zIndex: 10000000, height: height - 300 }}>
      <div
        className={`flex items-center justify-center ${bg} dark:bg-black`}
        style={{ height: height - 300 }}
      >
        <img src={loaderImage} alt="loading" className="w-[180px] h-[180px]" />

      </div>
    </div>
  );
};

export default Loader;

{/* <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div> */ }