import React, { useCallback, useEffect } from "react";
import SvgIcon from "assets/SvgIcon";
import { GradientBorderButton } from "components/Buttons";
import { TypeAiReady } from "interface";
import { useCommon } from "hooks/useCommon";
import axiosClient from "utils/axios";
import { ROUTES_API } from "utils/constants";

export default function ConnectStatus() {
	const { socket, profile, statusReady, onUpdateParams } = useCommon();

	const onGetReadyAi = useCallback(async (cb?: () => void) => {
		onUpdateParams({ statusReady: TypeAiReady.CHECKING });
		try {
			const rs: any = await axiosClient
				.get(ROUTES_API.USER.CHECK_READY_AI)
				.catch(() => {
					onUpdateParams({ statusReady: TypeAiReady.NOT_READY });
				});
			const { statusCode, message } = rs;
			if (statusCode === 200 && message === TypeAiReady.READY) {
				onUpdateParams({ statusReady: TypeAiReady.READY });
				return message;
			}
			return null;
		} catch (err) {
			onUpdateParams({ statusReady: TypeAiReady.NOT_READY });
			return null;
		}
	}, []);

	useEffect(() => {
		onGetReadyAi();
	}, []);

	useEffect(() => {
		if (socket && profile?.id) {
			socket?.on(`handleStatus-${profile?.id}`, (dataSocket) => {
				const { status, message } = JSON.parse(JSON.stringify(dataSocket));
				if (status === 200 && message === TypeAiReady.READY) {
					onUpdateParams({ statusReady: TypeAiReady.READY });
				}
			});
		}
	}, [socket, profile?.id]);

	const renderContent = useCallback(() => {
		switch (statusReady) {
			case TypeAiReady.READY:
				return (
					<div className="flex flex-row flex-nowrap content-center items-center gap-2 text-white">
						<span>{SvgIcon.ConnectIcon()}</span>
						<span>Connected</span>
					</div>
				);
			case TypeAiReady.NOT_READY:
				return (
					<div className="flex flex-row flex-nowrap content-center items-center gap-2 text-white">
						<span>{SvgIcon.DisConnectIcon()}</span>
						<span>Disconnected</span>
					</div>
				);
			case TypeAiReady.CHECKING:
				return (
					<div className="flex flex-row flex-nowrap content-center items-center gap-2 text-white">
						<span>{SvgIcon.CheckingIcon()}</span>
						<span>Checking...</span>
					</div>
				);
			default:
				console.error("Unexpected connection state:", statusReady);
				return null; // Or render a fallback message
		}
	}, [statusReady]);

	return (
		<div className="w-full flex flex-col items-center content-center gap-3">
			{renderContent()}
			<GradientBorderButton
				onClick={onGetReadyAi}
				firstIcon={SvgIcon.CheckIcon()}
				text="Check Status"
			/>
		</div>
	);
}
