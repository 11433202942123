import {
	dispatchActions,
	useUserDispatch,
	useUserState,
} from "context/UserContext";
import { IUserActionsType } from "types/auth";
import { useCallback, useState } from "react";
import { useAlert } from "./useAlert";
import { ROUTES_API } from "utils/constants";
import axiosClient from "utils/axios";

export function useCommon() {
	const {
		profile = {},
		avatar = "",
		brushSize = 50,
		nFrame = null,
		socket = null,
		images = null,
		currentFrame = 1,
		startSequence = false,
		paused = false,
		expand = true,
		brushMode = null,
		anti_alias_level = 50,
		blur_ratio = 50,
		dilate_ratio = 0,
		erode_ratio = 0,
		exportOption = "",
		nodeEnv = "development",
		nameEvent = {},
		statusReady = "",
		totalFrame = 0,
		textWarnExport = '',
		openModalSetting = false
	} = useUserState() || {};
	const { error } = useAlert();
	const [loading, setLoading] = useState(false);
	const userDispatch = useUserDispatch();

	const onUpdateParams = useCallback(
		(payload: any) => {
			console.log("🚀 ~ useCommon ~ payload:", payload);
			if (Object.keys(payload)?.[0] === 'expand' && !window?.location?.pathname?.includes('project-detail')) {
				return;
			}
			dispatchActions(
				{ type: IUserActionsType.UPDATE_PARAMS, payload },
				userDispatch,
			);
		},
		[userDispatch],
	);

	const onSplitFrame = async (
		file: File,
		code: string,
		callback?: (a: any) => void,
	) => {
		try {
			if (!file) {
				error("Field is required");
				return;
			}
			setLoading(true);
			const formData: any = new FormData();
			formData.append("file", file);
			formData.append("code", code);
			setLoading(false);
			const res: any = await axiosClient.post(
				ROUTES_API.MEDIA.SPLIT_FRAME,
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				},
			);

			console.log("Response On Upload =====================", res);

			if (res?.statusCode === 200) {
				onUpdateParams({ totalFrame: res?.data?.totalFrames });
				callback?.(res?.data);
			}
		} catch (err) {
			error("Server error!");
			setLoading(false);
			return callback?.(null);
		}
	};

	return {
		/* ----change data CONTEXT---- */
		onUpdateParams,
		profile,
		avt: avatar,
		loading,
		anti_alias_level,
		blur_ratio,
		dilate_ratio,
		erode_ratio,
		onSplitFrame,
		nFrame,
		socket,
		startSequence,
		images,
		currentFrame,
		paused,
		expand,
		nodeEnv,
		nameEvent,
		brushSize,
		brushMode,
		exportOption,
		statusReady,
		totalFrame,
		textWarnExport,
		openModalSetting
	};
}
