import { toHHMMSS } from "utils/helper";
import React, { useRef, useEffect } from "react";

const Ruler = ({ width, height, images, duration }: any) => {
	const canvasRef = useRef(null);

	const listFrameShow = images.slice(0, Math.ceil(duration / 100));

	useEffect(() => {
		const canvas: any = canvasRef.current;
		if (!canvas) return;

		const loadImage = (url: string) =>
			new Promise((resolve, reject) => {
				const img = new Image();
				img.addEventListener("load", () => resolve(img));
				img.addEventListener("error", (err) => reject(err));
				img.src = url;
			});
		const context = canvas.getContext("2d");

		// Set font
		const fontSize = 14;
		const fontFamily = "Satoshi";
		context.font = `${fontSize}px ${fontFamily} bold`;

		// Draw ruler
		context.beginPath();
		context.moveTo(0, height / 2);
		context.lineTo(width, height / 2);
		context.strokeStyle = "#fff"; // Set line color

		// Draw tick marks
		for (let i = 0; i <= width; i += 10) {
			const isNode = i % 100 === 0;
			context.fillStyle = "#fff";
			isNode && context.fillText(toHHMMSS(`${i / 100}`, true), i, 32);
			context.lineWidth = isNode ? 2 : 1; // Set line width
			context.beginPath();
			const h = height / (isNode ? 3 : 6);
			context.moveTo(i, height / 2 - 15);
			context.lineTo(i, h + 2);
			context.stroke();
		}

		images?.length &&
			listFrameShow.forEach((img: any, j: number) => {
				loadImage(img.url).then((imgs) =>
					context.drawImage(
						imgs,
						j * (duration / listFrameShow?.length),
						40,
						duration / listFrameShow?.length,
						62,
					),
				);
			});
	}, [listFrameShow?.length, duration]);

	return <canvas ref={canvasRef} width={width} height={height + 90} />;
};

export default Ruler;
