import useWindowDimensions from "hooks/useWindowDimensions";
import BrushMode from "./BrushMode";
import RangeSliderFX from "./RangeSliderFX";

import SvgIcon from "assets/SvgIcon";
import SubmitButton from "./SubmitButton";
import Reset from "./Reset";
import { useCommon } from "hooks/useCommon";
import { BrushModeProps, SubmitButtonProps } from "utils/constants";
import { useTour } from "@reactour/tour";
import { FaPause, FaPlay } from "react-icons/fa";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { TypeReset } from "types/RightSidebar/IBrushMode";
import React, { useCallback, useEffect, useState } from "react";
import ModalCustomRoto from "components/modals/ModalCutomRoto";
import StandaloneButton from "./StandaloneButton";
import { RemovalObject } from "./RemovalObject";

export default function RightSidebar({
	onChangeReset,
	autoPlay,
	onSaveAll,
	isDisable,
	loadingSequence,
	handleStopSequence,
	setIsChanged,
	curFrame,
	setCurFrame,
	setOpenCustomRoto,
	isDisableSam,
	maxLength,
	setModeFrame,
	totalFrame,
	onRemoveObject,
	setVisModeRemove,
}: any) {
	const { height } = useWindowDimensions();
	const { currentStep, isOpen } = useTour();
	const [rsFrame, setRsFrame] = useState("");
	const {
		onUpdateParams,
		anti_alias_level,
		blur_ratio,
		dilate_ratio,
		erode_ratio,
		startSequence,
		socket,
	} = useCommon();

	const onResetFrame = (e) => {
		console.log("🚀 ~ onResetFrame ~ e:", e.target.value);
		setRsFrame(e.target.value);
		onChangeReset?.(e.target.value);
		setTimeout(() => setRsFrame(""), 300);
	};

	return (
		<div className="w-[250px] h-full pr-2 py-5">
			<div
				className="border border-[#3c3c3c] rounded-lg bg-[rgb(31,31,31)] lg:px-4 px-2 flex flex-col content-between place-content-between overflow-y-scroll scrollbar-hide "
				style={{ height: height - 190 }}>
				<div className={isDisable || isDisableSam ? "isDisable" : undefined}>
					<div
						className={` ${
							currentStep === 7 && isOpen && "border-gradient px-3"
						}`}
						data-tour="step-8">
						<div className="flex w-full pt-5" data-tour="step-7">
							<div className="flex self-center">
								<h1 className="font-medium text-white text-[16px] text-left">
									{" "}
									Reset{" "}
								</h1>
							</div>
						</div>

						{/* <Reset>
							<SubmitButton
								text="Reset ObjectID"
								onClick={() => !autoPlay && onChangeReset?.()}
							/>
						</Reset>

						<Reset>
							<SubmitButton
								text="Reset All ObjectID"
								onClick={() => !autoPlay && onAllChangeReset?.()}
							/>
						</Reset> */}
						<div
							data-tour="step-2"
							className={`py-4 ${
								currentStep === 1 && isOpen && "border-gradient px-3"
							}`}>
							<Box
								sx={{ minWidth: 120 }}
								className="border-gradient text-white">
								<FormControl
									fullWidth
									sx={{
										boxShadow: "none",
										".MuiOutlinedInput-notchedOutline": { border: 0 },
									}}>
									<Select
										disabled={autoPlay}
										defaultValue={rsFrame}
										value={rsFrame}
										displayEmpty
										onChange={onResetFrame}
										IconComponent={() => <SvgIcon.DownArrowIcon />}
										sx={{
											padding: "0 10px",
											boxShadow: "none",
											".MuiOutlinedInput-notchedOutline": { border: 0 },
											svg: { color: "white", width: "15px" },
											color: "white",
										}}>
										<MenuItem sx={{ color: "#ffffff" }} value="">
											{"Choose option"}
										</MenuItem>
										{Object.entries(TypeReset).map(([k, v]) =>
											v ? (
												<MenuItem
													sx={{
														color: "#ffffff",
													}}
													key={k}
													value={k}>
													{v}
												</MenuItem>
											) : null,
										)}
									</Select>
								</FormControl>
							</Box>
						</div>
					</div>

					<div
						className={`${
							currentStep === 8 && isOpen && "border-gradient px-3"
						}`}
						data-tour="step-9">
						{/* <div className="flex w-full pt-5">
							<div className="flex self-center">
								 <h1 className="font-medium text-white text-[16px] text-left">
									{" "}
									Inpainting{" "}
								</h1> 
							</div>
						</div> */}

						{/* <BrushMode title={BrushModeProps.title} /> */}
					</div>

					{/* <BrushMode title={BrushModeProps.title} /> */}

					<div
						className={`pb-4 ${
							currentStep === 9 && isOpen && "border-gradient px-3"
						}`}
						data-tour="step-10">
						{/* {RangeSliderFXProps.map((e, k) => (
              <RangeSliderFX title={e.title} defaultValue={e.defaultValue} key={k} min={e.min} max={e.max} />
              // brushSize, antiAlias, blurRadio, erodeDilate
              blur_ratio
anti_alias_level
erode_ratio
dilate_ratio
            ))} */}

						{/* <RangeSliderFX onUpdate={v => { onUpdateParams({ brushSize: v }) }} title={'Alpha Ratio'} defaultValue={50} min={0} max={100} /> */}
						<RangeSliderFX
							onUpdate={(v) => {
								onUpdateParams({ anti_alias_level: v });
							}}
							title={"Anti-alias"}
							defaultValue={0}
							min={0}
							max={20}
						/>
						<RangeSliderFX
							onUpdate={(v) => {
								onUpdateParams({ blur_ratio: v });
							}}
							title={"Blur Radio"}
							defaultValue={0}
							min={0}
							max={100}
						/>
						<RangeSliderFX
							onUpdate={(v) => {
								onUpdateParams({
									erode_ratio: v < 0 ? Math.abs(v) : 0,
									dilate_ratio: v > 0 ? v : 0,
								});
							}}
							title={"Erode/Dilate"}
							defaultValue={0}
							min={-100}
							max={100}
						/>
						<SubmitButton
							onClick={(f: boolean) => {
								console.log("🚀 ~ RightSidebar ~ f:", f);
								onSaveAll?.({
									anti_alias_level,
									blur_ratio,
									dilate_ratio,
									erode_ratio,
								});
							}}
							icon={SvgIcon.SaveIcon({ width: 12, height: 12 })}
							className="mt-10"
							text={"Save All"}
						/>
						{/* <RemovalObject
							maxLength={maxLength}
							setModeFrame={(v) => {
								setVisModeRemove?.();
								setModeFrame(v);
							}}
							onSubmit={onRemoveObject}
							setCurFrame={setCurFrame}
						/> */}
					</div>
				</div>

				<div
					className={`pb-2 ${isDisableSam ? "isDisable" : ""}  ${
						currentStep === 10 && isOpen && "border-gradient px-3"
					}`}
					data-tour="step-11">
					<SubmitButton
						className={
							loadingSequence
								? "bg-gradient-to-r from-[#7340d88c] #5272ff71 to-[#48d7e49a] rounded-md"
								: undefined
						}
						icon={loadingSequence ? <FaPause /> : <FaPlay />}
						text={
							loadingSequence
								? SubmitButtonProps.pauseText
								: SubmitButtonProps.text
						}
						onClick={(f: boolean) => {
							if (startSequence) {
								return handleStopSequence();
							}
							setOpenCustomRoto(true);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
