import { Dispatch, SetStateAction } from "react";
import { EPricingPlan, EnumPlanType, PackagePlan } from "types/payment";
import { TypeExport } from "types/video";

export interface ResponseType {
	code?: string;
	data?: any;
	message?: string;
}

export interface AccountType {
	id: number;
	user_id: number;
	email: string;
	type: string;
	access_token: string;
	access_token_expire_at: string;
	is_active: boolean;
}

export interface ApplicationType {
	appId: string;
	title: string;
	description: string;
	descriptionHTML?: string;
	summary: string;
	installs: number;
	minInstalls: number;
	maxInstalls: number;
	score: number;
	scoreText: string;
	ratings?: number;
	price: number;
	free: boolean;
	currency: string;
	priceText: string;
	available: boolean;
	androidVersion: string;
	androidVersionText: string;
	developer: {
		devId: string;
		url: string;
	};
	developerId: string;
	developerEmail: string;
	genre: string;
	genreId: string;
	icon: string;
	headerImage: string;
	screenshots?: string[];
	videoImage?: string;
	contentRating: string;
	released: string;
	updated: number;
	version: string;
	comments: string[];
	playstoreUrl: string;
}

export interface ReviewType {
	id: string;
	userName: string;
	userImage: string;
	date: string;
	score: number;
	scoreText: number;
	url: string;
	title: string | null;
	text: string;
	replyDate?: string;
	replyText?: string;
	version: string;
	thumbsUp?: number;
}

export interface TemplateType {}

export interface AIAnswerType {
	user_review: string;
	language: string;
	classify_review: string;
	answer_review: string[];
}

export interface IOption {
	label: string | any;
	value: any;
}

export interface IDefaultSelection {
	options: any[];
	onOptionClick: (option: IOption) => void;
	className?: string;
}

export interface IDropdownFX {
	onSelected: (s: TypeExport) => void;
	options?: IOption[];
}

export enum EExport {
	VIDEO = "VIDEO",
	PROJECT = "PROJECT",
	NUKE = "NUKE",
}

export interface IValueStatusButtonProps {
	icon?: React.ReactNode;
	value: string | number;
	statusIcons?: [React.ReactNode, React.ReactNode] | null;
	isActive?: boolean;
	isStatus?: boolean;
	onClick?: () => void; // New prop for handling click
	className?: string;
	textColor?: string;
	textSize?: string;
	textFont?: string;
}

export enum TypeAiReady {
	READY = "READY",
	NOT_READY = "NOT_READY",
	CHECKING = "CHECKING",
}

export interface ISelectProps {
	values: any[];
	selected: any;
	disabled: boolean;
	group?: boolean;
	onSelected?: (a: string) => void;
}

export interface ICheckbox {
	value?: string | number;
	content?: string;
	checked?: boolean;
}

export interface IModalLayout {
	children?: React.ReactNode;
	className?: string;
	headerTitle?: string;
	footerModal?: React.ReactNode;
	open: boolean;
	toggleModal?: () => void;
	handleClose?: () => void;
	payNow?: (t: number) => void;
	plan?: PackagePlan;
	period?: EPricingPlan
}
