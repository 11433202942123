import { useRemovalObject } from "hooks/useRemovalObject";
import React, { useCallback, useEffect, useState } from "react";
import { Stage, Layer, Rect, Image as KonvaImage } from "react-konva";
import { toast } from "react-toastify";
import useImage from "use-image";

const MIN_SIZE = 300;
const MAX_SIZE = 1000;

const SelectionObject = ({
	imageUrl,
	width,
	height,
	rateScaleWidth,
	rateScaleHeight,
}) => {
	const { disabledRm, onUpdateRectPos, hideSelection, onHideRectSelection } =
		useRemovalObject();
	const [image] = useImage(imageUrl);
	const [isSelecting, setIsSelecting] = useState(false);
	const [selection, setSelection] = useState({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});
	const [startPos, setStartPos] = useState({ x: 0, y: 0 });

	useEffect(() => {
		if (selection.x && !isSelecting) {
			let newWidth = selection.width;
			let newHeight = selection.height;

			if (newWidth < MIN_SIZE || newHeight < MIN_SIZE) {
				toast.warn("The box crop is too small please crop again !!!", {
					position: "top-center",
					toastId: JSON.stringify(
						"The box crop is too small please crop again !!!",
					),
				});
				setSelection({
					x: 0,
					y: 0,
					width: 0,
					height: 0,
				});
				return;
			}
			onUpdateRectPos({
				xStart: selection.x / rateScaleWidth,
				yStart: selection.y / rateScaleHeight,
				xEnd: (selection.x + newWidth) / rateScaleWidth,
				yEnd: (selection.y + newHeight) / rateScaleHeight,
			});
		}
	}, [isSelecting, selection]);

	const handleMouseDown = useCallback(
		(e) => {
			if (disabledRm) {
				return;
			}
			const { x, y } = e.target.getStage().getPointerPosition();
			setStartPos({ x, y });
			setSelection({ x, y, width: 0, height: 0 });
			setIsSelecting(true);
		},
		[disabledRm],
	);

	const handleMouseMove = useCallback(
		(e: any) => {
			if (!isSelecting || disabledRm) return;

			const { x, y } = e.target.getStage().getPointerPosition();
			let newWidth = x - startPos.x;
			let newHeight = y - startPos.y;
			// Apply constraints
			newWidth = Math.min(newWidth, MAX_SIZE);
			newHeight = Math.min(newHeight, MAX_SIZE);

			newWidth = newWidth < 0 ? 0 : newWidth;
			newHeight = newHeight < 0 ? 0 : newHeight;

			setSelection({
				x: startPos.x,
				y: startPos.y,
				width: newWidth,
				height: newHeight,
			});
		},
		[disabledRm, isSelecting],
	);

	const handleMouseUp = (e) => {
		setIsSelecting(false);
		onHideRectSelection(true);
	};

	return (
		<Stage
			width={width}
			height={height}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}>
			<Layer>
				{image && <KonvaImage image={image} width={width} height={height} />}
				{hideSelection && (
					<Rect
						x={selection.x}
						y={selection.y}
						width={selection.width}
						height={selection.height}
						fill="rgba(217,217,217,0.5)"
						stroke="#D9D9D9"
					/>
				)}
			</Layer>
		</Stage>
	);
};

export default SelectionObject;
