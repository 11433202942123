import React from "react";
import Close from "assets/images/icon/icon-close.png";
import Next from "assets/images/icon/chevron-right.png";
import Previous from "assets/images/icon/chevron-left.png";

interface ITourItemProps {
  title?: string;
  onClose?: () => void;
  onNext?: any;
  content?: string;
  btnBackTitle?: string;
  btnNextTitle?: string;
  onBack?: () => void;
}
export const TourItem: React.FC<ITourItemProps> = ({
  title = "",
  onClose,
  onNext,
  onBack,
  content,
  btnNextTitle,
  btnBackTitle,
}) => {
  return (
    <div className="bg-[#191A1E] p-4 w-[329px] rounded-md">
      <div className="flex flex-row justify-between w-full">
        {title && <p className="font-semibold text-xl bg-gradient-to-r from-purple-600 via-blue-500 to-cyan-400 bg-clip-text text-transparent">{title}</p>}

        <button onClick={onClose} className="w-6 h-6">
          <img src={Close} alt="Close" className="w-6 h-6" />
        </button>
      </div>
      <p className="text-white text-base font-semibold">{content}</p>
      <div
        className={`mt-4 flex flew-row ${!btnBackTitle ? "justify-end" : "justify-between"
          }`}
      >
        {btnBackTitle && (
          <button
            onClick={onBack}
            className="bg-border-gradient rounded-md text-white text-sm h-10 border-[1px] py-3 pl-5 pr-4 flex items-center"
          >
            <img src={Previous} alt="Previous" />
            {btnBackTitle}
          </button>
        )}
        {btnNextTitle && (
          <button
            className="bg-border-gradient rounded-md text-white text-sm h-10 border-[1px] py-3 pl-5 pr-4 flex items-center"
            onClick={onNext}
          >
            {btnNextTitle}
            <img src={Next} alt="next" />
          </button>
        )}
      </div>
    </div>
  );
};
