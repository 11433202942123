import { useCallback, useEffect, useState } from "react";
import useProject from "hooks/useProject";
import { useParams } from "react-router-dom";
import SvgIcon from "assets/SvgIcon";
import InputWithEnter from "./InputEnter";
import { useAlert } from "hooks/useAlert";

const RenameProject = ({ projectName }: any) => {
	const { onUpdate } = useProject();
	const { plug: code } = useParams<{ plug: string }>();
	const [isEditable, setIsEditable] = useState(false);
	const [name, setName] = useState<string>(projectName ?? "");

	const { warn, success } = useAlert();

	const onReset = () => setIsEditable(false);

	const onUpdateProject = useCallback(
		async (v) => {
			if (!code) {
				return;
			}
			if (!v) {
				warn("Please enter a project name");
				return;
			}
			await onUpdate(
				{
					name: v,
					code: code,
				},
				() => {
                    success('Project name has been updated!')
					onReset();
					setName(v);
				},
			);
		},
		[],
	);
	return (
		<div className="flex flex-row items-center relative ml-2">
			{isEditable ? (
				<InputWithEnter
					value={projectName}
					onEnter={(v) => onUpdateProject(v)}
					onEsc={onReset}
				/>
			) : (
				<div className="text-white">{name ?? ""}</div>
			)}

			<button
				type="button"
				className="focus:outline-none ml-2"
				onClick={() => setIsEditable(true)}>
				{SvgIcon.PencilEditIcon()}
			</button>
		</div>
	);
};
export default RenameProject;
