import { Box, Modal, Portal, Typography } from '@mui/material'
import { GradientBorderButton } from 'components/Buttons'
import useWindowDimensions from 'hooks/useWindowDimensions'
import SvgIcon from 'assets/SvgIcon';
import { TModalType } from 'types/payment';
import { useCommon } from 'hooks/useCommon';

interface IProps {
    type: TModalType,
    open: boolean,
    data?: any,
    handleClose: () => void
}

export default function ModalPayment({ type, open = false, handleClose = () => { }, data }: IProps) {
    const { width, height } = useWindowDimensions()
	const { profile } = useCommon();
	console.log("🚀 ~ ModalPayment ~ profile:", profile)

    const labelType = {
        success: "Payment Successful",
        warn: "Payment Unsuccessful"
    }

    const renderWarningContent = () => (
        <>
            <Typography variant="body1" component="p" color={'white'} sx={{ marginTop: 2 }}>
                Sorry for any inconvenience caused.
            </Typography>
            <Typography variant="body2" component="p" color={'orange'}>
                Error message: Transaction has been cancel
            </Typography>
        </>
    )

    const payload = {
        invoiceId: 'Invoice ID',
        startDate: 'Date',
        byUser: 'By user',
        amountSpent: 'Amount spent',
    }

    const renderSuccessContent = () => (
        <>
            <Box sx={[styles.row, styles.bg1F]} mt={5} padding={2}>
                <Box flexDirection={'column'} justifyContent={'center'}>
                    <Typography variant="body1" component="h6" sx={{ color: 'white' }}>
                        Total balance
                    </Typography>
                </Box>
                <Box flex={1} />
                <Box flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant="h5" component="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                        {data?.totalCredit}
                    </Typography>
                </Box>
            </Box>
            <Box sx={[styles.col, styles.bg1F]} marginTop={3} py={2}>
                {Object.entries(payload).map(([k, v]) => (
                    <Box key={k} sx={[styles.row, styles.bg1F]}  boxSizing={'border-box'} px={2} py={1}>
                        <Box flexDirection={'column'} justifyContent={'center'}>
                            <Typography variant="body1" component="h6" sx={{ color: 'white' }}>
                                {v}
                            </Typography>
                        </Box>
                        <Box flex={1} />
                        <Box flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant="body1" component="h6" color={'white'}>
                                {k === 'byUser' ? profile?.userName : data?.[k]}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    )

    return (
        <>
            <Portal>
                <Modal
                    open={open}
                    onClose={handleClose}
                    slotProps={{
                        backdrop: { sx: styles.backdrop }
                    }}
                >
                    <Box sx={{ ...styles.wrapContent, width, height }}>
                        <Box sx={styles.content}>
                            {type !== 'success' && <Box />}
                            <Box sx={styles.col}>
                                <Typography variant="h5" component="h6" sx={{ fontWeight: 'bold', color: 'white', marginBottom: 2 }}>
                                    {labelType[type]}
                                </Typography>
                                {type === 'success' ? SvgIcon.CheckSuccess() : SvgIcon.Warning()}
                                {type === 'success' ? renderSuccessContent() : renderWarningContent()}
                            </Box>
                            <GradientBorderButton
                                onClick={() => handleClose()}
                                textColor={'text-white font-bold'}
                                text="Back to plan"
                            />
                        </Box>
                    </Box>
                </Modal>
            </Portal>
        </>
    )
}

const styles = {
    bg: {
        top: '50%',
        left: '50%',
        backgroundColor: '#fff',
        transform: 'translate(-50%, -50%)',
    },
    wrapContent: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    content: {
        backgroundColor: '#000',
        borderRadius: 2,
        minWidth: 504, minHeight: 710, padding: 5,
        justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', display: 'flex'
    },
    col: { justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex' },
    backdrop: {
        backgroundColor: 'rgb(0 0 0 / 50%)',
        position: 'absolute',
    },
    cenItem: {
    },
    row: { justifyContent: "space-between", alignItems: 'center', flexDirection: 'row', display: 'flex', width: 450 },
    bg1F: {
        backgroundColor: '#1f1f1f',
        borderRadius: 2
    }
};