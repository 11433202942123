import { useRef, useState } from "react";
import { useAlert } from "./useAlert";
import axiosClient from "utils/axios";
import {
	QueryParams,
	ROUTES_API,
	TImportKeys,
	TQueryDetailParams,
	TQueryParams,
	TUpdateProjectParams,
} from "utils/constants";
import { TCreateProject } from "types/project";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "components/Sidebar/routes";
import { useTour } from "@reactour/tour";
import { TypeAiReady } from "interface";
import { useCommon } from "./useCommon";

export default function useProject() {
	const prevRef = useRef<any>({
		inprogress: false,
		interval: null,
		count: 0,
		images: [],
	}).current;
	const { error, success } = useAlert();
	const [loading, setLoading] = useState(false);
	const router = useNavigate();
	const { setIsOpen } = useTour();
	const { info } = useAlert();
	const { statusReady, onUpdateParams } = useCommon();

	const onCreate = async (input: TCreateProject) => {
		if (statusReady !== TypeAiReady.READY) {
			return info("AI Initializing, please wait");
		}
		try {
			setLoading(true);
			const rs: any = await axiosClient
				.post(ROUTES_API.PROJECT.CREATE, input)
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onCreate ~ rs:", rs);
			setLoading(false);
			const { statusCode, data, message } = rs;
			console.log("🚀 ~ onCreate ~ data:", data);
			if (statusCode === 200) {
				const { code } = data;
				// success("Create successfully!");
				if (code) {
					router(`${ROUTES.ADMIN.PROJECT_DETAIL}/${code}`);
					setIsOpen?.(true);
				}
				return;
			}
			router(`${ROUTES.ADMIN.PROJECT}`);
			error(`Err: ${message}`);
		} catch (err) {
			setLoading(false);
		}
	};

	const onGetList = async (
		params: TQueryParams | null = QueryParams,
		callback?: (a: any) => void,
	) => {
		try {
			setLoading(true);
			const rs: any = await axiosClient
				.get(ROUTES_API.PROJECT.CREATE, { params })
				.catch(() => {
					setLoading(false);
				});
			setLoading(false);
			const { statusCode, data } = rs;
			if (statusCode === 200) {
				callback?.(rs);
			}
		} catch (err) {
			callback?.(null);
			setLoading(false);
		}
	};

	const onGetDetail = async (
		params: TQueryDetailParams | null,
		callback?: (a: any) => void,
		callback2?: (a: any) => void,
		totalFrame?: number,
	) => {
		try {
			prevRef.count = 0;
			prevRef.inprogress = true;
			setLoading(true);
			const rs: any = await axiosClient
				.get(ROUTES_API.PROJECT.DETAIL, {
					params: {
						...params,
						page: 1,
						limit: totalFrame === 0 ? 10 : totalFrame,
					},
				})
				.catch(() => {
					setLoading(false);
				});
			setLoading(false);
			const { statusCode, data } = rs;

			if (statusCode === 200 && data?.listFrame) {
				onUpdateParams({ totalFrame: data?.totalFrames });
				callback?.(data);
				callback2?.(data?.listFrame);
			}

			// if (statusCode === 200) {
			// 	callback?.(data);
			// 	let { total } = data.pagination;
			// 	prevRef.interval = setInterval(async () => {
			// 		if (prevRef.count >= 3) {
			// 			prevRef.interval && clearInterval(prevRef.interval);

			// 			return;
			// 		}
			// 		const result: any = await axiosClient
			// 			.get(ROUTES_API.PROJECT.DETAIL, {
			// 				params: { ...params, page: 1, limit: total ?? 10 },
			// 			})
			// 			.catch(() => {
			// 				// setLoading(false);
			// 			});
			// 		const r = result.data;
			// 		console.log(
			// 			"🚀 ~ prevRef.interval=setInterval ~ prevRef.count:",
			// 			prevRef.count,
			// 		);

			// 		r?.listFrame && callback2?.(r?.listFrame);
			// 		prevRef.count++;
			// 	}, 5000);
			// }
			prevRef.inprogress = false;
		} catch (err) {
			prevRef.count = 0;
			callback?.(null);
			setLoading(false);
		}
	};

	const onPlaySequence = async (
		params: TQueryDetailParams | null,
		callback?: (a: any) => void,
	) => {
		console.log("🚀 ~ onPlaySequence ~ params:", params);
		try {
			// if (!prevRef.inprogress) return;
			setLoading(true);
			const rs: any = await axiosClient
				.get(ROUTES_API.PROJECT.PLAY_SEQUENCE, { params })
				.catch(() => {
					setLoading(false);
				});
			setLoading(false);
			const { statusCode, data } = rs;

			console.log("🚀 ~ onPlaySequence ~ data:", data);
			if (statusCode === 200) {
				callback?.(data);
			}
		} catch (err) {
			callback?.(null);
			setLoading(false);
		}
	};

	const onUpdate = async (
		input: TUpdateProjectParams,
		callback?: (a: any) => void,
	) => {
		console.log("🚀 ~ onUpdate ~ params:", input);
		try {
			// if (!prevRef.inprogress) return;
			setLoading(true);
			const rs: any = await axiosClient
				.put(ROUTES_API.PROJECT.CREATE, input)
				.catch(() => {
					setLoading(false);
				});
			setLoading(false);
			const { statusCode, data } = rs;

			console.log("🚀 ~ onUpdate ~ data:", data);
			if (statusCode === 200) {
				callback?.(data);
			}
		} catch (err) {
			callback?.(null);
			setLoading(false);
		}
	};

	const onImportKeys = async (
		params: TImportKeys,
		callback?: (a: any) => void,
	) => {
		try {
			setLoading(true);
			const rs: any = await axiosClient
				.get(ROUTES_API.PROJECT.IMPORT_KEY, { params })
				.catch(() => {
					setLoading(false);
				});
			console.log("🚀 ~ onImportKeys ~ rs:", rs);
			setLoading(false);
			const { statusCode, data } = rs;
			console.log("🚀 ~ onImportKeys ~ data:", data);
			if (statusCode === 200) {
				callback?.(rs);
			}
		} catch (err) {
			callback?.(null);
			setLoading(false);
		}
	};

	const onDeleteProject = async (input: { code: string }, callback?:() => void) => {
		try {
			setLoading(true);
			const rs: any = await axiosClient
				.delete(ROUTES_API.PROJECT.DELETE, {
					data: input
				})
				.catch(() => {
					setLoading(false);
				});

			setLoading(false);
			const { statusCode, data } = rs;

			if (statusCode === 200) {
				success("Delete project successfully");
				callback && callback();
			}
		} catch (err) {
			setLoading(false);
		}
	};

	return {
		loading,
		onImportKeys,
		onCreate,
		onGetList,
		onGetDetail,
		onPlaySequence,
		onUpdate,
		onDeleteProject,
	};
}
