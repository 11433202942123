import { Box, Typography } from "@mui/material";
import { formatPrice } from "utils/helper";

export default function PaymentDetails({ profile }) {
	return (
		<>
			<Box mt={2} sx={[styles.cenRow]}>
				<Box sx={styles.col}>
					<Typography
						component={"span"}
						fontSize={"24px"}
						color={"white"}
						fontWeight={"600"}>
						Current plan
					</Typography>
				</Box>
				<Box sx={styles.col}>
					<Typography
						component={"span"}
						fontSize={"24px"}
						color={"white"}
						fontWeight={"600"}>
						{profile?.plan}
					</Typography>
					{profile?.totalBill ? (
						<Typography
							component={"span"}
							fontSize={"16px"}
							color={"#B3B6B6"}
							fontWeight={"500"}
							textAlign={"right"}
							>
							{formatPrice(profile?.totalBill)}/
							{profile?.typeOfUse === "Monthly" && "mo"}.
						</Typography>
					) : (
						"$0"
					)}
				</Box>
			</Box>
		</>
	);
}

const styles = {
	cenRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		// alignItems: "center",
		display: "flex",
	},
	col: { flexDirection: "column", display: "flex" },
};
