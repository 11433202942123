import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	onHideSelection,
	onUpdateBtn,
	updateDisabledRm,
	updateLoadingRm,
	updateRangeFrame,
	updateRectPosition,
	updateVisualization,
} from "redux/slices/projects";
import { AppDispatch, AppState } from "redux/store";
import { TRemovalObject } from "types/ai";
import { ActiveBtn } from "types/project";
import axiosClient from "utils/axios";
import { ROUTES_API } from "utils/constants";
import { getBlobId } from "utils/libs";

export const useRemovalObject = () => {
	const {
		loadingRm,
		disabledRm,
		rectPos,
		rangeFrame,
		visualization,
		hideSelection,
		activeBtn,
	} = useSelector((state: AppState) => state.project);
	const dispatch = useDispatch<AppDispatch>();

	const onUpdateLoadingRm = useCallback((loading) => {
		dispatch(updateLoadingRm(loading));
	}, []);

	const onUpdateDisabledRm = useCallback((disabled) => {
		dispatch(updateDisabledRm(disabled));
	}, []);

	const onUpdateRectPos = useCallback((pos) => {
		dispatch(updateRectPosition(pos));
	}, []);

	const onUpdateRangeFrame = useCallback((range) => {
		dispatch(updateRangeFrame(range));
	}, []);

	const onUpdateVisualization = useCallback((visual) => {
		dispatch(updateVisualization(visual));
	}, []);

	const onHideRectSelection = useCallback((hide) => {
		dispatch(onHideSelection(hide));
	}, []);

	const onUpdateActiveBtn = useCallback((act) => {
		dispatch(onUpdateBtn(act));
	}, []);

	const onRemovalObject = async (
		input: TRemovalObject,
		callback?: (a: any) => void,
	) => {
		try {
			const startFrame = getBlobId(input?.startFrame, true);
			console.log("🚀 ~ useRemovalObject ~ startFrame:", startFrame);
			const endFrame = getBlobId(input.endFrame, true);
			console.log("🚀 ~ useRemovalObject ~ endFrame:", endFrame);
			const rs: any = await axiosClient.post(ROUTES_API.AI.OBJECT_REMOVAL, {
				...input,
				startFrame,
				endFrame,
			});
			const { statusCode, data, message } = rs;
			if (statusCode === 200) {
				callback?.(true);
				onUpdateActiveBtn(null);
			}
			if (statusCode === 400) {
				toast.warn(`${message || "Something went wrong"}`, {
					position: "top-center",
					toastId: JSON.stringify(`${message}`),
				});
				onUpdateLoadingRm(false);
				onUpdateDisabledRm(false);
				onHideRectSelection(false);
				onUpdateActiveBtn(null);
			}
		} catch (err) {
			callback?.(false);
			onUpdateActiveBtn(null);
			onUpdateDisabledRm(false);
		}
	};

	return {
		onUpdateLoadingRm,
		onUpdateDisabledRm,
		onUpdateRectPos,
		onUpdateRangeFrame,
		onRemovalObject,
		onUpdateVisualization,
		onHideRectSelection,
		onUpdateActiveBtn,
		activeBtn,
		hideSelection,
		visualization,
		loadingRm,
		disabledRm,
		rectPos,
		rangeFrame,
	};
};
