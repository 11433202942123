export const payment_api_data = {
    title_plan: "Plan Summary",
    sub_title_plan: "Your new yearly total",
    content: "By providing your payment information and clicking “Confirm Purchase/Upgrade Plan”, you agree you will be charged the amount listed above and your subscription will automatically renew untill you cancel (price subject to change). Cancel anytime via Account Settings or Customer Support. You also agree to the Terms of Service and Privacy Policy.",
    price: 696,
    date_amount: 'year',
    tax: 0.00,
    total: 696,
    features: [
        {
            title: "1200 Credits/Month",
            tooltip: ""
        },
        {
            title: "Max Export Resolution 4K",
            tooltip: ""
        },
        {
            title: "5GB Max Video Upload Size",
            tooltip: ""
        },
        {
            title: "Commercial License",
            tooltip: ""
        },
    ],
    orther: "Buy more credits",
}