import MoreIcon from "assets/images/icon/more-icon.png";

export const BuyMore = () => (
	<div className="flex flex-row items-center gap-2 mb-6">
		<button
			className="text-white font-normal rounded border-transparent  w-fit px-2"
			style={{
				backgroundImage:
					"linear-gradient(to right, #7340D8B2 , #5271FFB2,#48D8E4B2)",
			}}>
			<p className="font-semibold text-[10px] text-white">SOON</p>
		</button>
		<p className="text-xs text-white flex-1 font-semibold">Buy more credits</p>
		<button>
			<img src={MoreIcon} alt="" />
		</button>
	</div>
);
