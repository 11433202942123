import React, { useEffect, useState } from "react";
import { useCommon } from "./useCommon";

const useProgressExport = (code: string) => {
	const [progressExport, setProgressExport] = useState(0);
	const [totalProcess, setTotalProcess] = useState(0);
	const { socket } = useCommon();

	useEffect(() => {
		const handleSocketEvent = (dataSocket) => {
			console.log("Data Socket Process Export", dataSocket);
			setProgressExport(dataSocket?.step);
			setTotalProcess(dataSocket?.totalProcess);
		};
		if (socket && code) {
			socket.on(`eventExport-${code}`, handleSocketEvent);
		}
		return () => {
			if (socket) {
				socket.off(`eventExport-${code}`, handleSocketEvent);
			}
		};
	}, [socket, code]);
	return { progressExport, totalProcess, setProgressExport };
};

export default useProgressExport;
