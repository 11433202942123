import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AccountType,
  ApplicationType,
  ReviewType,
} from "../../interface/state";

interface InitState {
  listAccounts: AccountType[];
  listApplications: ApplicationType[];
  listReviews: ReviewType[];
}

const initialState: InitState = {
  listAccounts: JSON.parse(localStorage.getItem("accounts") as string) || [],
  listApplications:
    JSON.parse(localStorage.getItem("applications") as string) || [],
  listReviews: JSON.parse(localStorage.getItem("reviews") as string) || [],
};

const managementSlice = createSlice({
  name: "management",
  initialState,
  reducers: {
    setListAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.listAccounts = action.payload;
      localStorage.setItem("accounts", JSON.stringify(action.payload));
    },
    setListApplication: (state, action: PayloadAction<ApplicationType[]>) => {
      state.listApplications = action.payload;
      localStorage.setItem("applications", JSON.stringify(action.payload));
    },
    setListReviews: (state, action: PayloadAction<ReviewType[]>) => {
      state.listReviews = action.payload;
      localStorage.setItem("reviews", JSON.stringify(action.payload));
    },
  },
});

export const { setListAccounts, setListApplication, setListReviews } =
  managementSlice.actions;

export default managementSlice.reducer;
