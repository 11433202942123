export type TLogin = {
  email: string;
  password: string;
};

export type TLoginSocial = {
  idToken: string;
};

export type TRegister = {
  userName: string;
  email: string;
  password: string;
  repassword: string;
};

export interface TForgotPassword {
  email?: string;
  uid?: string;
  otp?: string;
  newPassword?: string;
  repassword?: string;
}

export interface IInputFX {
  type: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  setValue?: (newValue: string) => void;
  formData?: TForgotPassword;
  setFormData?: (data: TForgotPassword) => void;
  errors?: TForgotPassword;
  setErrors?: (error: TForgotPassword) => void;
}

export interface IUser {
  avatar: string;
  email: string;
  id: string;
  isDeleted: false;
  name: string;
  password: string;
  role: string;
  theme: string;
  updatedAt: string;
  userName: string;
  _id: string;
}

export interface IContextState {
  profile: {};
}

export enum IUserActionsType {
  UPDATE_PARAMS = "UPDATE_PARAMS",
}
