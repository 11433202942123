import useProject from "hooks/useProject";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useCommon } from "hooks/useCommon";
import { ROUTES } from "components/Sidebar/routes";
import { Images } from "assets/images/Images";
import { LocalKey, USER_EX } from "utils/constants";
import ModalGettingStarted from "components/modals/ModalGettingStarted";
import { TourItem } from "components/TourGuide";
import { TypeAiReady } from "interface";
import { useAlert } from "hooks/useAlert";
import { ModalUserSettings } from "components/modals";
import DropdownMore from "components/Dropdowns/DropdownMore";
import SvgIcon from "assets/SvgIcon";
import { EnumActionProject } from "types/project";
import { FaTrash } from "react-icons/fa";
import InputWithEnter from "components/Inputs/InputEnter";
// import Thumbnail from "assets/images/thumbnails/thumbnail.png";

export default function ProjectPage() {
	const { onCreate, onGetList, onDeleteProject, onUpdate } = useProject();
	const { onUpdateParams, statusReady, openModalSetting } = useCommon();
	const [list, setList] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [isStartTour, setIsStartTour] = useState(false);
	const { info, warn, success } = useAlert();
	const [selectedProject, setSelectedProject] = useState<any>({
		isUpdate: false,
		selected: null,
	});

	const router = useNavigate();

	useEffect(() => {
		const checkUserEX = async () => {
			const isUserEx = await localStorage.getItem(LocalKey.GETTING_STARED);
			setOpenModal(!isUserEx);
		};
		checkUserEX();
	}, []);

	const initData = () => {
		onGetList(null, (rs) => {
			if (rs) setList(rs.data);
		});
	};

	useEffect(() => {
		initData();
		onUpdateParams({ startSequence: false });
	}, []);

	const onClickCheckReadyAndNavigate = useCallback(
		(e) => {
			if (statusReady !== TypeAiReady.READY) {
				return info("AI Initializing, please wait");
			}
			router(`${ROUTES.ADMIN.PROJECT_DETAIL}/${e?.code}`, {
				state: { name: e?.name },
			});
		},
		[statusReady],
	);

	const onHandleAction = useCallback(
		async (action: EnumActionProject | any, project) => {
			if (action === EnumActionProject.DELETE) {
				await onDeleteProject({ code: project.code }, initData);
				return;
			}
			if (action === EnumActionProject.RENAME) {
				setSelectedProject((prev) => ({
					...prev,
					selected: project,
					isUpdate: true,
				}));
			}
		},
		[],
	);

	const onReset = () =>
		setSelectedProject({
			isUpdate: false,
			selected: null,
		});

	const onUpdateProject = useCallback(
		async (v) => {
			if (!v) {
				warn("Please enter a project name");
				return;
			}
			await onUpdate(
				{
					name: v,
					code: selectedProject.selected.code,
				},
				() => {
					initData();
					onReset();
					success("Project name has been updated!");
				},
			);
		},
		[selectedProject],
	);

	return (
		<div className="mx-auto">
			{list.length > 0 ? (
				<div>
					<h2 className="text-title-md2 text-[#ffffff] font-semibold dark:text-white mt-5">
						My Projects
					</h2>
					<div className="flex grid-cols-* mt-10 flex-wrap gap-10">
						{list.map((e: any, index) => (
							<div
								key={e?.code + index}
								className="w-auto mt-10"
								onClick={() => onClickCheckReadyAndNavigate(e)}>
								{e?.thumbnail && typeof e.thumbnail === "string" ? (
									<div className="rounded-[20px] w-[375px] h-[212px] overflow-hidden cursor-pointer">
										<img
											src={e.thumbnail}
											alt="project"
											style={{ objectFit: "cover" }}
											className="w-full h-full transition-transform duration-300 hover:scale-110"
										/>
									</div>
								) : (
									<div className="rounded-[20px] w-[375px] h-[212px] overflow-hidden cursor-pointer">
										<img
											src={Images.Thumbnails}
											alt="project"
											style={{ objectFit: "cover" }}
											className="w-full h-full transition-transform duration-300 hover:scale-110"
										/>
									</div>
								)}

								<div className="flex items-center">
									<div
										className="flex flex-col flex-1 w-full"
										onClick={(e) => e.stopPropagation()}>
										{selectedProject.isUpdate &&
										selectedProject?.selected?.id === e?.id ? (
											<InputWithEnter
												value={selectedProject?.selected?.name}
												onEnter={(v) => onUpdateProject(v)}
												onEsc={onReset}
											/>
										) : (
											<div className="pt-2 text-white">
												{e?.name ? e?.name : e?.code}
											</div>
										)}
										<div className="pt-2 text-white">
											{moment(e?.createdAt).fromNow()}
										</div>
									</div>
									<DropdownMore
										onSelected={(s) => onHandleAction(s, e)}
										options={[
											{
												label: (
													<div className="flex items-center gap-1 text-white text-xs font-semibold font-raleway">
														{SvgIcon.PencilEditIcon()}{" "}
														{EnumActionProject.RENAME}
													</div>
												),
												value: EnumActionProject.RENAME,
											},
											{
												label: (
													<div className="flex items-center gap-1 text-white text-xs font-semibold font-raleway">
														<FaTrash /> {EnumActionProject.DELETE}
													</div>
												),
												value: EnumActionProject.DELETE,
											},
										]}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="">
					<div
						className={`w-[397px] ${isStartTour ? "border-gradient p-3" : ""}`}>
						<h2 className="text-title-md2 text-[#ffffff] font-semibold dark:text-white mt-5">
							My Projects
						</h2>
						<div
							className={`w-[376px] h-[212px]  mt-10  border-2 rounded-[20px] border-[#3c3c3c] bg-color-[#191A1E] hover:cursor-pointer hover:bg-black 
                                         flex justify-center flex-row items-center `}
							onClick={(e) => {
								onCreate({ name: "" });
							}}>
							<p className=" text-white text-[16px] mr-3">Create New Project</p>
							<img
								src={Images.IconAdd}
								width={40}
								height={40}
								alt="icon"
								className="w-[23px] h-[23px]"
							/>
						</div>
					</div>
					{isStartTour && (
						<div className="mt-2.5 max-w-[397px] mx-2">
							<TourItem
								onClose={() => setIsStartTour(false)}
								onNext={() => {
									onCreate({ name: "" });
									setIsStartTour(false);
								}}
								title="Start Here"
								content="This is where you can create new a project. After the tour is over, try to set started."
								btnNextTitle="Next"
							/>
						</div>
					)}
				</div>
			)}
			<ModalGettingStarted
				open={openModal}
				toggleModal={() => {
					localStorage.setItem(LocalKey.GETTING_STARED, "GETTING_STARED");
					setOpenModal(!openModal);
				}}
				startTour={() => setIsStartTour(true)}
			/>
			<ModalUserSettings openModalSetting={openModalSetting} />
		</div>
	);
}
