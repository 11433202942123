import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActiveBtn } from "types/project";

interface IRectPos {
	xStart: number;
	yStart: number;
	xEnd: number;
	yEnd: number;
}

interface IRangeFrame {
	startFrame: number;
	endFrame: number;
}

interface ProjectStateType {
	loadingRm: boolean;
	disabledRm: boolean;
	rectPos?: IRectPos | null;
	rangeFrame?: IRangeFrame | null;
	visualization?: boolean;
	hideSelection?: boolean;
	activeBtn?: ActiveBtn |null;
}

const initialState: ProjectStateType = {
	loadingRm: false,
	disabledRm: false,
	rectPos: null,
	visualization: false,
	hideSelection: false,
	activeBtn: null
};

const projectSlice = createSlice({
	name: "project",
	initialState,
	reducers: {
		updateLoadingRm: (state, action: PayloadAction<boolean>) => {
			state.loadingRm = action.payload;
		},
		updateDisabledRm: (state, action: PayloadAction<boolean>) => {
			state.disabledRm = action.payload;
		},
		updateRectPosition: (state, action: PayloadAction<IRectPos>) => {
			state.rectPos = action.payload;
		},
		updateRangeFrame: (state, action: PayloadAction<IRangeFrame>) => {
			state.rangeFrame = action.payload;
		},
		updateVisualization: (state, action: PayloadAction<boolean>) => {
			state.disabledRm = action.payload;
		},
		onHideSelection: (state, action: PayloadAction<boolean>) => {
			state.hideSelection = action.payload;
		},
		onUpdateBtn: (state, action: PayloadAction<ActiveBtn>) => {
			state.activeBtn = action.payload;
		}
	},
});

export const {
	updateDisabledRm,
	updateLoadingRm,
	updateRectPosition,
	updateRangeFrame,
	updateVisualization,
	onHideSelection,
	onUpdateBtn
} = projectSlice.actions;

export default projectSlice.reducer;
