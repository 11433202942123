import ModalLoading from "components/modals/ModalLoading";
import { useAuth } from "hooks/useAuth";
import { useCommon } from "hooks/useCommon";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "video-react/dist/video-react.css";

interface IDetailLayout {
	children: React.ReactNode;
	bottomBar: React.ReactNode;
	sideLeftBar: () => React.ReactNode;
	rightSidebar: () => React.ReactNode;
	isDisable: boolean;
	progress: number;
	totalFrame: number;
	isProgressUpload: boolean;
}

export default function DetailLayout({
	children,
	bottomBar,
	sideLeftBar,
	rightSidebar,
	progress,
	totalFrame,
	isProgressUpload,
}: IDetailLayout) {
	const prevRef = useRef<any>({ connected: false }).current;
	const { profile, socket, onUpdateParams } = useCommon();
	const { onProfile } = useAuth();

	useEffect(() => {
		onProfile();
	}, []);

	useEffect(() => {
		if (socket && profile?.id) {
			socket?.on(`handleStatus-${profile?.id}`, (msg) => {
				const { name, status } = msg;
				onUpdateParams({ nameEvent: { [`${name}`]: status } });
			});
		}
	}, [socket, profile]);

	const { width, height } = useWindowDimensions();

	return (
		<div className="bg-[#1F1F1F] w-screen h-screen flex flex-col overflow-y-scroll scrollbar-hide relative">
			<div
				className="flex flex-row w-full grow relative gap-2"
				style={{ maxHeight: height - 140 }}>
				{sideLeftBar && sideLeftBar?.()}
				<div
					style={{ width: width - 320, maxHeight: height - 170 }}
					className="flex flex-col grow relative">
					<div className="flex flex-col grow">{children}</div>
				</div>
				{rightSidebar && rightSidebar()}
			</div>
			{!bottomBar ? <></> : bottomBar}
			<ModalLoading
				open={isProgressUpload}
				progress={progress}
				totalFrame={totalFrame}
			/>
		</div>
	);
}
