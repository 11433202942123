import { ROUTES_API } from "utils/constants";
import { useAlert } from "./useAlert";
import { useCallback, useState } from "react";
import axiosClient from "utils/axios";
import {
	BtnPlanTitle,
	InvoiceType,
	PackagePlan,
	TPackageParams,
} from "types/payment";
import { formatPrice } from "utils/helper";

export default function usePricingPlan() {
	const [packages, setPackages] = useState<PackagePlan[]>([]);
	const [invoices, setInvoices] = useState<InvoiceType[]>([]);
	const [loading, setLoading] = useState(false);

	const onGetPackages = useCallback(async (params: TPackageParams) => {
		try {
			setLoading(true);
			const { limit = 10, page = 1, nukeShape = 0, quantity = 1 } = params;
			let url = `${ROUTES_API.PLAN.GET_PACKAGES}?limit=${limit}&page=${page}&nukeShape=${nukeShape}&quantity=${quantity}`;
			const rs: any = await axiosClient.get(url).catch(() => {
				setLoading(false);
			});
			setLoading(false);
			const { statusCode, data } = rs;
			if (statusCode === 200) {
				const remappingData = data
					?.sort((a, b) => a?.sortType - b?.sortType)
					?.map((item) => {
						return {
							...item,
							btnText: BtnPlanTitle[`${item?.packageName}`],
							annualPrice:
								quantity === 12
									? item?.priceSale
										? formatPrice(item?.priceSale * 12)
										: 0
									: 0,
							price: item?.price ? formatPrice(item?.price) : 0,
							priceSale: item?.priceSale ? formatPrice(item?.priceSale) : 0,
						};
					});

				setPackages(remappingData);
				return;
			}
			return null;
		} catch (err) {
			setLoading(false);
			return null;
		}
	}, []);

	const onGetInvoices = useCallback(async (params: TPackageParams) => {
		try {
			const { limit = 10, page = 1 } = params;
			let url = `${ROUTES_API.PLAN.GET_INVOICES}?limit=${limit}&page=${page}`;
			const rs: any = await axiosClient.get(url).catch(() => {});
			const { statusCode, data } = rs;
			if (statusCode === 200) {
				setInvoices(data);
				return;
			}
			return null;
		} catch (err) {
			return null;
		}
	}, []);

	return {
		packages,
		loading,
		invoices,
		onGetPackages,
		onGetInvoices,
	};
}
